import Clear from "@/layouts/Clear.vue";

export default {
  path: "/referral-program",
  component: Clear,
  children: [
    {
      path: "",
      name: "referral-program.index",
      component: () => import("@/views/ReferralProgram/ReferralProgram.vue"),
    },
  ],
};
