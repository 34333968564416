import * as AuthActions from "@/store/actions/auth";
import STORAGE from "@/constants/storage.constant";

import Api from "@/services/api.service";
import AuthService from "@/services/auth.service";
import jwt_decode from "jwt-decode";

const state = {
  loading: true,
  error: false,
  token: null,
};

const getters = {
  isAuthenticated: (state) => !!state.token,
  doctorId: (state) => jwt_decode(state.token).doctor_id,
  doctorSecondStageProfile: (state) => jwt_decode(state.token).second_stage,
};

const actions = {
  [AuthActions.AUTH_TOKEN]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(AuthActions.AUTH_TOKEN);

      const token = localStorage.getItem(STORAGE.AUTH_TOKEN);

      if (!token) {
        commit(AuthActions.AUTH_TOKEN_ERROR);
        reject("Token inválido");
        return;
      }

      commit(AuthActions.AUTH_TOKEN_SUCCESS, token);
      resolve(token);
    });
  },
  [AuthActions.AUTH_REQUEST]: ({ commit }, loginData) => {
    return new Promise((resolve, reject) => {
      commit(AuthActions.AUTH_REQUEST);

      AuthService.login(loginData)
        .then(({ data }) => {
          const { access_token } = data;

          localStorage.setItem(STORAGE.AUTH_TOKEN, access_token);
          commit(AuthActions.AUTH_REQUEST_SUCCESS, access_token);
          resolve(access_token);
        })
        .catch((err) => {
          commit(AuthActions.AUTH_REQUEST_ERROR, err);
          localStorage.removeItem(STORAGE.AUTH_TOKEN);
          reject(err);
        });
    });
  },
  [AuthActions.AUTH_LOGOUT]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(AuthActions.AUTH_LOGOUT_REQUEST);

      AuthService.logout()
        .then(
          () => (
            localStorage.removeItem(STORAGE.AUTH_TOKEN),
            commit(AuthActions.AUTH_LOGOUT_SUCCESS),
            resolve()
          )
        )
        .catch(() => (commit(AuthActions.AUTH_REQUEST_ERROR), reject()));
    });
  },
};

const mutations = {
  [AuthActions.AUTH_TOKEN]: (state) => {
    state.loading = true;
    state.error = false;
  },

  [AuthActions.AUTH_TOKEN_SUCCESS]: (state, token) => {
    state.loading = false;
    state.token = token;
    Api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  },

  [AuthActions.AUTH_TOKEN_ERROR]: (state) => {
    state.loading = false;
    state.error = true;
    state.token = null;
  },

  [AuthActions.AUTH_REQUEST]: (state) => {
    state.error = false;
  },

  [AuthActions.AUTH_REQUEST_SUCCESS]: (state, token) => {
    state.token = token;
    Api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  },

  [AuthActions.AUTH_REQUEST_ERROR]: (state) => {
    state.error = true;
    state.token = null;
  },

  [AuthActions.AUTH_LOGOUT_REQUEST]: (state) => {
    state.loading = true;
    state.error = false;
  },

  [AuthActions.AUTH_LOGOUT_SUCCESS]: (state) => {
    state.loading = false;
    state.error = false;
    state.token = null;
    delete Api.defaults.headers.common["Authorization"];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
