<template>
  <div class="schedules-list-view">
    <app-header icon="home" title="Atendimentos"></app-header>

    <section>
      <div class="row mt-2">
        <div class="col-12 col-lg-12 col-md-12 col-sm-6">
          <div class="card">
            <div class="card-content">
              <div class="card-title">FILTROS</div>

              <div class="row d-flex">
                <div class="col-3 col-lg-3 col-md-3 col-sm-2 card-select">
                  <div class="card-select-title">Advogado</div>
                  <b-field>
                    <app-input
                      v-model="searchDoctorTerm"
                      placeholder="Nome, e-mail ou CPF/CNPJ"
                      icon="search"
                    >
                    </app-input>
                  </b-field>
                </div>
                <div class="col-2 col-lg-2 col-md-2 col-sm-1 card-select">
                  <div class="card-select-title">Cliente</div>
                  <b-field>
                    <app-input
                      v-model="searchPatientTerm"
                      placeholder="Nome, e-mail ou CPF"
                      icon="search"
                    >
                    </app-input>
                  </b-field>
                </div>
                <div
                  class="col-2 col-lg-2 col-md-2 col-sm-1 d-flex align-items: middle"
                >
                  <b-tooltip label="FILTROS">
                    <b-checkbox
                      v-model="checked"
                      class="mr-0"
                      label="Filtrar resultados"
                      style="margin-top: 25px"
                    />
                  </b-tooltip>
                  <span style="padding-top: 23px">OUTROS FILTROS</span>
                </div>
                <div class="col-4 col-lg-4 col-md-4 col-sm-2">
                  <b-tooltip label="FILTRAR">
                    <b-button
                      type="is-primary"
                      icon-right="search"
                      style="margin-top: 10px; margin-right: 15px"
                      @click="filtrar_outros()"
                      >FILTRAR</b-button
                    >
                  </b-tooltip>
                  <b-tooltip label="LIMPAR FILTROS">
                    <b-button
                      type="is-primary"
                      icon-right="recycle"
                      style="margin-top: 10px; margin-right: 15px; width: 20px"
                      @click="limpar_filtros()"
                    ></b-button>
                  </b-tooltip>
                  <b-tooltip label="EXPORTAR XLS">
                    <b-button
                      @click="exportSchedules()"
                      type="is-primary"
                      icon-right="file-excel"
                      :disabled="isExporting"
                      :loading="isExporting"
                      style="margin-top: 10px"
                    >
                      EXPORTAR XLS
                    </b-button>
                  </b-tooltip>
                </div>
              </div>

              <div v-if="checked" class="row mt-4 d-flex">
                <div class="col-2 col-lg-2 col-md-2 col-sm-1 card-select">
                  <div class="card-select-title">Status</div>

                  <b-field>
                    <b-select
                      v-model="status"
                      placeholder="Selecionar..."
                      expanded
                    >
                      <b-select-option :value="'ALL'" :key="0"
                        >Todos</b-select-option
                      >
                      <b-select-option :value="'CANCELED'" :key="1"
                        >Cancelados</b-select-option
                      >
                      <b-select-option :value="'PERFORMED'" :key="2"
                        >Realizados</b-select-option
                      >
                      <b-select-option :value="'TO_PERFORM'" :key="3"
                        >A realizar</b-select-option
                      >
                    </b-select>
                  </b-field>
                </div>

                <div class="col-2 col-lg-2 col-md-2 col-sm-1 card-select">
                  <div class="card-select-custom-title">Especialidade</div>
                  <b-field>
                    <b-select
                      class="select-input"
                      v-model="searchSpecialtyId"
                      placeholder="Especialidade"
                      name="searchSpecialtyId"
                    >
                      <b-select-option :value="null">Todas</b-select-option>
                      <b-select-option
                        v-for="specialty in specialties"
                        :key="specialty.id"
                        :value="specialty.id"
                      >
                        {{ specialty.name }}
                      </b-select-option>
                    </b-select>
                  </b-field>
                </div>

                <div class="col-2 col-lg-2 col-md-2 col-sm-1 card-select">
                  <div class="card-select-title">Selecione um período</div>
                  <b-field>
                    <b-select
                      @input="onPeriodChange()"
                      expanded
                      name="period"
                      v-model="periodFilter"
                      placeholder="Selecionar"
                    >
                      <b-select-option
                        v-for="period of periods"
                        :key="period.id"
                        :value="period.id"
                      >
                        {{ period.name }}
                      </b-select-option>
                    </b-select>
                  </b-field>
                </div>

                <div
                  v-if="isCustomPeriod"
                  class="col-2 col-lg-2 col-md-2 col-sm-1 card-select"
                >
                  <div class="card-select-custom-title">Período</div>
                  <b-field>
                    <b-datepicker
                      @input="onRangeChange"
                      placeholder="Clique para selecionar..."
                      icon="calendar"
                      range
                    >
                    </b-datepicker>
                  </b-field>
                </div>

                <!-- <div class="col-2 col-lg-2 col-md-2 col-sm-1 card-select">
                  <div class="card-select-title">Tipo de Atendimento</div>
                  <b-field>
                    <multiselect
                      v-model="values"
                      :options="tipos_atendimento"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      placeholder="Selecionar..."
                      dysplay-label="true"
                      label="id"
                      track-by="id"
                      :searchable="false"
                      :allow-empty="true"
                      :preselect-first="false"
                      open-direction="bottom"
                      @select="onSelect"
                      @remove="onRemove"
                    >
                    </multiselect>
                  </b-field>
                </div> -->

                <!-- <div class="col-2 col-lg-2 col-md-2 col-sm-1 card-select">
                  <div class="card-select-title">Atendimentos</div>
                  <b-field>
                    <b-select
                      v-model="tatendimento"
                      placeholder="Selecionar..."
                      expanded
                    >
                      <b-select-option :value="0">Todos</b-select-option>
                      <b-select-option :value="1"
                        >Atendimento Online</b-select-option
                      >
                      <b-select-option :value="2"
                        >Atendimento Agendado</b-select-option
                      >
                    </b-select>
                  </b-field>
                </div> -->
              </div>

              <div v-if="checked" class="row mt-4 d-flex">
                <!-- <div class="col-2 col-lg-2 col-md-2 col-sm-1 card-select">
                                    <div class="card-select-title">Forma de Pagamento</div>
                                    <b-field>
                                        <b-select
                                            v-model="forma_pagamento"
                                            placeholder="Selecionar..."
                                            expanded
                                        >
                                            <b-select-option :value="0">Todos</b-select-option>
                                            <b-select-option :value="1">Pix</b-select-option>
                                            <b-select-option :value="2">Cartão 1x</b-select-option>
                                            <b-select-option :value="3">Cartão 2x</b-select-option>
                                            <b-select-option :value="4">Cartão 3x</b-select-option>
                                            <b-select-option :value="5">Cartão 4x</b-select-option>
                                        </b-select>
                                    </b-field>
                                </div> -->

                <!-- <div class="col-2 col-lg-2 col-md-2 col-sm-1 card-select">
                                    <div class="card-select-title">Pagamento Status</div>
                                    <b-field>
                                        <b-select v-model="payment_status" placeholder="Selecionar..." expanded>
                                            <b-select-option :value="'ALL'" :key="0">Todos</b-select-option>
                                            <b-select-option :value="'CANCELED'" :key="1"
                                                >Cancelados</b-select-option
                                            >
                                            <b-select-option :value="'VOUCHER_100'" :key="2"
                                                >Voucher 100%</b-select-option
                                            >
                                            <b-select-option :value="'EXTERNAL'" :key="3"
                                                >Externo</b-select-option
                                            >
                                            <b-select-option :value="'WAITING'" :key="4"
                                                >Aguardando</b-select-option
                                            >
                                            <b-select-option :value="'CONFIRMED'" :key="5"
                                                >Convirmado</b-select-option
                                            >
                                        </b-select>
                                    </b-field>
                                </div> -->
                <!-- 
                                <div class="col-2 col-lg-2 col-md-2 col-sm-1 card-select">
                                    <div class="card-select-title">Tipo</div>
                                    <b-field>
                                        <b-select
                                            v-model="tipo_agendamento"
                                            placeholder="Selecionar..."
                                            expanded
                                        >
                                            <b-select-option :value="-1">Todos</b-select-option>
                                            <b-select-option :value="0">Externo</b-select-option>
                                            <b-select-option :value="1">Interno</b-select-option>
                                        </b-select>
                                    </b-field>
                                </div> -->

                <!-- <div class="col-2 col-lg-2 col-md-2 col-sm-1 card-select">
                  <div class="card-select-title">Atendimentos</div>
                  <b-field>
                    <b-select
                      v-model="tatendimento"
                      placeholder="Selecionar..."
                      expanded
                    >
                      <b-select-option :value="0">Todos</b-select-option>
                      <b-select-option :value="1"
                        >Atendimento Online</b-select-option
                      >
                      <b-select-option :value="2"
                        >Atendimento Agendado</b-select-option
                      >
                    </b-select>
                  </b-field>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-6 col-lg-6 col-md-6 col-sm-3">
          <div class="box-primary">CONSULTAS EM ANDAMENTO: 0</div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12 col-lg-12 col-md-12 col-sm-6">
          <b-table
            id="table-schedules"
            class="table-custom"
            :data="schedules_list"
            :per-page="perPage"
            :current-page.sync="page"
            :total="total"
            :loading="isLoading"
            :paginated="false"
            :pagination-simple="false"
            pagination-position="both"
            default-sort-direction="asc"
            :pagination-rounded="false"
            sort-icon="arrow-down"
            sort-icon-size="is-small"
            default-sort="row.name"
            aria-next-label="Próxima Página"
            aria-previous-label="Página Anterior"
            aria-page-label="Página"
            aria-current-label="Página Atual"
          >
            <b-table-column
              v-slot="props"
              field="doctor.name"
              label="ADVOGADO"
              sortable
            >
              {{ props.row.doctor.name || "-" }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="patient.name"
              label="CLIENTE"
              sortable
            >
              {{ props.row.patient.name || "-" }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="start"
              label="INÍCIO"
              sortable
              centered
            >
              {{ props.row.start | date("DD/MM/YYYY [às] HH:mm") }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="specialty.name"
              label="ESPECIALIDADE"
              sortable
            >
              {{ props.row.specialty ? props.row.specialty.name : "Geral" }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="value"
              label="VALORES"
              sortable
              centered
            >
              <div class="ctab-r">
                {{ (props.row.value || 0) | currency("BRL") }}
              </div>
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="value_fee"
              label="TAXA"
              sortable
              centered
            >
              <div class="ctab-r">
                {{ (props.row.value_fee || 0) | currency("BRL") }}
              </div>
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="internal"
              label="TIPO"
              sortable
              centered
            >
              <span v-if="props.row.specialty_id === null">Geral</span>
              <span v-if="props.row.replicated_from !== null">Replicada</span>
              <span
                v-if="
                  props.row.replicated_from === null &&
                  props.row.specialty_id !== null
                "
                >Específica</span
              >
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="forma_pagamento.name"
              label="F. PAGTO."
              sortable
              centered
            >
              {{ props.row.forma_pagamento.name || "-" }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="appointment_types.name"
              label="T. ATEND."
              sortable
              centered
            >
              {{ props.row.appointment_types.name || "-" }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="tatendimento.name"
              label="ATEND."
              sortable
              centered
            >
              {{ props.row.tatendimento.name || "-" }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="schedule_status.label"
              label="STATUS"
              centered
            >
              <b-tag type="is-primary">
                {{ props.row.schedule_status.label }}
              </b-tag>
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="payment_status"
              label="PGTO"
              centered
            >
              <div>
                <b-tag type="is-primary">
                  {{
                    props.row.payment_status.length
                      ? props.row.payment_status.label
                      : "Pago"
                  }}
                </b-tag>
              </div>
            </b-table-column>

            <b-table-column
              v-slot="props"
              label="OPÇÕES"
              cell-class="width-100"
              centered
            >
              <router-link
                :to="{
                  name: 'schedules.save',
                  params: { id: props.row.id },
                }"
              >
                <b-tooltip label="VISUALIZAR">
                  <b-button type="is-primary" size="2x" icon-right="eye" />
                </b-tooltip>
              </router-link>
            </b-table-column>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="frown" size="is-small"></b-icon>
                  </p>
                  <p>Nenhum registro encontrado.</p>
                </div>
              </section>
            </template>

            <template #footer v-if="total > 0" class="totais">
              <div
                class="row justify-content-lg-center justify-content-md-center justify-content-sm-center"
              >
                <div class="col-5 col-lg-5 col-md-5 col-sm-3 ptext text-right">
                  Exibindo {{ rows }} de {{ total }}
                </div>

                <div class="col-auto text-center">
                  <b-pagination
                    v-model="page"
                    :total-rows="total"
                    :per-page="perPage"
                    first-number
                    last-number
                    aria-controls="table-schedules"
                  >
                  </b-pagination>
                </div>

                <div class="col-auto text-center">
                  <b-select
                    class="pselect"
                    v-model="perPage"
                    style="margin-left: 10px"
                  >
                    <b-select-option
                      v-for="i in perPageOptions"
                      :key="i"
                      :value="i"
                    >
                      <b>{{ i }}</b>
                    </b-select-option>
                  </b-select>
                </div>

                <div class="col align-self-end">
                  <div class="ctab-r col-12 col-lg-12 col-md-12 col-sm-6">
                    <p>Valores conforme o filtro:</p>

                    <p>
                      Total:
                      {{ schedule_values.search_total | currency("BRL") }}
                    </p>

                    <p>
                      Valor médio dos atendimentos:
                      {{ schedule_values.average | currency("BRL") }}
                    </p>

                    <p>
                      Total na página: {{ schedule_values.paginated_total }}
                    </p>

                    <p>
                      Total de atendimentos:
                      {{ schedule_values.total_of_schedules }}
                    </p>
                  </div>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as moment from "moment";
import debounce from "lodash/debounce";
import PERIODS from "@/constants/periods.constant";

//import DashboardService from '@/services/dashboard.service';
import SchedulesService from "@/services/schedules.service";
import SpecialtiesService from "@/services/specialties.service.js";
// import Multiselect from "vue-multiselect";

export default {
  // components: { Multiselect },

  mounted() {
    this.onPeriodChange();
    this.loadSpecialties();
    this.load();
  },

  data: () => ({
    checked: false,
    periodFilter: 10,
    periods: PERIODS,
    selectedPeriod: 10,
    isLoading: true,
    data: [],
    jsondata: {},
    total: 0,
    page: 1,
    dates: [],
    rows: 0,
    isCustomPeriod: false,
    searchDoctorTerm: null,
    searchPatientTerm: null,
    searchStart: null,
    searchEnd: null,
    status: "ALL",
    specialties: [],
    searchSpecialtyId: null,
    searchDoctor: null,
    searchPatient: null,
    isExporting: false,
    schedule_values: {},
    perPageOptions: [10, 20, 40, 80],
    perPage: 10,
    forma_pagamento: 0,
    term_patient: null,
    term_doctor: null,
    specialty_id: null,
    payment_status: null,
    appointment_types: [],
    tatendimento: 0,
    tipo_agendamento: [],
    range: null,
    values: [],
    tipos_atendimento: [
      { id: "1 - Chat" },
      { id: "2 - Ligação" },
      { id: "3 - Vídeo Chamada" },
    ],
  }),

  computed: {
    schedules_list() {
      return this.data;
    },

    rows() {
      return this.data.length;
    },
  },

  watch: {
    sorter() {
      this.page = 1;
      this.load();
    },

    searchDoctorTerm: debounce(function () {
      this.page = 1;
      this.load();
    }, 400),

    searchPatientTerm: debounce(function () {
      this.page = 1;
      this.load();
    }, 400),

    searchStart() {
      this.page = 1;
    },

    searchEnd() {
      this.page = 1;
    },

    searchSpecialtyId() {
      this.page = 1;
    },

    status() {
      this.page = 1;
    },

    page() {
      if (this.data.length > 0 && this.data.length < this.total) this.load();
    },

    perPage() {
      this.load();
    },

    payment_status() {
      this.page = 1;
    },

    tatendimento() {
      this.page = 1;
    },

    selectedPeriod() {
      this.page = 1;
    },

    onPeriodChange() {
      this.page = 1;
    },

    onRangeChange() {
      this.page = 1;
    },
  },

  methods: {
    onSelect(values) {
      if (values) {
        this.appointment_types.push(
          Object.values(values).toString().substr(0, 1)
        );
      }
    },

    onRemove(values) {
      if (values) {
        const val = Object.values(values).toString().substr(0, 1);
        this.appointment_types.splice(this.appointment_types.indexOf(val), 1);
      }
    },

    onPeriodChange() {
      const period = this.periods.find((item) => item.id == this.periodFilter);

      if (!period.custom) {
        this.page = 1;
        this.isCustomPeriod = false;
        const { start, end } = period;

        this.dates = [
          moment(start).format("YYYY-MM-DD"),
          moment(end).format("YYYY-MM-DD"),
        ];
      } else {
        this.isCustomPeriod = true;
        this.dates = [];
      }
    },

    filtrar_outros() {
      this.load();
    },

    limpar_filtros() {
      this.searchDoctorTerm = "";
      this.searchPatientTerm = "";
      this.searchApproved = -1;
      this.searchActive = -1;
      this.searchJuno = -1;
      this.searchDate = null;
      this.searchDateEnd = null;
      this.online = false;
      this.sorter = false;
      this.status = "ALL";
      this.forma_pagamento = 0;
      this.tatendimento = 0;
      this.payment_status = null;
      this.appointment_types = [];
      this.tipo_agendamento = [];
      this.searchSpecialtyId = null;
      this.datesFilter = [];
      this.periodFilter = 10;
      this.selectedPeriod = "";
      this.checked = false;
      this.onPeriodChange = "";
      this.onRangeChange = "";
      this.load();
    },

    loadSpecialties() {
      this.isLoading = true;
      SpecialtiesService.getAll()
        .then(({ data }) => (this.specialties = data))
        .finally(() => (this.isLoading = false));
    },

    load() {
      this.isLoading = true;

      const params = {
        page: this.page,
        perPage: this.perPage,
        // doctor: this.searchDoctor,
        // patient: this.searchPatient,
        // status: this.status,
      };

      if (this.status != "ALL") {
        params["filter[status]"] = this.status;
      } else {
        this.status = "";
      }

      if (this.searchDoctorTerm) {
        params["filter[doctor_info]"] = this.searchDoctorTerm;
      }

      if (this.searchPatientTerm) {
        params["filter[patient_info]"] = this.searchPatientTerm;
      }

      if (this.dates.length == 2) {
        params[
          "filter[start_end_between]"
        ] = `${this.dates[0]},${this.dates[1]}`;
      }

      if (this.searchSpecialtyId) {
        params["filter[doctor.by_specialty]"] =
          this.searchSpecialtyId != null ? this.searchSpecialtyId : "";
      }

      if (this.payment_status != "ALL") {
        params["filter[payment_status]"] = this.payment_status;
      }

      if (this.forma_pagamento > 0) {
        params["filter[forma_pagamento]"] = this.forma_pagamento;
      }

      if (this.appointment_types != "") {
        params["filter[appointment_types]"] = this.appointment_types;
      }

      if (this.tatendimento > 0) {
        params["filter[tatendimento]"] = this.tatendimento;
      }

      if (this.tipo_agendamento != "") {
        params["filter[type]"] = this.tipo_agendamento;
      } else {
        this.tipo_agendamento = "";
      }

      SchedulesService.get({ ...params })
        .then(({ data }) => {
          const { total, data: items, current_page, links } = data;

          this.total = total;
          this.page = current_page;
          this.data = Object.values(items);
          this.rows = this.data.length;
          this.links = links;
        })
        .finally(() => {
          //this.loadScheduleValues();
          this.isLoading = false;
        });

      for (var i = 0; i < this.data.length; i++) {
        this.jsondata[(this.data[i].id = this.data[i].value)],
          this.jsondata[(this.data[i].name = this.data[i].value)];
      }

      const values = this.data.reduce(
        (prev, cur) => parseFloat(prev) + parseFloat(cur.value),
        0
      );

      this.schedule_values.search_total = values;

      if (this.perPage < this.total) {
        this.schedule_values.paginated_total = this.perPage;
      } else {
        this.schedule_values.paginated_total = this.total;
      }
      this.schedule_values.total_of_schedules = this.total;
      this.schedule_values.average =
        this.schedule_values.search_total /
        this.schedule_values.total_of_schedules;
    },

    delete(id) {
      SchedulesService.delete(id)
        .then(() => {
          this.$buefy.snackbar.open("Consulta excluída com sucesso.");
          this.load();
        })
        .catch(({ response }) => {
          if (typeof response != "undefined" && response.status != 200) {
            this.$buefy.snackbar.open(
              response.message || "Erro ao tentar excluir a Consulta.",
              "error",
              4000
            );
          }
        });
    },

    onRangeChange(values) {
      const dates = values.map((date) => moment(date).format("YYYY-MM-DD"));
      this.dates = dates;

      this.selectedPeriod = `${dates[0]},${dates[1]}`;
    },

    exportSchedules() {
      this.isExporting = true;

      const params = {};

      if (this.status != "ALL") {
        params["status"] = this.status;
      } else {
        this.status = "";
      }

      if (this.searchDoctorTerm) {
        params["lawyer_info"] = this.searchDoctorTerm;
      }

      if (this.searchPatientTerm) {
        params["client_info"] = this.searchPatientTerm;
      }

      if (this.dates.length == 2) {
        params["start_end_between"] = `${this.dates[0]},${this.dates[1]}`;
      }

      if (this.searchSpecialtyId) {
        params["specialty_id"] =
          this.searchSpecialtyId != null ? this.searchSpecialtyId : "";
      }

      SchedulesService.export({ ...params })
        .then(({ data }) => {
          const fileName = data.split("/")[data.split("/").length - 1];
          const fileLink = document.createElement("a");

          fileLink.target = "_blank";
          fileLink.href = data;
          fileLink.setAttribute("download", fileName);
          fileLink.setAttribute("style", "display:none;");

          document.body.appendChild(fileLink);
          fileLink.click();

          fileLink.remove();
        })
        .catch(({ response }) => {
          const message =
            response.data.message || "Erro ao realizar exportação.";
          this.$buefy.snackbar.open(message);
        })
        .finally(() => (this.isExporting = false));
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.select-input .select,
.select-input .select select {
  width: 100%;
}
</style>
