<template>
  <section class="reward-modal-view">
    <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>

    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ modalTitle }}</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div class="card cadastro">
          <div class="card-body">
            <div class="card-title-cadastro">Nome da recompensa</div>
            <app-input v-model="reward.name" :errors="errors.name" />
          </div>
        </div>
        <div class="card cadastro">
          <div class="card-body">
            <div class="card-title-cadastro">Meta de conversão</div>
            <app-input v-model="reward.goal" :errors="errors.goal" />
          </div>
        </div>
        <div class="card cadastro">
          <div class="card-body">
            <div class="card-title-cadastro">Valor da recompensa</div>
            <app-input
              ref="valueInput"
              v-model="reward.value"
              :mask="masks.money"
              :errors="errors.value"
            />
          </div>
        </div>
      </section>
      <footer class="modal-card-foot is-right">
        <b-button @click="$emit('close')">Fechar</b-button>
        <b-button @click="onSubmit" :disabled="!canSave" type="is-primary"
          >Salvar
        </b-button>
      </footer>
    </div>
  </section>
</template>

<script>
import ReferralProgramService from "../../../services/referral-program.service";
import MASKS from "@/constants/masks.constant";

export default {
  props: {
    rewardProp: Object,
  },
  data: () => ({
    isLoading: false,
    reward: {},
    errors: {},
    masks: MASKS,
  }),
  computed: {
    isNew() {
      return !this.reward?.id;
    },
    modalTitle() {
      return this.isNew ? "Cadastrar recompensa" : "Editar recompensa";
    },
    canSave() {
      return this.reward.name && this.reward.goal && this.reward.value;
    },
  },
  methods: {
    onSubmit() {
      const data = {
        ...this.reward,
        value: parseFloat(
          this.reward.value.replace(/[^\d,-]/g, "").replaceAll(",", ".")
        ),
      };
      this.isNew ? this.store(data) : this.update(data);
    },
    update(data) {
      this.isLoading = true;

      ReferralProgramService.updateReward(this.reward.id, data)
        .then(() => {
          this.$buefy.snackbar.open("Recompensa atualizada com sucesso!");
          this.$emit("close", true);
        })
        .catch((response) => {
          const { data, status } = response;

          if (status === 422) {
            this.errors = data.errors;
          } else
            this.$buefy.snackbar.open(
              "Erro ao tentar a atualizar a recompensa.",
              "error"
            );
        })
        .finally(() => (this.isLoading = false));
    },
    store(data) {
      this.isLoading = true;

      ReferralProgramService.createReward(data)
        .then(() => {
          this.$buefy.snackbar.open("Recompensa criada com sucesso!");
          this.$emit("close", true);
        })
        .catch((response) => {
          const { data, status } = response;

          if (status === 422) {
            this.errors = data.errors;
          } else
            this.$buefy.snackbar.open(
              "Erro ao tentar criar recompensa.",
              "error"
            );
        })
        .finally(() => (this.isLoading = false));
    },
  },
  beforeMount() {
    this.reward = { ...this.rewardProp };
  },
};
</script>

<style></style>
