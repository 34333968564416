import Vue from 'vue';

export default Vue.mixin({
  methods: {
    notifyError(error, defaultMessage = null, alertType = 'is-danger') {
      let message = defaultMessage;

      if (typeof error.errors == 'object' && this.errors) {
        this.errors =
          'errors' in error.errors ? error.errors.errors : error.errors;

        message =
          'message' in error.errors ? error.errors.message : defaultMessage;
      }

      if (typeof error.errors == 'string') {
        message = error.errors;
      }

      message &&
        this.$buefy.snackbar.open({
          message,
          type: alertType,
          duration: 5000,
          pauseOnHover: true,
        });
    },
  },
});
