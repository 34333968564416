import * as moment from 'moment';

export default [
    {
      id: 1,
      name: 'Esse mês',
      start: moment().startOf('month'),
      end: moment().endOf('month'),
      clear: false,
      custom: false,
    },
    {
      id: 2,
      name: 'Mês anterior',
      start: moment().subtract(1,'month').startOf('month'),
      end: moment().subtract(1,'month').endOf('month'),
      clear: false,
      custom: false,
    },
    {
      id: 3,
      name: 'Próximo Mês',
      start: moment().add(1, 'month').startOf('month'),
      end: moment().add(1, 'month').endOf('month'),
      clear: false,
      custom: false,
    },
    {
      id: 4,
      name: 'Hoje',
      start: moment(),
      end: moment(),
      clear: false,
      custom: false,
    },
    {
      id: 5,
      name: 'Ontem',
      start: moment().subtract(1,'day').startOf('day'),
      end: moment().subtract(1,'day'),
      clear: false,
      custom: false,
    },
    {
      id: 6,
      name: 'Amanhã',
      start: moment().add(1,'day').startOf('day'),
      end: moment().add(1,'day').endOf('day'),
      clear: false,
      custom: false,
    },
    {
      id: 7,
      name: 'Essa semana',
      start: moment().startOf('week'),
      end: moment().endOf('week'),
      clear: false,
      custom: false,
    },
    {
      id: 8,
      name: 'Semana anterior',
      start: moment().subtract(1, 'week').startOf('week'),
      end: moment().subtract(1, 'week').endOf('week'),
      clear: false,
      custom: false,
    },
    {
      id: 9,
      name: 'Próxima semana',
      start: moment().add(1, 'week').startOf('week'),
      end: moment().add(1, 'week').endOf('week'),
      clear: false,
      custom: false,
    },
    {
      id: 10,
      name: 'Esse ano',
      start: moment().startOf('year'),
      end: moment().endOf('year'),
      clear: false,
      custom: false,
    },
    {
      id: 11,
      name: 'Ano anterior',
      start: moment().subtract(1, 'year').startOf('year'),
      end: moment().subtract(1, 'year').endOf('year'),
      clear: false,
      custom: false,
    },
    {
      id: 12,
      name: 'Sem período definido',
      start: moment().subtract(50, 'year').startOf('year'),
      end: moment().endOf('year'),
      clear: false,
      custom: false,
    },
    {
      id: 13,
      name: 'Definir período especifíco',
      custom: true,
    },
  ];
