import Vue from "vue";

export default Vue.mixin({
    methods: {
        toast(message, type = "is-success") {
            this.$buefy.snackbar.open({
                message,
                type,
                duration: 5000,
                pauseOnHover: true,
            });
        },
    },
});
