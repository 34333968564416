<template>
  <div class="doctor-view">
    <app-header icon="user-cog" title="Incluir Advogado"></app-header>
    <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>

    <section>
      <form @submit="onSubmit" v-if="!isLoading">
        <div class="container-cadastro">
          <div class="row mt-4">
            <div class="col-11 col-lg-4 col-md-11 mx-sm-auto mx-lg-0">
              <div class="subtitle_cadastro fdark">Foto</div>
              <div
                @click="onImageClick(doctor.photo)"
                class="card cadastro photo-this.review text-center"
                style="height: 300px; display: flex; align-items: center"
              >
                <img
                  v-if="doctor.photo"
                  :src="doctor.photo"
                  style="
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                    max-height: 300px;
                  "
                />
              </div>
              <app-file-picker
                label="Foto"
                accept="image/*"
                v-model="doctor.photo"
                :errors="doctor.photo"
                @fileSelected="onPhotoSelected"
                @fileDeleted="() => (this.doctor.photo = '')"
              >
              </app-file-picker>
            </div>

            <div class="col-11 col-lg-4 col-md-11 mx-sm-auto mx-lg-0">
              <div class="subtitle_cadastro fdark">Foto da OAB (frente)</div>
              <div
                @click="onImageClick(doctor.crm_photo_front)"
                class="card cadastro photo-this.review"
                style="height: 300px; display: flex; align-items: center"
              >
                <img
                  v-if="doctor.crm_photo_front"
                  :src="doctor.crm_photo_front"
                  style="
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                    max-height: 300px;
                  "
                />
              </div>
              <app-file-picker
                accept="image/*"
                label="Foto da carteirinha OAB"
                v-model="doctor.crm_photo_front"
                :errors="doctor.crm_photo_front"
                @fileSelected="onCrmPhotoSelected"
                @fileDeleted="() => (this.doctor.crm_photo_front = '')"
              >
              </app-file-picker>
            </div>

            <div class="col-11 col-lg-4 col-md-11 mx-sm-auto mx-lg-0">
              <div class="subtitle_cadastro fdark">Foto da OAB (verso)</div>
              <div
                @click="onImageClick(doctor.crm_photo_verse)"
                class="card cadastro photo-this.review"
                style="height: 300px; display: flex; align-items: center"
              >
                <img
                  v-if="doctor.crm_photo_verse"
                  :src="doctor.crm_photo_verse"
                  style="
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                    max-height: 300px;
                  "
                />
              </div>

              <app-file-picker
                accept="image/*"
                label="Foto da carteirinha OAB"
                v-model="doctor.crm_photo_verse"
                :errors="doctor.crm_photo_verse"
                @fileSelected="onCrmPhotoSelected"
                @fileDeleted="() => (this.doctor.crm_photo_verse = '')"
              >
              </app-file-picker>
            </div>
          </div>

          <div class="title_cadastro">ATIVO</div>
          <div class="row mt-4">
            <div class="col-4 col-lg-4 col-md-4">
              <toggle-button
                v-model="doctor.active"
                :labels="{ checked: 'Sim', unchecked: 'Não' }"
              />
            </div>
          </div>

          <div class="title_cadastro">APROVADO</div>
          <div class="row mt-4">
            <div class="col-4 col-lg-4 col-md-4">
              <toggle-button
                v-model="doctor.approved"
                :labels="{ checked: 'Sim', unchecked: 'Não' }"
              />
            </div>
          </div>

          <div class="title_cadastro">DADOS PESSOAIS</div>
          <div class="row mt-4">
            <div class="col-11 col-lg-5 col-md-11 mx-sm-auto mx-lg-0">
              <div class="card cadastro">
                <div class="card-body">
                  <div class="card-title-cadastro">Nome</div>
                  <input
                    class="input-cadastro"
                    type="text"
                    name="name"
                    ref="name"
                    v-model="doctor.name"
                    :errors="errors.name"
                    is-required
                  />
                </div>
              </div>
            </div>

            <div class="col-11 col-lg-2 col-md-11 mx-sm-auto mx-lg-0">
              <div class="card cadastro">
                <div class="card-body">
                  <div class="card-title-cadastro">CPF</div>
                  <input
                    class="input-cadastro"
                    type="text"
                    @blur="testaCPF"
                    ref="cpf"
                    name="cpf"
                    is-required
                    v-model="doctor.cpf"
                    :errors="errors.cpf"
                    :mask="MASKS.cpf"
                    v-mask="'###.###.###-##'"
                  />
                </div>
              </div>
            </div>

            <div class="col-11 col-lg-3 col-md-11 mx-sm-auto mx-lg-0">
              <div class="card cadastro">
                <div class="card-body">
                  <div class="card-title-cadastro">
                    Tipo de Atendimento

                    <b-tooltip
                      label="Os tipos Chat, Ligação e Vídeo Chamadas são integrados com o WhatsApp, a Ligação também integra com a chamada convencional. O cliente irá selecionar uma dessas opções disponibilizadas pelo advogado"
                      style="text-align: justify"
                    >
                      <b-icon
                        class="toggle-question ml-2 mb-1"
                        icon="question-circle-fill"
                      ></b-icon>
                    </b-tooltip>
                  </div>

                  <b-field>
                    <multiselect
                      class="multiselect-custom"
                      v-model="appointment_types_selected"
                      :options="appointment_types"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      placeholder="Selecionar..."
                      display-label="true"
                      label="text"
                      track-by="id"
                      :searchable="false"
                      :allow-empty="true"
                      :preselect-first="false"
                      open-direction="bottom"
                      @select="onSelect"
                      @remove="onRemove"
                    >
                      <template slot="option" slot-scope="{ option }">
                        <span class="multiselect-option" :id="option.id">
                          {{ option.name }}
                        </span>
                      </template>

                      <template slot="selection" slot-scope="{ values }">
                        <p
                          class="multiselect-option m-0"
                          v-if="values.length > 0"
                        >
                          {{ values.length }}
                          {{
                            values.length > 1
                              ? "tipos selecionados"
                              : "tipo selecionado"
                          }}
                        </p>
                      </template>
                    </multiselect>
                  </b-field>
                </div>
              </div>
            </div>

            <div class="col-11 col-lg-2 col-md-11 mx-sm-auto mx-lg-0">
              <div class="card cadastro">
                <div class="card-body">
                  <div class="card-title-cadastro">Tipos Selecionados</div>

                  <app-input
                    type="text"
                    :value="selectedTypeAppointments"
                  ></app-input>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-11 col-lg-3 col-md-11 mx-sm-auto mx-lg-0">
              <div class="card cadastro">
                <div class="card-body">
                  <div class="card-title-cadastro">Data de nascimento</div>

                  <b-datepicker
                    v-model="doctor.birthday"
                    :locale="'pt-BR'"
                    :years-range="[-100, 100]"
                    placeholder="Selecionar data..."
                    icon="calendar"
                    trap-focus
                    :errors="errors.birthday"
                    is-required
                    ref="birthday"
                    clearable
                    clearFilters
                    editable
                  ></b-datepicker>
                </div>
              </div>
            </div>

            <div class="col-11 col-lg-3 col-md-11 mx-sm-auto mx-lg-0">
              <div class="card cadastro">
                <div class="card-body">
                  <div class="card-title-cadastro">Telefone fixo</div>
                  <input
                    v-model="doctor.phone"
                    :errors="errors.phone"
                    v-mask="'(##) ####-####'"
                    class="input-cadastro"
                    type="text"
                    ref="phone"
                    name="phone"
                  />
                </div>
              </div>
            </div>

            <div class="col-11 col-lg-3 col-md-11 mx-sm-auto mx-lg-0">
              <div class="card cadastro">
                <div class="card-body">
                  <div class="card-title-cadastro">Celular</div>
                  <input
                    class="input-cadastro"
                    type="text"
                    ref="cellphone"
                    name="cellphone"
                    :errors="errors.cellphone"
                    maxlength="15"
                    is-required
                    v-model="doctor.cellphone"
                    v-mask="'(##) #####-####'"
                  />
                </div>
              </div>
            </div>

            <div class="col-11 col-lg-3 col-md-11 mx-sm-auto mx-lg-0">
              <div class="card cadastro">
                <div class="card-body">
                  <div class="card-title-cadastro">Email</div>
                  <input
                    class="input-cadastro"
                    type="text"
                    ref="email"
                    name="email"
                    is-required
                    v-model="doctor.user.email"
                    :errors="errors.email"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-11 col-lg-2 col-md-11 mx-sm-auto mx-lg-0">
              <div class="card cadastro">
                <div class="card-body">
                  <div class="card-title-cadastro">OAB</div>
                  <input
                    class="input-cadastro"
                    type="text"
                    ref="crm"
                    name="crm"
                    v-model="doctor.crm"
                    :errors="errors.crm"
                    is-required
                  />
                </div>
              </div>
            </div>

            <div class="col-11 col-lg-3 col-md-11 mx-sm-auto mx-lg-0">
              <div class="card cadastro">
                <div class="card-body">
                  <div class="card-title-cadastro">Senha</div>
                  <div class="d-flex gap-1">
                    <input
                      class="input-cadastro"
                      :type="showPassword1 ? 'text' : 'password'"
                      ref="password"
                      name="password"
                      minlength="6"
                      v-model="doctor.password"
                      is-required
                      :errors="errors.password"
                    />

                    <div>
                      <b-button
                        class="border-0 p-2 remove-focus"
                        @click="onShowPassword('1')"
                      >
                        <b-icon
                          v-if="showPassword1"
                          icon="eye-slash-fill"
                        ></b-icon>
                        <b-icon
                          v-else-if="!showPassword1"
                          icon="eye-fill"
                        ></b-icon>
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-11 col-lg-3 col-md-11 mx-sm-auto mx-lg-0">
              <div class="card cadastro">
                <div class="card-body">
                  <div class="card-title-cadastro">Confirmar senha</div>
                  <div class="d-flex gap-1">
                    <input
                      class="input-cadastro"
                      :type="showPassword2 ? 'text' : 'password'"
                      ref="password_confirmation"
                      name="password_confirmation"
                      minlength="6"
                      v-model="doctor.password_confirmation"
                      is-required
                      :errors="errors.password_confirmation"
                    />

                    <div>
                      <b-button
                        class="border-0 p-2 remove-focus"
                        @click="onShowPassword('2')"
                      >
                        <b-icon
                          v-if="showPassword2"
                          icon="eye-slash-fill"
                        ></b-icon>
                        <b-icon
                          v-else-if="!showPassword2"
                          icon="eye-fill"
                        ></b-icon>
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-11 col-lg-2 col-md-11 mx-sm-auto mx-lg-0">
              <div class="card cadastro">
                <div class="card-body">
                  <div class="card-title-cadastro">OAB UF</div>
                  <b-select
                    v-model="doctor.crm_uf"
                    placeholder="Selecionar"
                    name="crm_uf"
                    :errors="errors.crm_uf"
                    expanded
                  >
                    <b-select-option
                      v-for="state in states"
                      :key="state.id"
                      :value="state.id"
                    >
                      {{ state.name }}
                    </b-select-option>
                  </b-select>
                </div>
              </div>
            </div>

            <div class="col-12 col-lg-12 col-md-12 mx-sm-auto mx-lg-0">
              <div class="card cadastro">
                <div class="card-body h-auto">
                  <div class="card-title-cadastro mb-2">Especialidade</div>
                  <tag-input
                    v-if="hasSpecialtiesSelected"
                    v-model="doctor.specialties"
                    :options="specialties"
                    :selecteds="doctorSpecialties"
                    :max="3"
                    :errors="errors.specialties"
                    :tagRemove="true"
                    :disabled="true"
                    is-required
                  >
                  </tag-input>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-11 mx-auto">
              <div class="card-title-cadastro mb-3">Mini Currículo</div>
              <editor
                class="input-cadastro"
                v-model="doctor.mini_curriculum"
                :api-key="editorApiKey"
                :init="tinyConfig"
                placeholder="O mini CV irá aparecer para os clientes"
                modal-events="keydown"
              ></editor>
            </div>
          </div>

          <div class="title_cadastro">ENDEREÇO</div>
          <div class="row mt-4">
            <div class="col-11 col-lg-2 col-md-11 mx-sm-auto mx-lg-0">
              <div class="card cadastro">
                <div class="card-body">
                  <div class="card-title-cadastro">CEP</div>
                  <app-input
                    v-model="doctor.cep"
                    type="text"
                    v-mask="'#####-###'"
                    :errors="errors.cep"
                    is-required
                  ></app-input>
                </div>
              </div>
            </div>

            <div class="col-11 col-md-11 mx-sm-auto mx-lg-0">
              <div class="card cadastro">
                <div class="card-body">
                  <div class="card-title-cadastro">Endereço</div>
                  <input
                    class="input-cadastro"
                    type="text"
                    name="street"
                    is-required
                    v-model="doctor.street"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-11 col-lg-2 col-md-11 mx-sm-auto mx-lg-0">
              <div class="card cadastro">
                <div class="card-body">
                  <div class="card-title-cadastro">Número</div>
                  <input
                    class="input-cadastro"
                    type="text"
                    name="number"
                    is-required
                    v-model="doctor.number"
                    :errors="errors.number"
                    @keypress="onlyNumbers"
                  />
                </div>
              </div>
            </div>

            <div class="col-11 col-lg-5 col-md-11 mx-sm-auto mx-lg-0">
              <div class="card cadastro">
                <div class="card-body">
                  <div class="card-title-cadastro">Complemento</div>
                  <input
                    class="input-cadastro"
                    type="text"
                    name="complement"
                    v-model="doctor.complement"
                    :errors="errors.complement"
                  />
                </div>
              </div>
            </div>

            <div class="col-11 col-lg-4 col-md-11 mx-sm-auto mx-lg-0">
              <div class="card cadastro">
                <div class="card-body">
                  <div class="card-title-cadastro">Bairro</div>
                  <input
                    class="input-cadastro"
                    type="text"
                    name="neighborhood"
                    is-required
                    v-model="doctor.neighborhood"
                    :errors="errors.neighborhood"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-11 col-lg-4 col-md-11 mx-sm-auto mx-lg-0">
              <div class="card cadastro">
                <div class="card-body">
                  <div class="card-title-cadastro">Cidade</div>
                  <app-input v-model="doctor.city_name" type="text"></app-input>
                </div>
              </div>
            </div>

            <div class="col-11 col-lg-4 col-md-11 mx-sm-auto mx-lg-0">
              <div class="card cadastro">
                <div class="card-body">
                  <div class="card-title-cadastro">Estado</div>
                  <app-input
                    v-model="doctor.state_name"
                    type="text"
                  ></app-input>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-12">
              <b-checkbox v-model="doctor.payer_user"
                >Acesso ao sistema mediante a pagamento</b-checkbox
              >
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-11 col-lg-4 col-md-8 mx-auto mt-3">
              <b-button
                class="btn-block bg-success"
                native-type="submit"
                type="is-primary"
                ref="btn_submit"
              >
                Salvar
              </b-button>
            </div>

            <div
              v-if="!doctor.approved"
              class="col-11 col-lg-4 col-md-8 mx-auto mt-3"
            >
              <b-button
                class="btn-block"
                variant="light"
                @click="onRejectLawyerClick()"
                type="is-danger"
              >
                Reprovar
              </b-button>
            </div>

            <div class="col-11 col-lg-4 col-md-8 mx-auto mt-3">
              <b-button
                class="btn-block"
                variant="light"
                @click="goBack"
                type="is-dark"
              >
                Voltar
              </b-button>
            </div>
          </div>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";
import Editor from "@tinymce/tinymce-vue";

import StatesService from "@/services/states.service";
import CitiesService from "@/services/cities.service";
import CepService from "@/services/cep.service";
import DoctorsService from "@/services/doctors.service.js";
import SpecialtiesService from "@/services/specialties.service";
import AdminsService from "@/services/admins.service";

import FileMixin from "@/mixins/FileMixin";
import MASKS from "@/constants/masks.constant";
import BanksService from "@/services/banks.service";
import DOCUMENTS_STATUS from "@/constants/document_juno_status.constant";

import TagInput from "../../components/inputs/TagInput.vue";
import RejectJustificationVue from "../../modals/RejectJustification.vue";

export default {
  components: { Multiselect, TagInput, editor: Editor },
  mixins: [FileMixin],
  error_messages: [],

  data: () => ({
    editorApiKey: process.env.VUE_APP_TINY_MCE_KEY,
    tinyConfig: {
      menubar: false,
      plugins: ["link image preview"],
      toolbar: "undo redo | bold italic | link",
    },

    doctorSpecialties: [],
    nfoto: "",
    MASKS: MASKS,
    doctorCity: null,
    banks: [],
    maxDigitAgency: 4,
    patients: [],
    states: [],
    cities: [],
    state_id: null,
    city_id: null,
    element: null,
    rev: null,
    add: null,
    cpf: null,
    cnpj: null,
    phone: null,
    cellphone: null,
    doctorId: null,
    isLoading: false,
    is_loading_transactions: false,
    transactions_data: [],
    total: 0,
    page: 1,
    perPage: 10,
    balance_info: {
      balance: null,
      withheld: null,
      transferable: null,
    },
    user: {
      email: null,
    },
    errors: {},
    isLoadingCity: false,
    isLoadingBank: false,
    countLoad: 0,
    searchSpecialty: "",
    searchTipoAtendimento: "",
    doctorTipoAtendimento: [],
    specialties: [],
    photoSelected: false,
    crmPhotoSelected: false,
    documentPhotoSelected: false,
    showAccountComplementNumber: false,
    documentPhotoIsLoading: false,
    selfieDocumentIsLoading: false,
    crmPhotoIsLoading: false,
    PhotoIsLoading: false,
    isCreate: false,
    showPassword1: false,
    showPassword2: false,
    doctor: {
      city: {
        id: null,
      },
      user: {
        email: null,
      },
      juno_documents_status: {
        type: null,
        status: null,
      },
      payer_user: true,
    },
    isPaginated: false,
    isPaginationSimple: false,
    isPaginationRounded: false,
    paginationPosition: "both",
    defaultSortDirection: "asc",
    sortIcon: "arrow-down",
    sortIconSize: "is-small",
    currentPage: 1,
    complete: false,
    pageOptions: [10, 20, 40, 80],
    tp: [],
    tps: [],
    appointment_types_selected: [],
    appointment_types: [
      { id: 1, name: "1 - Chat" },
      { id: 2, name: "2 - Ligação" },
      { id: 3, name: "3 - Vídeo Chamada" },
    ],
    validations: {
      photo: {
        message: "A foto é obrigatória",
        required: true,
        removeMask: false,
      },
      crm_photo_front: {
        message: "A foto da carteirinha da OAB (frente) é obrigatória",
        required: true,
        removeMask: false,
      },
      crm_photo_verse: {
        message: "A foto da carteirinha da OAB (verso) é obrigatória",
        required: true,
        removeMask: false,
      },
      phone: {
        message: "O telefone é obrigatório",
        required: false,
        removeMask: true,
        length: 10,
      },
      cellphone: {
        message: "O número de celular é obrigatório",
        required: true,
        removeMask: true,
        length: 11,
      },
      name: {
        message: "O nome é obrigatório",
        required: true,
        removeMask: false,
      },
      cpf: {
        message: "O CPF é obrigatório",
        required: true,
        removeMask: true,
        length: 11,
      },
      // appointment_types: {
      //   message: "Selecione o(s) tipo(s) de atendimento",
      //   required: true,
      //   removeMask: false,
      //   is_array: true,
      // },
      birthday: {
        message: "A data de nascimento é obrigatória",
        required: true,
        removeMask: false,
      },
      crm: {
        message: "OAB obrigatório",
        required: true,
        removeMask: false,
      },
      password: {
        message: "A senha é obrigatória",
        required: false,
        removeMask: false,
      },
      password_confirmation: {
        message: "A confirme a senha",
        required: () => (this.doctor.password ? true : false),
        removeMask: false,
      },
      crm_uf: {
        message: "O estado da OAB é obrigatório",
        required: true,
        removeMask: false,
      },
      cep: {
        message: "O CEP é obrigatório",
        required: true,
        removeMask: true,
        length: 8,
      },
      street: {
        message: "O endereço é obrigatório",
        required: true,
        removeMask: false,
      },
      number: {
        message: "O número é obrigatório",
        required: true,
        removeMask: false,
      },
      neighborhood: {
        message: "O bairro é obrigatório",
        required: true,
        removeMask: false,
      },
      city_name: {
        message: "A cidade é obrigatória",
        required: true,
        removeMask: false,
      },
      state_name: {
        message: "O estado é obrigatório",
        required: true,
        removeMask: false,
      },
      // monthly_income_or_revenue: {
      //   message: "O valor da renda é obrigatório",
      //   required: true,
      //   removeMask: true,
      // },
      // mother_name: {
      //   message: "O nome da mãe é obrigatório",
      //   required: true,
      //   removeMask: false,
      // },
    },
  }),

  mounted() {
    this.doctorId = this.$route.params.id;

    this.doctorId && this.loadDoctor();
    this.loadDoctorTransactions();
    this.loadStates();
    this.loadSpecialties();
    this.loadBanks();
  },

  computed: {
    ...mapGetters(["secondStage"]),
    hasSpecialtiesSelected() {
      return this.doctorSpecialties.length;
    },
    filteredTipoAtendimento() {
      return this.tipos_atendimento.filter(
        (appointment_types) =>
          appointment_types.name &&
          appointment_types.name
            .toLowerCase()
            .indexOf(this.searchTipoAtendimento.toLowerCase()) >= 0
      );
    },

    filteredSpecialty() {
      return this.specialties.filter(
        (specialty) =>
          specialty.name
            .toLowerCase()
            .indexOf(this.searchSpecialty.toLowerCase()) >= 0
      );
    },

    selectedTipoAtendimento: function () {
      let selectedTipoAtendimento = this.tipos_atendimento.find(
        (appointment_types) =>
          appointment_types.id === this.doctor.appointment_types
      );

      if (selectedTipoAtendimento == null) {
        return "Adicione tipo de atendimento";
      }

      return selectedTipoAtendimento.name;
    },

    selectedSpecialty: function () {
      let selectedSpecialty = this.specialties.find(
        (specialty) => specialty.id === this.doctor.specialties
      );

      if (selectedSpecialty == null) {
        return "Adicione sua especialidade";
      }

      return selectedSpecialty.name;
    },

    selectedBankName() {
      return (
        this.banks.find((bank) => bank.bank_number == this.doctor.bank)?.name ??
        ""
      );
    },

    headerTitle() {
      return this.doctorId
        ? "Editar informações do advogado"
        : "Cadastrar NOVO Advogado";
    },

    selectedTypeAppointments() {
      return this.appointment_types_selected
        .map((x) => x.name.replaceAll(/[-\d]/g, ""))
        .join(", ");
    },

    maskAgency() {
      return Array(this.maxDigitAgency).fill("#").join("");
    },
  },

  watch: {
    perPage() {
      if (this.perPage > this.transactions_data.length) this.loadPatints();
    },

    "doctor.bank"() {
      const banksMaxDigit = ["001"];

      if (this.doctor.bank !== 104) this.showAccountComplementNumber = true;
      else this.showAccountComplementNumber = false;

      this.maxDigitAgency = banksMaxDigit.includes(this.doctor.bank) ? 5 : 4;
    },
  },

  methods: {
    statesGetter: StatesService.search,
    citiesGetter: CitiesService.search,
    banksGetter: BanksService.search,
    banksListen: BanksService.listBank,
    onRejectLawyerClick() {
      this.$buefy.modal.open({
        parent: this,
        component: RejectJustificationVue,
        hasModalCard: true,
        trapFocus: true,

        events: {
          close: (data) => data && this.rejectlawyer(data),
        },
      });
    },
    rejectlawyer(justification) {
      this.isLoading = true;

      AdminsService.rejectLawyer(this.doctorId, { justification })
        .then(({ data }) => {
          this.$buefy.snackbar.open({
            message: data.message,
            type: "is-success",
          });
          this.$router.go(-1);
        })
        .catch(() =>
          this.$buefy.snackbar.open({
            message: "Erro ao tentar reprovar cadastro de advogado",
            type: "is-danger",
          })
        )
        .finally(() => (this.isLoading = false));
    },
    loadBanks() {
      this.isLoadingBank = true;
      BanksService.listBank()
        .then(({ data }) => (this.banks = data))
        .finally(() => (this.isLoadingBank = false));
    },

    loadDoctorTransactions() {
      const params = {
        page: this.page,
        perPage: this.perPage,
        doctor_id: this.doctorId,
      };

      this.is_loading_transactions = true;

      DoctorsService.getDoctorTransactions({ ...params })
        .then(({ data }) => {
          const { total, data: items, current_page } = data;

          this.total = total;
          this.page = current_page;
          this.transactions_data = items;
        })
        .finally(() => (this.is_loading_transactions = false));
    },

    loadSpecialties() {
      SpecialtiesService.getAll().then(({ data }) => {
        this.specialties = data;
      });
    },

    loadDoctor() {
      this.isLoading = true;

      DoctorsService.getId(this.doctorId)
        .then(({ data: doctor }) => {
          this.doctor = {
            ...doctor,
            on_line: !!doctor.on_line,
            email: doctor.user.email,
            city: { name: !doctor.city ? "" : doctor.city.name },
            birthday: doctor.birthday ? new Date(doctor.birthday) : null,
            specialties: doctor.specialties.map((s) => s.id),
            monthly_income_or_revenue: parseFloat(
              doctor.monthly_income_or_revenue
            ).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            }),
            city_name: doctor.city.name,
            state_name: doctor.city.state.name,
          };

          this.doctorSpecialties = doctor.specialties;

          this.appointment_types_selected = doctor.appointment_types.map(
            ({ id, name }) => ({
              id,
              name,
            })
          );
          this.doctor.appointment_types = doctor.appointment_types.map(
            ({ id }) => id
          );

          this.complete = this.doctor.account_stage == 2; // complete;

          if (this.isJunoDocRejected("ID")) this.doctor.personal_document = "";
          if (this.isJunoDocRejected("SELFIE"))
            this.doctor.selfie_document = "";
        })
        .catch((e) => {
          console.log(e);
          this.$buefy.snackbar.open({
            message: "Erro ao carregar dados do advogado",
            type: "is-danger",
            duration: 3000,
            pauseOnHover: true,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });

      DoctorsService.getBalanceInfo(this.doctorId).then(({ data }) => {
        this.balance_info = { ...data };
      });
    },

    onSelect(selected) {
      selected && this.doctor.appointment_types.push(selected.id);
    },

    onRemove(selected) {
      if (selected) {
        this.appointment_types_selected = this.appointment_types_selected.filter(
          (x) => x.id !== selected.id
        );

        this.doctor.appointment_types = this.doctor.appointment_types.filter(
          (x) => x !== selected.id
        );
      }
    },

    onPageChange(page) {
      this.page = page;
      this.loadDoctorTransactions();
    },

    onShowPassword(inputN) {
      if (inputN === "1") this.showPassword1 = !this.showPassword1;
      else if (inputN === "2") this.showPassword2 = !this.showPassword2;
    },

    onlyNumbers(event) {
      if (event.keyCode < 48 || event.keyCode > 57) event.preventDefault();
    },

    onImageClick(img) {
      if (!img) return;

      window.open(img, "_blank");
    },

    onSubmit(event) {
      event.preventDefault();
      // atribuindo objeto referenciado, retornando um novo objeto
      const form = { ...this.doctor };
      let invalid = false;

      if (
        [null, undefined].includes(this.doctor.user?.email) ||
        this.doctor.user?.mail?.length == 0
      ) {
        this.$buefy.snackbar.open({
          message: "Email é obrigatório",
          type: "is-danger",
        });
        return;
      }

      form["monthly_income_or_revenue"] = form["monthly_income_or_revenue"]
        ?.replace(/[\D]+/, "")
        .replace(",", ".");

      if (form["monthly_income_or_revenue"] <= 0)
        form["monthly_income_or_revenue"] = "";

      for (let key in this.validations) {
        invalid = false;

        if (this.validations[key]?.removeMask) {
          let value = form[key];
          form[key] = value?.replaceAll(/[^\w\d]+/g, "");
        }

        if (
          this.validations[key]?.required &&
          (form[key] === null || form[key]?.length === 0)
        ) {
          invalid = true;
        }

        if (
          "length" in this.validations[key] &&
          form[key]?.length !== this.validations[key]?.length &&
          (this.validations[key].required ||
            (!this.validations[key].required && form[key]?.length > 0))
        ) {
          invalid = true;
        }

        if (
          ("max" in this.validations[key] &&
            form[key]?.length > this.validations[key].max) ||
          ("min" in this.validations[key] &&
            form[key]?.length < this.validations[key].min)
        ) {
          invalid = true;
        }

        if (invalid) {
          return this.$buefy.snackbar.open({
            message: this.validations[key].message,
            duration: 5000,
            type: "is-danger",
          });
        }
      }

      this.doctorId ? this.update(this.doctorId) : this.save();
    },

    async deleteFile() {
      DoctorsService.delfile(this.fileName);
      this.$emit("fileDeleted");
      this.$emit("onSubmit");
    },

    testaCPF() {
      let cpf = this.doctor.cpf.replace(/[^\d]+/g, "").trim();

      if (cpf?.length < 11) return;

      const regex = new RegExp(
        `^(1{11}|2{11}|3{11}|4{1}|5{11}|6{11}|7{11}|8{11}|9{11})$`
      );

      if (cpf?.length === 11 && regex.test(cpf)) {
        this.$buefy.snackbar.open({
          message: "Cpf inválido",
          type: "is-danger",
        });
        this.doctor.cpf = "";
        return;
      }
    },

    onFormatMoney(event) {
      if (!event.target?.value?.trim()) return;

      let value = event.target.value
        ?.replace("R$", "")
        .replace(",", ".")
        .replace(/[^\d.]+/g, "");

      value = parseFloat(value);

      if (isNaN(value)) value = 0;

      event.target.value = value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },

    onFormatClearMoney(event) {
      if (!event.target?.value?.trim()) return;

      event.target.value = event.target.value
        .trim()
        .replace("R$", "")
        .replaceAll(".", "");
    },

    retira_caracteres(value) {
      return value.trim().replaceAll(/[\D]+/g, "");
    },

    goBack() {
      this.$router.go(-1);
    },

    fixedValue(key) {
      return this.balance_info[key]
        ? this.balance_info[key].toFixed(2)
        : (0).toFixed(2);
    },

    async getCepInfo(cep) {
      if (!cep) return;

      cep = cep.replaceAll(/[\D]+/g, "");
      if (cep.length < 8) return;

      cep = cep?.replaceAll(/[\D]+/g, "");
      await CepService.getCepInfo(cep)
        .then(({ data }) => {
          CitiesService.getId(data.city_id)
            .then(({ data }) => {
              this.doctor = {
                ...this.doctor,
                city_id: data.id,
                city_name: data.name,
                state_name: data.state.name,
              };
            })
            .finally(() => (this.isLoading = false));

          this.doctor = {
            ...this.doctor,
            street: data.logradouro,
            neighborhood: data.bairro,
          };
        })
        .catch(() => {
          if (status != 200) {
            this.$buefy.snackbar.open("Cep não localizado");
          }
        })
        .finally(() => (this.isLoading = false));
    },

    loadStates() {
      this.isLoading = true;
      StatesService.get()
        .then(({ data }) => (this.states = data))
        .finally(() => (this.isLoading = false));
    },

    formatData() {
      const data = { ...this.doctor };

      for (let key in data) {
        if ([null, undefined].includes(data[key])) delete data[key];

        if (key in this.validations && this.validations[key].removeMask)
          data[key] = data[key]?.replaceAll(/[\D]+/g, "")?.trim();
      }

      if (this.doctor.photo && this.photoSelected) {
        data["photo"] = this.doctor.photo;
      }

      if (this.doctor.crm_photo_front && this.crmPhotoSelected) {
        data["crm_photo_front"] = this.doctor.crm_photo_front;
      }

      if (this.doctor.crm_photo_verse && this.crmPhotoSelected) {
        data["crm_photo_verse"] = this.doctor.crm_photo_verse;
      }

      if (this.doctor.personal_document && this.documentPhotoSelected) {
        data["personal_document"] = this.doctor.personal_document;
      }

      if (this.doctor.selfie_document && this.documentPhotoSelected) {
        data["selfie_document"] = this.doctor.selfie_document;
      }

      return data;
    },

    async save() {
      this.isSaving = true;

      const data = this.formatData();

      await DoctorsService.save(data)
        .then(({ data }) => {
          this.isCreate = true;
          this.update(data.data.id);
        })
        .catch(({ response }) => {
          const { status } = response;
          let message = "";

          if (status == 422) {
            this.errors = response.data.errors;
            message =
              "Erro ao cadastrar a conta no JUNO. Dados inválidos ou já existentes";
          } else if (status == 400) {
            this.errors = response.data.errors;
            message = "Por favor verifique os dados informados";
          } else {
            message = response.data.message;
          }

          this.$buefy.snackbar.open({
            message,
            type: "is-danger",
            duration: 3000,
            pauseOnHover: true,
          });
        })
        .finally(() => (this.isSaving = false));
    },

    async update(id) {
      this.isSaving = true;

      const data = this.formatData();

      DoctorsService.update(id, data)
        .then(() => {
          this.isCreate = false;
          this.$buefy.snackbar.open("Advogado atualizado com sucesso!");
          this.$router.push({ name: "doctors.index" });
        })
        .catch(({ response }) => {
          const { status } = response;
          if (status != 200) {
            if (response.data.message == "The given data was invalid.") {
              this.errors = response.data.errors;
              this.$buefy.snackbar.open({
                message:
                  "Erro ao cadastrar a conta no JUNO. Dados inválidos ou já existentes",
                type: "is-danger",
                duration: 5000,
                pauseOnHover: true,
              });
            } else {
              this.$buefy.snackbar.open({
                message: response.data.message,
                type: "is-danger",
                duration: 5000,
                pauseOnHover: true,
              });
            }
          } else {
            this.$buefy.snackbar.open({
              message: "Advogado salvo com sucesso.",
              type: "is-success",
              duration: 5000,
              pauseOnHover: true,
            });
          }
        })
        .finally(() => (this.isSaving = false));
    },

    isJunoDocRejected(TYPE) {
      const doctor_juno_status = this.doctor.juno_documents_status;
      if (!this.doctor?.juno_documents_status) return false;

      const doc = Array.isArray(doctor_juno_status)
        ? doctor_juno_status.find((document) => document.type == TYPE)
        : doctor_juno_status[TYPE];

      if (!doc) return false;

      return DOCUMENTS_STATUS[doc.status] === DOCUMENTS_STATUS.REJECTED;
    },

    onCrmPhotoSelected(value) {
      this.fileToBase64(value).then(
        (base64) => (this.doctor.crm_photo = base64)
      );
      this.crmPhotoSelected = true;
    },

    onPhotoSelected(value) {
      this.fileToBase64(value).then((base64) => (this.doctor.photo = base64));
      this.photoSelected = true;
    },

    onDocumentPhotoSelected(value, type) {
      if (type === 1) {
        DoctorsService.setPhoto(this.doctorId, value, type, 1)
          .then(({ data }) => data.url)
          .finally(() => {
            // this.documentPhotoIsLoading = false
          });
        this.fileToBase64(value).then(
          (base64) => (this.doctor.personal_document = base64)
        );
      } else {
        DoctorsService.setPhoto(this.doctorId, value, type, 1)
          .then(({ data }) => data.url)
          .finally(() => {
            // this.selfieDocumentIsLoading = false
          });
        this.fileToBase64(value).then(
          (base64) => (this.doctor.selfie_document = base64)
        );
      }
      this.documentPhotoSelected = true;
    },

    formatacbanco() {
      if (
        ["", "0000000000", "0", null, undefined].includes(this.doctor.account)
      ) {
        this.$buefy.snackbar.open({
          message: "A conta é obrigatória",
          type: "is-danger",
          duration: 4000,
          pauseOnHover: true,
        });
      } else {
        this.$refs.account.value = (
          "0000000000" + this.$refs.account.value
        ).slice(-10);
      }
    },

    formataabanco() {
      this.$refs.agency.value = ("0000" + this.$refs.agency.value).slice(-4);
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.doctor-form-page {
  .required-field {
    border: 1px solid rgb(255, 56, 56);
  }

  .photo-this.review {
    overflow: hidden;

    width: 300px;
    height: 250px;

    cursor: pointer;

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      height: auto !important;
      width: auto !important;
    }
  }

  .card-title-cadastro {
    .span {
      margin-left: 5px;
    }
  }

  .alert {
    margin-top: 10px;
    font-style: italic;
    color: var(--color-primary);
  }

  .subtitle_cad_advogado-edit {
    span {
      margin-top: 1px;
      margin-left: 5px;
    }
  }
}

.subspecialties {
  .tag {
    margin-right: 10px !important;
  }
}

.box-btn-show-password {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  color: var(--color-primary);
}

.remove-focus {
  &:focus,
  &:focus-visible,
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
}
</style>
