import Api from "./api.service";

export default {
  getReferrers: (params) => Api.get("referrers", { params }),
  showReferrer: (id) => Api.get(`referrers/${id}`),
  getRewards: (params) => Api.get("rewards", { params }),
  createReward: (data) => Api.post(`rewards/`, data),
  updateReward: (id, data) => Api.put(`rewards/${id}`, data),
  deleteReward: (id) => Api.delete(`rewards/${id}`),
  getPayments: (params) => Api.get("referrers/payments/list", { params }),
  markPaymentAsPaid: (id, data) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(`${key}`, data[key] == undefined ? "" : data[key]);
    });

    return Api.post(`referrers/payments/${id}/mark-as-paid`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
      params: {
        _method: "PUT",
      },
    });
  },
};
