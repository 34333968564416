import Vue from "vue";

import {
    BootstrapVueIcons,
    BPagination,
    PaginationPlugin,
    SpinnerPlugin,
    ImagePlugin,
    BFormInput,
    BFormText,
    BCol,
    BBadge,
    BFormSelect,
    BFormSelectOption,
    BFormSelectOptionGroup,
} from "bootstrap-vue";

Vue.component("b-pagination", BPagination);
Vue.component("b-form-input", BFormInput);
Vue.component("b-form-text", BFormText);
Vue.component("modal", { template: "#modal-template" });
Vue.component("b-col", BCol);
Vue.component("b-badge", BBadge);
Vue.component("b-select", BFormSelect);
Vue.component("b-select-option", BFormSelectOption);
Vue.component("b-select-option-group", BFormSelectOptionGroup);

Vue.use(PaginationPlugin);
Vue.use(SpinnerPlugin);
Vue.use(ImagePlugin);
Vue.use(BootstrapVueIcons);
