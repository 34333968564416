<template>
  <div class="extra-subscription-credit-modal">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Liberar créditos para assinatura</p>
        <button type="button" class="delete" @click="$emit('close', false)" />
      </header>

      <section class="modal-card-body">
        <div class="row mb-4">
          <div class="col-12">
            <b-table
              :paginated="false"
              id="table-extra-credits"
              :current-page.sync="page"
              :data="extra_credits"
              :total="extra_credits.length"
              :per-page="extra_credits.length"
            >
              <b-table-column
                v-slot="props"
                field="original_credits"
                label="Créditos liberados"
              >
                <p>
                  {{ props.row.original_credits }}
                </p>
              </b-table-column>

              <b-table-column
                v-slot="props"
                field="available_credits"
                label="Créditos disponíveis"
              >
                <p>
                  {{ props.row.available_credits }}
                </p>
              </b-table-column>

              <b-table-column v-slot="props" field="code" label="Válido até">
                <p>
                  {{ props.row.valid_until | date }}
                </p>
              </b-table-column>

              <b-table-column v-slot="props" field="code" label="Liberado em">
                <p>
                  {{ props.row.created_at | date("DD/MM/YYYY [às] HH:mm") }}
                </p>
              </b-table-column>
            </b-table>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="card cadastro">
              <div class="card-body">
                <div class="card-title-cadastro">Créditos liberar</div>
                <input
                  required
                  :min="1"
                  :max="100"
                  type="number"
                  class="input-cadastro"
                  v-model="form.credits"
                />
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="card cadastro">
              <div class="card-body">
                <div class="card-title-cadastro">Validade</div>
                <input
                  required
                  :min="1"
                  type="date"
                  class="input-cadastro"
                  v-model="form.valid_until"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="modal-card-foot">
        <b-button
          @click="submit()"
          type="is-primary"
          :loading="isLoading"
          :disabled="isLoading || !isEnabled"
          >Salvar</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import SubscriptionsService from "@/services/subscriptions.service";

export default {
  props: {
    subscription_id: {
      type: Number,
      default: () => null,
    },
    extra_credits: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    form: {
      credits: 1,
      valid_until: null,
      subscription_id: null,
    },
    yesterday: null,
    isLoading: false,
  }),
  computed: {
    isEnabled() {
      return this.form.credits && this.form.valid_until;
    },
  },
  methods: {
    submit() {
      this.isLoading = true;

      SubscriptionsService.storeExtraSubscriptionCredits({
        ...this.form,
      })
        .then(({ data }) => {
          this.$buefy.snackbar.open(data.message);
          this.$emit("close", true);
        })
        .catch(({ response }) => {
          const { message } = response;
          this.$buefy.snackbar.open(
            message || "Erro ao tentar liberar créditos.",
            "error",
            4000
          );
        })
        .finally(() => (this.isLoading = false));
    },
  },
  beforeMount() {
    const now = new Date();
    this.yesterday = new Date(now.setDate(now.getDate() - 1));

    this.form.subscription_id = this.subscription_id;
  },
};
</script>

<style lang="scss" scoped></style>
