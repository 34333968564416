import Api from './api.service';

export default {
  get: (params) => Api.get('lawyers', { params }),

  getId: (id) => Api.get(`lawyers/${id}`),

  save: (data) => Api.post(`lawyers/`, data),

  update: (id, data) => Api.put(`lawyers/${id}`, data),

  delete: (id) => Api.delete(`lawyers/${id}`),

  delfile: (fileName) => Api.post(`lawyers/${fileName}`),

  export: (filters) => Api.post('lawyers/export', { filters }),

  patch: (id, data) => Api.patch(`lawyers/${id}`, data),

  getDoctorTransactions(params = {}) {
    return Api.get('lawyers-transactions', { params });
  },

  getBalanceInfo(id) {
    return Api.get(`lawyers/balance-info?doctor_id=${id}`);
  },

  getcountByApproved(params = {}) {
    return Api.get('lawyers-approveds', { params });
  },

  setPhoto: (doctorId, file, type, junoFile = 0) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);
    formData.append('juno_file', junoFile);

    return Api.post(`lawyers/${doctorId}/set-photo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    });
  },
  getlawyersAll() {
    return Api.get('lawyers-all');
  },
};
