<template>
    <div class="patient-view">
        <app-header icon="user-cog" title="Incluir Clientes" goes-back></app-header>

        <section>
            <form v-if="!isLoading" @submit.prevent="onSubmit()">
                <div class="container-cadastro">
                    <div class="title_cadastro">FOTO</div>

                    <div class="row col-12 mt-4">
                        <div class="col-4 col-lg-4 col-md-4 col-sm-2">
                            <div class="subtitle_cadastro fdark">Foto</div>
                            <div
                                @click="onImageClick(patient.photo)"
                                class="card cadastro photo-preview"
                                style="
                                    height: 300px;
                                    display: flex;
                                    align-items: center;
                                    marging-top: auto;
                                    marging-bottom: auto;
                                "
                            >
                                <img
                                    :src="patient.photo"
                                    v-if="patient.photo"
                                    style="
                                        position: relative;
                                        top: 50%;
                                        transform: translateY(-50%);
                                        max-height: 300px;
                                    "
                                />
                            </div>
                            <div>
                                <app-file-picker
                                    label="Foto"
                                    v-model="patient.photo"
                                    :errors="patient.photo"
                                    accept="image/*"
                                    @fileSelected="onPhotoSelected"
                                    @fileDeleted="() => (this.patient.photo = '')"
                                    required
                                >
                                </app-file-picker>
                            </div>
                        </div>
                    </div>

                    <div class="title_cadastro">DADOS PESSOAIS</div>
                    <div class="row col-12">
                        <div class="col-8 col-md-8 col-sm-4">
                            <div class="card cadastro">
                                <div class="card-body">
                                    <div class="card-title-cadastro">Nome</div>

                                    <input
                                        class="input-cadastro"
                                        type="text"
                                        name="name"
                                        required
                                        v-model="patient.name"
                                        :errors="errors.name"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="col-4 col-lg-4 col-md-4 col-sm-2">
                            <div class="card cadastro">
                                <div class="card-body">
                                    <div class="card-title-cadastro">CPF</div>
                                    <input
                                        class="input-cadastro"
                                        type="text"
                                        ref="cpf"
                                        @blur="testaCPF"
                                        name="cpf"
                                        required
                                        v-model="patient.cpf"
                                        :errors="errors.cpf"
                                        :mask="MASKS.cpf"
                                        v-mask="'###.###.###-##'"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row col-12">
                        <div class="col-4 col-lg-4 col-md-4 col-sm-2">
                            <div class="card cadastro">
                                <div class="card-body">
                                    <div class="card-title-cadastro">E-mail</div>
                                    <input
                                        class="input-cadastro"
                                        type="mail"
                                        name="email"
                                        required
                                        v-model="patient.user.email"
                                        :errors="errors.email"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-4 col-lg-4 col-md-4 col-sm-2">
                            <div class="card cadastro">
                                <div class="card-body">
                                    <div class="card-title-cadastro">Senha</div>
                                    <input
                                        class="input-cadastro"
                                        type="password"
                                        @blur="v_password"
                                        ref="password"
                                        name="password"
                                        minlength="6"
                                        v-model="patient.password"
                                        :errors="errors.password"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-4 col-lg-4 col-md-4 col-sm-2">
                            <div class="card cadastro">
                                <div class="card-body">
                                    <div class="card-title-cadastro">Confirmação Senha</div>
                                    <input
                                        class="input-cadastro"
                                        type="password"
                                        @blur="v_password_confirmation"
                                        ref="password_confirmation"
                                        name="password_confirmation"
                                        minlength="6"
                                        v-model="patient.password_confirmation"
                                        :errors="errors.password_confirmation"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row col-12">
                        <div class="col-4 col-lg-4 col-md-4 col-sm-2">
                            <div class="card cadastro">
                                <div class="card-body">
                                    <div class="card-title-cadastro">Data de nascimento</div>
                                    <b-datepicker
                                        v-model="patient.birthday"
                                        :locale="'pt-BR'"
                                        :years-range="[-100, 100]"
                                        placeholder="Selecionar data..."
                                        icon="calendar"
                                        trap-focus
                                        required
                                        editable
                                    ></b-datepicker>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 col-lg-4 col-md-4 col-sm-2">
                            <div class="card cadastro">
                                <div class="card-body">
                                    <div class="card-title-cadastro">Telefone</div>
                                    <input
                                        class="input-cadastro"
                                        v-model="patient.phone"
                                        type="text"
                                        name="phone"
                                        v-mask="[
                                            '(',
                                            /\d/,
                                            /\d/,
                                            ') ',
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                            '-',
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                        ]"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="title_cadastro">ENDEREÇO</div>
                    <div class="row col-12">
                        <div class="col-4 col-lg-4 col-md-4 col-sm-2">
                            <div class="card cadastro">
                                <div class="card-body">
                                    <div class="card-title-cadastro">CEP</div>
                                    <input
                                        class="input-cadastro"
                                        @blur="getCepInfo"
                                        v-model="patient.cep"
                                        type="text"
                                        :mask="MASKS.cep"
                                        v-mask="[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-8 col-md-8 col-sm-4">
                            <div class="card cadastro">
                                <div class="card-body">
                                    <div class="card-title-cadastro">Endereço</div>
                                    <input
                                        class="input-cadastro"
                                        v-model="patient.street"
                                        type="text"
                                        name="street"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row col-12">
                        <div class="col-4 col-lg-4 col-md-4 col-sm-2">
                            <div class="card cadastro">
                                <div class="card-body">
                                    <div class="card-title-cadastro">Número</div>
                                    <input
                                        class="input-cadastro"
                                        v-model="patient.number"
                                        type="text"
                                        name="number"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-4 col-lg-4 col-md-4 col-sm-2">
                            <div class="card cadastro">
                                <div class="card-body">
                                    <div class="card-title-cadastro">Complemento</div>
                                    <input
                                        class="input-cadastro"
                                        v-model="patient.complement"
                                        type="text"
                                        name="complement"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-4 col-lg-4 col-md-4 col-sm-2" style="padding-right: 15px">
                            <div class="card cadastro">
                                <div class="card-body">
                                    <div class="card-title-cadastro">Bairro</div>
                                    <input
                                        class="input-cadastro"
                                        v-model="patient.neighborhood"
                                        type="text"
                                        name="neighborhood"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row col-12">
                        <div class="col-3 col-lg-3 col-md-3 col-sm-1">
                            <div class="card cadastro">
                                <div class="card-body">
                                    <div class="card-title-cadastro">Estado</div>
                                    <b-select
                                        v-if="patient.city"
                                        v-model="patient.city.state_id"
                                        placeholder="Selecionar"
                                        @input="loadCities(patient.city.state_id)"
                                    >
                                        <b-select-option
                                            v-for="state in states"
                                            :key="state.id"
                                            :value="state.id"
                                        >
                                            {{ state.name }}
                                        </b-select-option>
                                    </b-select>
                                    <b-select
                                        v-else
                                        v-model="state_id"
                                        placeholder="Selecionar"
                                        @input="loadCities(state_id)"
                                    >
                                        <b-select-option
                                            v-for="state in states"
                                            :key="state.id"
                                            :value="state.id"
                                        >
                                            {{ state.name }}
                                        </b-select-option>
                                    </b-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 col-lg-4 col-md-4 col-sm-2">
                            <div class="card cadastro">
                                <div class="card-body">
                                    <div class="card-title-cadastro">Cidade</div>
                                    <b-select
                                        v-model="patient.city_id"
                                        placeholder="Selecionar"
                                        :errors="errors.city_id"
                                    >
                                        <b-select-option v-for="city in cities" :key="city.id" :value="city.id">
                                            {{ city.name }}
                                        </b-select-option>
                                    </b-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row col-12 mt-4">
                        <div
                            class="col-12 col-lg-12 col-md-12 col-sm-6 button-container d-flex justify-content-end"
                            style="padding-right: 15px"
                        >
                            <b-button variant="light" @click="onSubmit()" type="is-primary">Salvar</b-button>
                        </div>
                    </div>
                </div>
            </form>
        </section>
    </div>
</template>

<script
    type="text/javascript"
    src="//gaintime.github.io/br-validator/cdn/$VERSION/dist/gaintime.br-validator.min.js"
></script>

<script>
import PatientsService from "@/services/patients.service.js";
import StatesService from "@/services/states.service";
import CitiesService from "@/services/cities.service";
import CepService from "@/services/cep.service";
import MASKS from "@/constants/masks.constant";
import * as moment from "moment";
import { required, email } from "vuelidate/lib/validators";
import FileMixin from "@/mixins/FileMixin";

export default {
    mixins: [FileMixin],
    bimage: "",
    data: () => ({
        old_photo: null,
        element: null,
        rev: null,
        add: null,
        cpf: null,
        cnpj: null,
        fileName: "",
        fileLink: "",
        errors: {},
        MASKS: MASKS,
        isLoading: true,
        isLoadingCity: false,
        title: "",
        patient: {
            city: {
                id: null,
            },
            user: {
                email: null,
            },
            photo: null,
            complement: null,
        },
        patientCity: null,
        //patient.city.id
        patients: [],
        states: [],
        cities: [],
        state_id: null,
        city_id: null,
        photoSelected: false,
    }),
    validations: {
        patient: {
            name: {
                required,
            },
            user: {
                email: {
                    required,
                    email,
                },
            },
            photo: {
                required,
            },
            cpf: {
                required,
            },
            cep: {
                required,
            },
            password: {
                required,
            },
            number: {
                required,
            },
            phone: {
                required,
            },
            birthday: {
                required,
            },
        },
    },
    methods: {
        testaCPF() {
            this.element = this.$refs.cpf;
            this.cpf = this.patient.cpf.replace(/[^\d]+/g, "");
            if (this.cpf == "") {
                this.patient.cpf = "";
                this.$buefy.snackbar.open({
                    message: "Cpf inválido",
                    type: "is-danger",
                    duration: 5000,
                    pauseOnHover: true,
                });
                this.element.focus();
                return false;
            }
            // Elimina CPFs invalidos conhecidos
            if (
                this.cpf.length != 11 ||
                this.cpf == "00000000000" ||
                this.cpf == "11111111111" ||
                this.cpf == "22222222222" ||
                this.cpf == "33333333333" ||
                this.cpf == "44444444444" ||
                this.cpf == "55555555555" ||
                this.cpf == "66666666666" ||
                this.cpf == "77777777777" ||
                this.cpf == "88888888888" ||
                this.cpf == "99999999999"
            ) {
                this.$buefy.snackbar.open({
                    message: "Cpf inválido",
                    type: "is-danger",
                    duration: 5000,
                    pauseOnHover: true,
                });
                this.patient.cpf = "";
                this.element.focus();
                return false;
            }
            // Valida 1o digito
            this.add = 0;
            for (var i = 0; i < 9; i++) this.add += parseInt(this.cpf.charAt(i)) * (10 - i);
            this.rev = 11 - (this.add % 11);
            if (this.rev == 10 || this.rev == 11) this.rev = 0;
            if (this.rev != parseInt(this.cpf.charAt(9))) {
                this.$buefy.snackbar.open({
                    message: "Cpf inválido",
                    type: "is-danger",
                    duration: 5000,
                    pauseOnHover: true,
                });
                this.patient.cpf = "";
                this.element.focus();
                return false;
            }
            // Valida 2o digito
            this.add = 0;
            for (var i = 0; i < 10; i++) this.add += parseInt(this.cpf.charAt(i)) * (11 - i);
            this.rev = 11 - (this.add % 11);
            if (this.rev == 10 || this.rev == 11) this.rev = 0;
            if (this.rev != parseInt(this.cpf.charAt(10))) {
                this.$buefy.snackbar.open({
                    message: "Cpf inválido",
                });
                this.$buefy.snackbar.open({
                    message: "Cpf inválido",
                });
                this.patient.cpf = "";
                this.element.focus();
                return false;
            } else {
                return true;
            }
        },

        async deleteFile() {
            this.fileName = this.fileName.substr(60);
            PatientsService.delfile(this.fileName);
            this.$emit("fileDeleted");
            this.$emit("onSubmit");
        },

        v_password_confirmation() {
            if ((this.patient.password_confirmation === "") | (this.patient.password_confirmation === null)) {
                this.$buefy.snackbar.open({
                    message: "A confirmação da senha é obrigatória",
                    type: "is-danger",
                    duration: 5000,
                    pauseOnHover: true,
                });
            }
        },

        v_password() {
            if ((this.patient.password === "") | (this.patient.password === null)) {
                this.$buefy.snackbar.open({
                    message: "A senha é obrigatória",
                    type: "is-danger",
                    duration: 5000,
                    pauseOnHover: true,
                });
            }
        },
        
        goBack() {
            this.$router.go(-1);
        },

        statesGetter: StatesService.search,
        citiesGetter: CitiesService.search,

        async getCepInfo(cep) {
            if (typeof cep == "undefined" || cep == null) {
                return;
            }
            if (!cep || cep.length < 8) return;
            cep = this.patient.cep?.replace(/\D/g, "");
            await CepService.getCepInfo(cep).then(({ data }) => {
                CitiesService.getId(data.city_id)
                    .then(({ data }) => {
                        let city = {
                            id: "",
                            state_id: "",
                        };
                        if (typeof data.id != "undefined" && typeof data.state_id != "undefined") {
                            city = data;
                            this.loadCities(city.state_id);
                            this.patient = {
                                ...this.patient,
                                city_id: city.id,
                                city: {
                                    id: city.id,
                                    state_id: city.state_id,
                                },
                            };
                        }
                    })
                    .finally(() => (this.isLoading = false));

                this.patient = {
                    ...this.patient,
                    street: data.logradouro,
                    neighborhood: data.bairro,
                };
            });
        },

        loadCities(state_id) {
            this.isLoadingCity = true;
            CitiesService.searchByStateId(state_id)
                .then(({ data }) => (this.cities = data))
                .finally(() => (this.isLoadingCity = false));
        },

        loadStates() {
            this.isLoading = true;
            StatesService.get()
                .then(({ data }) => (this.states = data))
                .finally(() => (this.isLoading = false));
        },

        loadPatient(id) {
            this.isLoading = true;

            if (typeof id != "undefined") {
                PatientsService.getId(id)
                    .then(({ data: data }) => {
                        this.patient = data;
                        if (this.patient.birthday) {
                            this.patient.birthday = new Date(this.patient.birthday);
                        }
                        typeof this.patient.city != "undefined" && this.patient.city != null
                            ? this.loadCities(this.patient.city.state_id)
                            : this.loadStates();
                    })
                    .finally(() => (this.isLoading = false));
                this.old_photo = this.patient.photo;
            }

            this.isLoading = false;
        },

        onImageClick(url) {
            if (!url) return;
            window.open(url, "_blank");
        },

        onSubmit() {
            if (
                this.patient.name &&
                this.patient.birthday &&
                this.patient.cpf &&
                this.patient.user.email &&
                this.patient.password &&
                this.patient.cep &&
                this.patient.photo &&
                this.patient.phone
            ) {
                this.errors = {};
                this.errors = {};
                !this.id && this.save();
                this.id && this.update(this.id);
            } else {
                if (!this.patient.photo) this.$buefy.snackbar.open("A foto do cliente é obrigatória");
                if (!this.patient.phone) this.$buefy.snackbar.open("O telefone do cliente é obrigatório");
                if (!this.patient.name) this.$buefy.snackbar.open("O nome do cliente é obrigatório");
                if (!this.patient.birthday) this.$buefy.snackbar.open("A data de nascimento é obrigatória");
                if (!this.patient.cpf) this.$buefy.snackbar.open("O cpf é obrigatório");
                if (!this.patient.user.email) this.$buefy.snackbar.open("O email é obrigatório");
                if (!this.patient.password) this.$buefy.snackbar.open("A senha é obrigatória");
                if (!this.patient.cep) this.$buefy.snackbar.open("O cep é obrigatório");
                if (!this.patient.number)
                    this.$buefy.snackbar.open("O número do endereço do cliente é obrigatório");
            }
        },
        save() {
            let data = {
                approved: 1,
                cpf: this.patient.cpf?.replace(/\D/g, ""),
                name: this.patient.name,
                email: this.patient.user.email,
                password: this.patient.password,
                birthday: moment(this.patient.birthday).format("YYYY-MM-DD"),
                phone: this.patient.phone?.replace(/\D/g, ""),
                cep: this.patient.cep?.replace(/\D/g, ""),
                street: this.patient.street,
                number: this.patient.number,
                complement: this.patient.complement,
                neighborhood: this.patient.neighborhood,
                city_id: this.patient.city_id,
            };

            if (this.patient.photo) {
                data["photo"] = this.patient.photo;
            }

            this.isSaving = true;
            PatientsService.save(data)
                .then(() => {
                    this.toast("Cliente atualizado com sucesso!");
                    this.$router.push({ name: "patients.index" });
                })
                .catch(({ response }) => {
                    const message = response.data.message;
                    this.toast(
                        typeof message === "string" ? message : "Erro ao tentar atualizar o Cliente.",
                        "is-error"
                    );
                    this.errors = response.data.message;
                })
                .finally(() => (this.isSaving = false));
        },
        update(id) {
            let data = {
                cpf: this.patient.cpf?.replace(/\D/g, ""),
                name: this.patient.name,
                email: this.patient.user.email,
                password: this.patient.password,
                birthday: moment(this.patient.birthday).format("YYYY-MM-DD"),
                phone: this.patient.phone?.replace(/\D/g, ""),
                cep: this.patient.cep?.replace(/\D/g, ""),
                street: this.patient.street,
                number: this.patient.number,
                complement: this.patient.complement,
                neighborhood: this.patient.neighborhood,
                city_id: this.patient.city_id,
            };

            if (this.patient.photo && this.photoSelected) {
                data["photo"] = this.patient.photo;
            }

            this.isSaving = true;
            PatientsService.update(id, data)
                .then(() => {
                    this.$buefy.snackbar.open("Cliente atualizado com sucesso!");
                    this.$router.push({ name: "patients.index" });
                })
                .catch(({ response }) => {
                    const message = response.data.message;
                    this.$buefy.snackbar.open(
                        typeof message === "string" ? message : "Erro ao tentar atualizar o Advogado.",
                        "error"
                    );
                    this.errors = response.data.message;
                })
                .finally(() => (this.isSaving = false));
        },
        onPhotoSelected(value) {
            this.fileToBase64(value).then((base64) => (this.patient.photo = base64));
            this.photoSelected = true;
        },
    },
    mounted() {
        const id = this.$route.params.id;
        this.id = this.$route.params.id;
        this.loadPatient(id);
        this.loadStates();
    },
};
</script>

<style lang="scss" scoped>
.patient {
    display: flex;

    &__image {
        margin-right: 24px;
        border: 1px solid #f5f5f5;
        overflow: hidden;
        height: 120px;
        width: 100%;
        height: 100%;
        text-align: center;

        img {
            object-fit: cover;
        }
    }

    &__info {
        h2 {
            font-size: 1.4rem;
        }

        .links {
            margin-top: 8px;

            .button:not(:first-child) {
                margin-left: 8px;
            }
        }
    }

    img,
    video {
        height: 0;
        max-width: 0;
    }

    .remove-btn {
        margin-top: 5px;
        color: var(--color-danger);
        border: none;
        background: transparent;
        outline: 0;
        font-size: 1.1em;
        cursor: pointer;
        transition: background 0.2s ease-in-out;
        border: 1px solid;
    }
}
</style>
