export default [
  {
    key: 'active',
    text: 'Ativa',
  },
  {
    key: 'registration',
    text: 'Cadastro',
  },
  {
    key: 'affiliation',
    text: 'Afiliação',
  },
  {
    key: 'refused',
    text: 'Recusada',
  },
  {
    key: 'suspended',
    text: 'Suspensa',
  },
  {
    key: 'blocked',
    text: 'Bloqueada',
  },
  {
    key: 'inactive',
    text: 'Inativa',
  },
];
