import * as AppActions from "@/store/actions/app";

const state = {
    showSidebar: false,
};

const getters = {
    showSidebar: (state) => state.showSidebar,
};

const actions = {};

const mutations = {
    [AppActions.CHANGE_SIDEBAR]: (state) => {
        state.showSidebar = !state.showSidebar;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
