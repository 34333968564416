import Api from "./api.service";

const ENDPOINT = "discounts";

export default {
    get: (params) => Api.get(ENDPOINT, { params }),
    getLabels: () => Api.get("get-discount-type"),
    getId: (id) => Api.get(`${ENDPOINT}/${id}`),
    post: (data) => Api.post(ENDPOINT, data),
    update: (id, data) => Api.put(`${ENDPOINT}/${id}`, data),
    delete: (id) => Api.delete(`${ENDPOINT}/${id}`),
};
