import Clear from "@/layouts/Clear.vue";

export default {
    path: "/discount",
    component: Clear,
    children: [
        {
            path: "",
            name: "discount.index",
            component: () => import("@/views/Discount/DiscountList.vue"),
        },
        {
            path: ":id?",
            name: "discount.save",
            component: () => import("@/views/Discount/Discount.vue"),
        },
    ],
};
