<template>
  <section class="subscription-details-modal">
    <div class="modal-card">
      <div class="modal-card-head"></div>
      <div class="modal-card-body">
        <div class="card mt-4">
          <div class="card-header">
            <div class="card-header-title">Créditos liberados</div>
          </div>
          <div class="card-content">
            <div class="row">
              <div class="col-12">
                <b-table
                  :paginated="false"
                  id="table-extra-credits"
                  :current-page.sync="page"
                  :data="subscription.extra_credits"
                  :total="subscription.extra_credits.length"
                  :per-page="subscription.extra_credits.length"
                >
                  <b-table-column
                    v-slot="props"
                    field="original_credits"
                    label="Créditos liberados"
                  >
                    <p>
                      {{ props.row.original_credits }}
                    </p>
                  </b-table-column>

                  <b-table-column
                    v-slot="props"
                    field="available_credits"
                    label="Créditos disponíveis"
                  >
                    <p>
                      {{ props.row.available_credits }}
                    </p>
                  </b-table-column>

                  <b-table-column
                    v-slot="props"
                    field="code"
                    label="Válido até"
                  >
                    <p>
                      {{ props.row.valid_until | date }}
                    </p>
                  </b-table-column>

                  <b-table-column
                    v-slot="props"
                    field="code"
                    label="Liberado em"
                  >
                    <p>
                      {{ props.row.created_at | date("DD/MM/YYYY [às] HH:mm") }}
                    </p>
                  </b-table-column>
                </b-table>
              </div>
            </div>
          </div>
        </div>

        <div class="card mt-4">
          <div class="card-header">
            <div class="card-header-title">Utilização dos créditos</div>
          </div>
          <div class="card-content">
            <div class="row">
              <div class="col-12">
                <b-table
                  id="table-subscriptions"
                  :paginated="false"
                  :data="subscriptionPlan"
                >
                  <b-table-column v-slot="props" field="code" label="Período">
                    <p>
                      {{ props.row.title }}
                    </p>
                  </b-table-column>

                  <b-table-column
                    v-slot="props"
                    field="code"
                    label="Créditos disponíveis"
                  >
                    <p>
                      {{
                        props.row.available_credits
                          ? props.row.available_credits
                          : "-"
                      }}
                    </p>
                  </b-table-column>

                  <b-table-column
                    v-slot="props"
                    field="code"
                    label="Iniciou em"
                  >
                    <p>
                      {{ props.row.start | date }}
                    </p>
                  </b-table-column>

                  <b-table-column
                    v-slot="props"
                    field="code"
                    label="Válido até"
                  >
                    <p>
                      {{ props.row.end | date }}
                    </p>
                  </b-table-column>
                </b-table>
              </div>
            </div>
          </div>
        </div>

        <div class="card mt-4">
          <div class="card-header">
            <div class="card-header-title">Histórico de pagamento</div>
          </div>
          <div class="card-content">
            <div class="row">
              <div class="col-12">
                <b-table
                  id="table-subscriptions"
                  :total="subscription.charges.length"
                  :paginated="false"
                  :data="subscription.charges"
                  :per-page="subscription.charges.length"
                  :current-page.sync="page"
                >
                  <b-table-column v-slot="props" field="code" label="COD.">
                    <p>
                      {{ props.row.code }}
                    </p>
                  </b-table-column>

                  <b-table-column
                    v-slot="props"
                    field="amount"
                    label="Valor pago"
                  >
                    <p>
                      {{ props.row.amount | currency }}
                    </p>
                  </b-table-column>

                  <b-table-column
                    v-slot="props"
                    field="payment_method"
                    label="Método de pagamento"
                  >
                    <p>
                      {{
                        props.row.payment_method === "pix"
                          ? "PIX"
                          : "Cartão de Crédito"
                      }}
                    </p>
                  </b-table-column>

                  <b-table-column
                    v-slot="props"
                    field="charge_status"
                    label="Status do pagamento"
                  >
                    <p>
                      {{ props.row.charge_status }}
                    </p>
                  </b-table-column>

                  <b-table-column
                    v-slot="props"
                    field="paid_at"
                    label="Pago em"
                  >
                    <p v-if="props.row.status === 'paid'">
                      {{ props.row.paid_at | date }}
                    </p>
                    <p v-else>-</p>
                  </b-table-column>
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-card-foot">
        <b-button expanded @click="$emit('close')">Fechar</b-button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    subscription: Object,
  },
  computed: {
    subscriptionPlan() {
      const currentPeriod = {
        title: "Atual",
        available_credits: this.subscription
          .current_subscription_available_limit,
        ...this.subscription.current_subscription_period,
      };

      const nextPeriod = {
        title: "Próximo",
        ...this.subscription.next_subscription_period,
      };

      const allPeriod = {
        title: "Total",
        ...this.subscription.all_subscription_period,
      };

      const details = [currentPeriod, nextPeriod, allPeriod];

      return details;
    },
  },
  data: () => ({
    page: 1,
    perPage: 10,
  }),
};
</script>

<style lang="scss">
.subscription-details-modal {
  .modal-card {
    width: 800px !important;
  }
}
</style>
