import Api from "./api.service";

export default {
    get: (params) => Api.get("specialties", { params }),
    getAll: () => Api.get("specialties"),
    getId: (id) => Api.get(`specialties/${id}`),
    save: (data) => Api.post(`specialties/`, data),
    update: (id, data) => Api.put(`specialties/${id}`, data),
    delete: (id) => Api.delete(`specialties/${id}`),
    export: (filters) => Api.post("specialties/export", { filters }),
};
