<template>
  <div class="app-header">
    <div class="app-header__content">
      <b-button
        class="btn-toggle-menu"
        icon-right="bars"
        size="small"
        @click="toggleSidebar"
      ></b-button>

      <font-awesome-icon
        class="icon"
        v-if="icon"
        :icon="icon"
      ></font-awesome-icon>

      <h2>{{ title }}</h2>
    </div>

    <div class="app-header__buttons">
      <router-link v-if="backRoute" :to="backRoute">
        <b-button
          native-type="button"
          type="is-primary"
          icon-left="chevron-left"
        >
          Voltar
        </b-button>
      </router-link>

      <b-button
        v-if="!backRoute && goesBack"
        @click="goBack()"
        native-type="button"
        type="is-primary"
        icon-left="chevron-left"
      >
        Voltar
      </b-button>
    </div>
  </div>
</template>

<script>
import { CHANGE_SIDEBAR } from "@/store/actions/app";

export default {
  props: {
    icon: String,
    title: String,
    backRoute: Object,
    goesBack: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },

    toggleSidebar() {
      this.$store.commit(CHANGE_SIDEBAR);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-toggle-menu {
  margin-right: 0.7rem;

  &:focus,
  &:focus-visible {
    box-shadow: none !important;
    border: 1px solid #adadad;
  }
}

.app-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  margin-bottom: 20px;
  //   border-bottom: 2px inset var(--color-secondary);

  &__content {
    display: flex;
    align-items: center;
    color: var(--text-color-medium);
    font-weight: bold;
    text-transform: capitalize;

    .icon {
      display: block;
      margin-right: 0.8rem;

      color: var(--color-secondary);
    }

    h2 {
      font-family: Poppins, family;
      font-size: 1.3rem;
      font-weight: 600;
      color: var(--color-secondary);

      margin-top: 0;
    }
  }
}
</style>
