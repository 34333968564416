<template>
  <div id="app">
    <loading-page v-if="isLoading || isAuthLoading"></loading-page>
    <router-view v-if="!isLoading" />
  </div>
</template>

<script>
import LoadingPage from "@/views/Loading.vue";
import { AUTH_TOKEN } from "@/store/actions/auth";

export default {
  data: () => ({
    isAuthLoading: false,
  }),

  components: {
    "loading-page": LoadingPage,
  },

  computed: {
    isLoading() {
      return this.$store.state.auth.loading;
    },
  },

  mounted() {
    this.isAuthLoading = true;

    this.$store
      .dispatch(AUTH_TOKEN)
      .then(() => this.$router.push("/lawyers"))
      .catch(() => this.$route.name != "login" && this.$router.push("/login"))
      .finally(() => (this.isAuthLoading = false));
  },
};
</script>

<style lang="scss">
@import "styles/_global.scss";

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
}
</style>
