<template>
    <div class="values-view">
        <app-header icon="coins" title="Valores"></app-header>

        <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>

        <section>
            <form v-if="!isLoading" @submit.prevent="onSubmit()">
                <div class="container-appointments">
                    <div class="row d-flex flex-wrap">
                        <div class="col-11 col-lg-4 col-md-10 mx-lg-0 mx-auto mb-4">
                            <div class="card cadastro">
                                <div class="card-body">
                                    <b-field for="crm_value" class="card-title-cadastro">
                                        Valor padrão da OAB por hora
                                    </b-field>

                                    <input
                                        class="input-cadastro"
                                        v-model="crm_value"
                                        type="text"
                                        name="crm_value"
                                        @keydown="onInputNumber"
                                    />

                                    <div class="mt-4">
                                        <b-form-text class="text-error" v-if="errors.crm_value">
                                            {{ errors.crm_value }}
                                        </b-form-text>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-11 col-lg-4 col-md-10 mx-lg-0 mx-auto mb-4">
                            <div class="card cadastro">
                                <div class="card-body">
                                    <b-field for="discount_expire_at" class="card-title-cadastro">
                                        Data de expiração do desconto
                                    </b-field>

                                    <input
                                        class="input-cadastro"
                                        v-model="discount_expire_at"
                                        type="text"
                                        name="discount_expire_at"
                                        v-mask="'##/##/#### ##:##'"
                                    />

                                    <div class="mt-4">
                                        <b-form-text class="text-error" v-if="errors.discount_expire_at">
                                            {{ errors.discount_expire_at }}
                                        </b-form-text>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row d-flex flex-wrap">
                        <div class="col-11 col-lg-4 col-md-10 mx-lg-0 mx-auto mb-4">
                            <div class="card cadastro">
                                <div class="card-body">
                                    <b-field for="time" class="card-title-cadastro">
                                        Tempo mínimo de atendimento (em minutos)

                                        <b-tooltip
                                            label="Com base no tempo mínimo de atendimento, o sistema irá calcular o valor da consulta."
                                            placement="top"
                                            class="tooltip-info"
                                        >
                                            <span class="info-appointment" id="infoTime">
                                                <b-icon icon="question-circle-fill"></b-icon>
                                            </span>
                                        </b-tooltip>
                                    </b-field>

                                    <input
                                        class="input-cadastro"
                                        v-model="time"
                                        type="text"
                                        name="time"
                                        @keydown="onInputNumber"
                                        @blur="onValidateTime"
                                    />

                                    <div class="mt-4">
                                        <b-form-text class="text-error" v-if="errors.time">
                                            {{ errors.time }}
                                        </b-form-text>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-11 col-lg-4 col-md-10 mx-lg-0 mx-auto mb-4">
                            <div class="card cadastro">
                                <div class="card-body">
                                    <b-field for="percent_discount" class="card-title-cadastro">
                                        Porcentagem a ser aplicada em um desconto
                                    </b-field>

                                    <input
                                        class="input-cadastro"
                                        v-model="percent_discount"
                                        type="text"
                                        name="percent_discount"
                                        @keydown="onInputNumber"
                                    />

                                    <div class="mt-4">
                                        <b-form-text class="text-error" v-if="errors.percent_discount">
                                            {{ errors.percent_discount }}
                                        </b-form-text>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row d-flex flex-wrap">
                        <div class="col-11 col-lg-4 col-md-10 mx-lg-0 mx-auto mb-4">
                            <div class="card cadastro disabled">
                                <div class="card-body">
                                    <b-field for="value" class="card-title-cadastro">
                                        Valor do atendimento

                                        <b-tooltip
                                            label="Valor padrão de acordo OAB"
                                            placement="top"
                                            class="tooltip-info"
                                        >
                                            <span class="info-appointment" id="infoTime">
                                                <b-icon icon="question-circle-fill"></b-icon>
                                            </span>
                                        </b-tooltip>
                                    </b-field>

                                    <input
                                        class="input-cadastro"
                                        v-model="value"
                                        type="text"
                                        name="value"
                                        :disabled="true"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="col-11 col-lg-4 col-md-10 mx-lg-0 mx-auto mb-4">
                            <div class="card cadastro disabled">
                                <div class="card-body">
                                    <b-field for="value_discount" class="card-title-cadastro">
                                        Valor do atendimento com desconto

                                        <b-tooltip
                                            label="Valor de desconto de acordo a porcentagem"
                                            placement="top"
                                            class="tooltip-info"
                                        >
                                            <span class="info-appointment" id="infoTime">
                                                <b-icon icon="question-circle-fill"></b-icon>
                                            </span>
                                        </b-tooltip>
                                    </b-field>

                                    <input
                                        class="input-cadastro"
                                        v-model="value_discount"
                                        type="text"
                                        name="value_discount"
                                        :disabled="true"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-8">
                            <b-button
                                native-type="submit"
                                type="is-primary"
                                :disabled="isSubmit"
                                :loading="isSubmit"
                            >
                                Salvar
                            </b-button>
                        </div>
                    </div>
                </div>
            </form>
        </section>
    </div>
</template>

<script>
import AppointmentTypeService from "@/services/appointment_types.service";
import moment from "moment";

export default {
    data: () => ({
        isLoading: false,
        isSubmit: false,
        appointment_types: [],
        crm_value: "",
        time: 0,
        value: 0,
        value_discount: 0,
        percent_discount: 0,
        discount_expire_at: null,
        errors: {},
    }),

    mounted() {
        this.loadTiposAtendimento();
    },

    watch: {
        crm_value() {
            this.onCalcValueAppointment();
            this.onCalcValueDiscount();
        },

        time() {
            this.onCalcValueAppointment();
            this.onCalcValueDiscount();
        },

        percent_discount() {
            this.onCalcValueDiscount();
        },
    },

    methods: {
        loadTiposAtendimento() {
            this.isLoading = true;
            AppointmentTypeService.getAll()
                .then(({ data: appointment_types }) => {
                    this.appointment_types = appointment_types;

                    const appointment_type = appointment_types[0];

                    this.time = appointment_type?.time ?? 0;
                    this.percent_discount = parseInt(appointment_type?.percent_discount ?? 0);
                    this.discount_expire_at = moment(appointment_type.discount_expire_at).format(
                        "DD/MM/YYYY HH:mm"
                    );

                    this.value = this.toReal(appointment_type?.value ?? 0);
                    this.crm_value = appointment_type?.crm_value ;
                    this.value_discount = this.toReal(appointment_types?.value_discount);
                })
                .catch((error) => {
                    if (error.status === 500) {
                        this.$buefy.snackbar.open("Não foi possível carregar os valores do atendimentos!");
                    }
                })
                .finally(() => (this.isLoading = false));
        },

        onSubmit() {
            this.errors = {};

            if (this.validate()) return;

            this.isSubmit = true;

            AppointmentTypeService.updateMany({
                value: parseFloat(this.unmask(this.value)),
                crm_value: parseFloat(this.unmask(this.crm_value)),
                time: parseInt(this.time),
                percent_discount: parseInt(this.percent_discount),
                discount_expire_at: this.parseDatetime(this.discount_expire_at ?? ""),
            })
                .then(() =>
                    this.$buefy.snackbar.open({
                        message: "Valor e desconto aplicado com sucesso",
                        type: "is-success",
                        duration: 5000,
                        pauseOnHover: true,
                    })
                )
                .catch((error) => {
                    if (error.status == 400) {
                        Object.entries(error.data?.errors ?? {}).map(
                            ([key, value]) => (error.data.errors[key] = value[0])
                        );

                        this.errors = error.data.errors;
                    }

                    this.$buefy.snackbar.open({
                        message: "Verifique os campos!",
                        type: "is-danger",
                        duration: 5000,
                        pauseOnHover: true,
                    });
                })
                .finally(() => (this.isSubmit = false));
        },

        onInputNumber(event) {
            console.log(event.keyCode)
            if (!event.target?.value?.trim()) return;

            const keyCodeValidWithCtrl = [37, 39, 65];
            const othersCodeValid = [8, 9, 37, 38, 39, 40, 46];

            if (
                (event.ctrlKey && !keyCodeValidWithCtrl.includes(event.keyCode)) ||
                (!event.ctrlKey && !othersCodeValid.includes(event.keyCode) && isNaN(parseInt(event.key)))
            ) {
                event.preventDefault();
                return;
            }
        },

        onValidateTime(event) {
            if (!event.target?.value?.trim()) return;

            const number = parseInt(event.target.value);

            if (number <= 0) {
                this.$buefy.snackbar.open({
                    message: "Defina um tempo válido acima de 0",
                    type: "is-warning",
                    duration: 5000,
                    pauseOnHover: true,
                });
            }
        },

        onCalcValueAppointment() {
            if (!this.time || !this.crm_value) return;

            this.value = this.toReal((parseInt(this.time) / 60) * parseFloat(this.crm_value));
        },

        onCalcValueDiscount() {
            if (!this.percent_discount) return;

            let value = parseFloat(this.unmask(this.value));

            const value_discount = (value * this.percent_discount) / 100;

            this.value_discount = this.toReal(value - value_discount);
        },

        validate() {
            let isInvalid = false;
            const fields = ["crm_value", "time", "percent_discount", "discount_expire_at"];

            this.errors = {};

            const fields_name = {
                crm_value: "valor padrão da OAB",
                time: "tempo de atendimento",
                percent_discount: "porcentagem",
                discount_expire_at: "data de expiração",
            };

            fields.forEach((f) => {
                const field = this[f];

                if (
                    !["percent_discount", "discount_expire_at"].includes(f) &&
                    (!field || (typeof field == "number" && field <= 0))
                ) {
                    isInvalid = true;
                    this.errors[f] = `O campo ${fields_name[f]} é obrigatório`;
                }

                if (field && f == "discount_expire_at" && !moment(this.parseDatetime(field)).isValid()) {
                    isInvalid = true;
                    this.errors.discount_expire_at = "Data inválida";
                }
            });

            return isInvalid;
        },

        unmask(value) {
            if (!value) return value;

            return parseFloat(value.trim().replace("R$", "").replaceAll(",", "."));
        },

        toReal(value) {
            value = typeof value == "string" ? parseFloat(value) : value;

            if ((!value && value != 0) || isNaN(value)) return 0;

            return value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
            });
        },

        parseDatetime(value) {
            if (!value) return value;

            return moment(value, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD HH:mm:ss");
        },
    },
};
</script>

<style lang="scss">
.card.disabled,
.card.disabled input {
    background: #e7e7e7;
    box-shadow: none;
}

.card-title-cadastro {
    font-size: 1rem;
}

.info-appointment {
    display: inline-block;
    width: 15px;
    height: auto;
    margin-left: 0.3rem;
    color: var(--color-secondary);

    svg {
        width: 100%;
        height: 100%;
        object-fit: cover;
        vertical-align: text-bottom;
    }

    cursor: help;
}

.tooltip-info {
    & .tooltip-content {
        font-size: 1rem !important;
        filter: opacity(90%);
        z-index: 2000 !important;
    }
}
</style>
