<template>
    <div class="discount-view">
        <app-header title="Descontos" icon="percent" goes-back></app-header>

        <section>
            <form v-if="!isLoading" @submit.prevent="onSubmit()">
                <div class="container-discount">
                    <div class="row col-12">
                        <div class="col-11 col-lg-6 col-md-10 mx-sm-auto mb-sm-4">
                            <div class="card cadastro">
                                <div class="card-body">
                                    <div class="card-title-cadastro mb-2">Tipo de desconto</div>

                                    <b-select
                                        v-model="discount.discount_type"
                                        :options="discount_types"
                                        text-field="label"
                                        :errors="errors.discount_type"
                                    ></b-select>
                                </div>
                            </div>
                        </div>

                        <div class="col-11 col-lg-6 col-md-10 mx-sm-auto mb-sm-4">
                            <div class="card cadastro">
                                <div class="card-body">
                                    <div class="card-title-cadastro mb-2">Valor</div>

                                    <input
                                        class="input-cadastro"
                                        v-model="discount.value"
                                        name="value"
                                        :errors="errors.value"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row col-12">
                        <div class="col-11 col-lg-6 col-md-10 mx-sm-auto mb-sm-4">
                            <div class="card cadastro">
                                <div class="card-body">
                                    <div class="card-title-cadastro mb-2">Data de expiraçãos</div>

                                    <input
                                        class="input-cadastro"
                                        v-model="discount.due_date"
                                        name="value"
                                        v-mask="'##/##/#### ##:##'"
                                        :errors="errors.due_date"
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="col-11 col-lg-6 col-md-10 mx-sm-auto mb-sm-4">
                            <div class="card cadastro">
                                <div class="card-body">
                                    <div class="card-title-cadastro mb-2">Quantidade de usos</div>

                                    <input
                                        class="input-cadastro"
                                        v-model="discount.quantity_of_use"
                                        name="value"
                                        :errors="errors.quantity_of_use"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row col-12 mt-4">
                        <div
                            class="col-12 col-lg-12 col-md-12 col-sm-6 button-container d-flex justify-content-end"
                            style="padding-right: 15px"
                        >
                            <b-button
                                variant="light"
                                native-type="submit"
                                type="is-primary"
                                :loading="isSubmit"
                                :disabled="isSubmit"
                            >
                                Salvar
                            </b-button>
                        </div>
                    </div>
                </div>
            </form>
        </section>
    </div>
</template>

<script>
import DiscountService from "@/services/discount.service";
import moment from "moment";

export default {
    data: () => ({
        isLoading: false,
        isSubmit: false,
        id: null,
        discount_types: [],
        discount: {
            discount_type: null,
            value: "",
            due_date: null,
            quantity_of_use: 0,
            invalid: false,
        },
        errors: {},
    }),

    mounted() {
        this.loadDiscountsLabels();

        this.id = this.$route.params.id;

        this.id && this.loadDiscount();
    },

    methods: {
        loadDiscount() {
            this.isLoading = true;

            DiscountService.getId(this.id)
                .then(({ data }) => {
                    data.due_date = moment(data.due_date).format("DD/MM/YYYY HH:mm");
                    this.discount = data;
                })
                .catch(() => this.toast("Ocorreu um erro ao carregar o desconto"))
                .finally(() => (this.isLoading = false));
        },

        loadDiscountsLabels() {
            DiscountService.getLabels().then(({ data }) => (this.discount_types = data));
        },

        onSubmit() {
            const [date, time] = this.discount.due_date?.split(" ");

            const data = {
                ...this.discount,
                invalid: false,
                due_date: `${date?.split("/")?.reverse()?.join("-")} ${time}`,
            };

            this.id ? this.update(data) : this.save(data);
        },

        save(data) {
            this.isSubmit = true;

            DiscountService.post(data)
                .then(() => {
                    this.toast("Desconto criado com sucesso!");
                    this.$router.go(-1);
                })
                .catch(({ data }) => {
                    this.notifyError(data, "Erro ao criar desconto");
                })
                .finally(() => (this.isSubmit = false));
        },

        update(data) {
            this.isSubmit = true;

            DiscountService.update(this.id, data)
                .then(() => this.toast("Desconto atualizado!"))
                .catch(({ data }) => {
                    this.notifyError(data, "Erro ao atualizar desconto");
                })
                .finally(() => (this.isSubmit = false));
        },
    },
};
</script>

<style lang="scss" scoped>
.input-cadastro {
    padding-bottom: 0.5rem !important;
}
</style>
