import Api from './api.service';

export default {
  get: (params) => Api.get('schedules', { params }),
  getId: (id) => Api.get(`schedules/${id}`),
  save: (data) => Api.post(`schedules/`, data),
  update: (id, data) => Api.put(`schedules/${id}`, data),
  delete: (id) => Api.delete(`schedules/${id}`),
  export: (params) => Api.get('schedules/export', { params }),
  // getOnline: (params) => Api.get('schedules/online', { params }),
};
