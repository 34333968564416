<template>
    <div class="send-push-notifications-view">
        <app-header icon="paper-plane" title="Enviar Notificações"></app-header>

        <section>
            <div class="col-12">
                <form @submit.prevent="onSubmit()">
                    <div class="row d-flex flex-column justify-content-center align-items-center">
                        <div class="col-12 col-md-8 mb-5">
                            <div class="card cadastro">
                                <div class="col-12">
                                    <div class="card-body">
                                        <b-field for="title" class="card-title-cadastro text-center">
                                            Título
                                        </b-field>

                                        <div class="col-11 mx-auto rounded box-input box-input_1">
                                            <input
                                                class="input-cadastro"
                                                v-model="message.title"
                                                type="text"
                                                name="title"
                                                maxlength="90"
                                            />
                                        </div>

                                        <div class="mt-4">
                                            <b-form-text class="text-error" v-if="errors.title">
                                                {{ errors.title }}
                                            </b-form-text>
                                        </div>

                                        <div class="col-12 d-flex justify-content-end py-2 px-3 value-length">
                                            <span>{{ title_length }}/90</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="card-body">
                                        <b-field for="body" class="card-title-cadastro text-center">
                                            Descrição da mensagem
                                        </b-field>

                                        <div class="col-11 mx-auto rounded box-input box-input_2">
                                            <input
                                                class="input-cadastro h-auto"
                                                v-model="message.body"
                                                type="text"
                                                name="body"
                                                maxlength="200"
                                            />
                                        </div>

                                        <div class="mt-4">
                                            <b-form-text class="text-error" v-if="errors.body">
                                                {{ errors.body }}
                                            </b-form-text>
                                        </div>

                                        <div class="col-12 d-flex justify-content-end py-2 px-3 value-length">
                                            <span>{{ body_length }}/200</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="card-body">
                                        <b-field for="send_to" class="card-title-cadastro text-center">
                                            Enviar para
                                        </b-field>

                                        <div class="col-11 mx-auto rounded box-input box-input_3 p-0">
                                            <b-select
                                                class="col-12"
                                                v-model="message.send_to"
                                                name="send_to"
                                                text-field="label"
                                                :options="filters"
                                            >
                                            </b-select>
                                        </div>

                                        <div class="mt-4">
                                            <b-form-text class="text-error" v-if="errors.send_to">
                                                {{ errors.send_to }}
                                            </b-form-text>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4 col-12">
                            <div class="col-11 col-lg-3 col-md-8 mx-auto">
                                <b-button
                                    class="col-12"
                                    :disabled="disabled"
                                    :loading="isSubmit"
                                    native-type="submit"
                                    type="is-primary"
                                    block
                                >
                                    Enviar
                                </b-button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    </div>
</template>

<script>
import PushNotificationsService from "@/services/push_notifications.service";

export default {
    data: () => ({
        isSubmit: false,

        errors: {},
        filters: [],
        message: {
            title: "",
            body: "",
            send_to: null,
        },
    }),

    mounted() {
        this.loadFilters();
    },

    computed: {
        disabled() {
            return (
                this.isSubmit ||
                this.message.title.length == 0 ||
                this.message.body.length == 0 ||
                !this.message.send_to
            );
        },

        title_length() {
            return this.message.title?.length ?? 0;
        },

        body_length() {
            return this.message.body?.length ?? 0;
        },
    },

    methods: {
        loadFilters() {
            PushNotificationsService.getFilters()
                .then(({ data }) => (this.filters = data))
                .catch(console.warn);
        },

        onSubmit() {
            this.isSubmit = true;

            PushNotificationsService.sendNotifications(this.message)
                .then(({ data }) => this.toast(data.message))
                .catch(({ data, status }) => {
                    this.notifyError(
                        data,
                        status == 400 ? "Verifique os campos" : "Ocorreu um erro ao enviar notificação"
                    );
                })
                .finally(() => (this.isSubmit = false));
        },
    },
};
</script>

<style lang="scss" scoped>
.card-title-cadastro {
    font-size: 1.1rem;
}

.cadastro {
    border-radius: 10px;
    height: auto !important;

    .card-body {
        .box-input {
            border: 1px solid var(--color-primary);

            &.box-input_1 input {
                height: 50px !important;
                font-size: 1rem;
            }

            &.box-input_2 input {
                height: 100px !important;
                font-size: 1rem;
            }

            &.box-input_3 select {
                &:focus,
                &:focus-visible {
                    box-shadow: none !important;
                    border: 0 !important;
                }
            }
        }

        .value-length span {
            color: #333333 !important;
            font-weight: 600 !important;
            font-size: 1rem !important;
        }
    }
}
</style>
