<template>
  <div class="rank-rules-view">
    <b-loading :active.sync="isLoading" :is-full-page="true"></b-loading>

    <section>
      <div class="card" style="background: #fff">
        <div class="card-header">
          <div
            class="card-header-title"
            style="color: #1a1a1a; text-transform: uppercase"
          >
            Pontos de ranking de advogados
          </div>

          <div class="card-header-buttons">
            <b-button
              @click="onUpdateRankedRulesClick"
              class="ml-4"
              variant="light"
              type="is-primary"
              size="is-small"
              icon-left="save"
              :disabled="allRulesInactive || hasOnlySpecialRules"
              :loading="isLoading"
              >Salvar</b-button
            >
          </div>
        </div>
        <div class="card-content m-2">
          <div class="ranking-rules">
            <div v-for="(rule, idx) of rules" :key="idx" class="ranking-rule">
              <div class="header" style="display: flex; align-items: baseline">
                <div class="card-title-cadastro" style="font-size: 1.2rem">
                  <span
                    :style="!rule.active ? 'text-decoration: line-through' : ''"
                    >{{ rule.title }}</span
                  >
                  <span v-if="rule.active">
                    | {{ rule.weight }} ponto(s) ({{ rule.percentage }}%)</span
                  >
                </div>

                <!-- <b-field style="margin-left: 10px">
                  <b-switch size="is-small" v-model="rule.active" />
                </b-field> -->
              </div>

              <b-field>
                <b-slider
                  v-model="rule.weight"
                  size="is-large"
                  format="percent"
                  locale="pt-BR"
                  :max="10"
                  :min="0"
                  ticks
                  :tooltip="false"
                  rounded
                ></b-slider>
              </b-field>

              <rule-values
                v-if="rule.rule === 'BySpecialties' && rule.active"
                :getValues="getSpecialties"
                :ruleValues="rule.value"
                @onSelectValue="(value) => onValuesIsSelected(value, idx)"
              ></rule-values>

              <rule-values
                v-if="rule.rule === 'ByScheduleAverageByDay' && rule.active"
                :getValues="getAverageDaysOptions"
                :ruleValues="rule.value"
                :shouldBeUnique="true"
                @onSelectValue="(value) => onValuesIsSelected(value, idx)"
              >
              </rule-values>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import LawyersService from "@/services/lawyers.service";
import SpecialtiesService from "@/services/specialties.service";
import RuleValues from "./RuleValues.vue";

export default {
  components: {
    RuleValues,
  },
  data: () => ({
    isLoading: false,
    rules: [],
  }),
  watch: {
    rules: {
      deep: true,
      handler() {
        const allWeights = this.rules
          .filter((rule) => rule.active)
          .reduce((x, y) => {
            return x + y.weight;
          }, 0);

        this.rules.forEach((rule) => {
          if (!rule.weight) rule.active = false;
          else rule.active = true;

          if (rule.active)
            rule.percentage =
              rule.weight > 0
                ? ((rule.weight / allWeights) * 100).toFixed(2)
                : 0;
          else rule.percentage = 0;
        });
      },
    },
  },
  computed: {
    allRulesInactive() {
      return (
        this.rules.filter((rule) => !rule.active).length === this.rules.length
      );
    },
    hasOnlySpecialRules() {
      const specialRules = ["ByState", "BySpecialties"];

      const activeRules = this.rules
        .filter((rule) => rule.active && !specialRules.includes(rule.rule))
        .map((rule) => rule.rule);

      return activeRules.length === 0;
    },
  },
  methods: {
    getSpecialties: SpecialtiesService.get,
    getAverageDaysOptions: LawyersService.getAverageDaysOptions,
    getRankRules() {
      LawyersService.getRankRules().then(({ data }) => {
        this.rules = data.map((rule) => ({
          ...rule,
          value: rule.value ? JSON.parse(rule.value) : [],
        }));
      });
    },
    onUpdateRankedRulesClick() {
      this.isLoading = true;

      LawyersService.updateRankRules(this.rules)
        .then(() =>
          this.$buefy.snackbar.open("Regras atualizadas com sucesso!")
        )
        .catch(() =>
          this.$buefy.snackbar.open("Erro inesperadao ao atualizar regras!")
        )
        .finally(() => (this.isLoading = false));
    },

    onValuesIsSelected(values, idx) {
      this.rules[idx].value = values;
    },
  },
  mounted() {
    this.getRankRules();
  },
};
</script>
