import Api from './api.service';

export default {
  get: (params) => Api.get('clients', { params }),
  getId: (id) => Api.get(`clients/${id}`),
  save: (data) => Api.post(`clients/`, data),
  update: (id, data) => Api.put(`clients/${id}`, data),
  delete: (id) => Api.delete(`clients/${id}`),
  delfile: (fileName) => Api.post(`clients/${fileName}`),
  export: (term) => Api.post('clients/export', term),
  patch: (id, data) => Api.patch(`clients/${id}`, data),
};
