<template>
  <section class="rewards-list-view">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">Recompensas</div>
        <div class="card-header-buttons">
          <b-button
            class="ml-4"
            variant="light"
            @click="onOpenRewardClick()"
            type="is-primary"
            size="is-small"
            icon-left="plus"
            >Criar recompensa
          </b-button>
        </div>
      </div>
      <div class="card-content">
        <b-table :data="data" :loading.sync="isLoading" :paginated="false">
          <b-table-column v-slot="props" field="name" label="Nome">
            <p>{{ props.row.name }}</p>
          </b-table-column>

          <b-table-column v-slot="props" field="goal" label="Meta de conversão">
            <p>{{ props.row.goal }}</p>
          </b-table-column>

          <b-table-column
            v-slot="props"
            field="value"
            label="Valor da recompensa"
          >
            <p>{{ props.row.value | currency("BRL") }}</p>
          </b-table-column>

          <b-table-column v-slot="props" label="Opções" width="110">
            <b-tooltip label="Editar" class="m-1">
              <b-button
                @click="onOpenRewardClick(props.row)"
                type="is-dark"
                size="is-small"
                icon-right="edit"
              />
            </b-tooltip>

            <b-tooltip label="Excluir" class="m-1">
              <b-button
                @click="onDeleteRewardClick(props.row.id)"
                type="is-danger"
                size="is-small"
                icon-right="trash"
              />
            </b-tooltip>
          </b-table-column>

          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>Nenhum registro encontrado.</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </div>
  </section>
</template>

<script>
import ReferralProgramService from "../../../services/referral-program.service";
import RewardModal from "./RewardModal.vue";

export default {
  data: () => ({
    isLoading: false,
    data: [],
  }),
  methods: {
    loadRewards() {
      this.isLoading = true;

      ReferralProgramService.getRewards()
        .then(({ data }) => {
          this.data = data;
        })
        .catch(() => {
          this.$buefy.snackbar.open("Erro ao carregar recompensas.");
        })
        .finally(() => (this.isLoading = false));
    },
    onOpenRewardClick(reward) {
      this.$buefy.modal.open({
        parent: this,
        component: RewardModal,
        hasModalCard: true,
        trapFocus: true,
        canCancel: true,
        props: {
          rewardProp: reward,
        },
        events: {
          close: (reload) => {
            reload && this.$emit("reload");
          },
        },
      });
    },
    onDeleteRewardClick(rewardId) {
      this.$buefy.dialog.confirm({
        title: "Excluir recompensa?",
        message:
          "Tem certeza de que deseja <b>excluir</b> esta recompensa? Esta ação não poderá ser desfeita.",
        cancelText: "Cancelar",
        confirmText: "Excluir Recompensa",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteReward(rewardId),
      });
    },
    deleteReward(rewardId) {
      this.isLoading = true;

      ReferralProgramService.deleteReward(rewardId)
        .then(() => {
          this.$buefy.snackbar.open("Recompensa excluída com sucesso!");

          const idx = this.data.findIndex((reward) => reward.id === rewardId);
          this.data.splice(idx, 1);
        })
        .catch(() => {
          this.$buefy.snackbar.open("Erro ao excluir recompensa!", "error");
        })
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.loadRewards();
  },
};
</script>

<style></style>
