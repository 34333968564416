<template>
  <section class="subscritpions-list-view">
    <app-header icon="signature" title="Assinaturas"></app-header>
    <section>
      <div class="card">
        <div class="card-head">
          <div class="row mt-4">
            <div class="col-3 col-lg-3 col-md-3 col-sm-3 card-select">
              <div class="card-select-title">Advogado</div>

              <app-input
                v-model="searchTerm"
                placeholder="Informe o nome do advogado(a)"
                icon="search"
                type="is-primary"
              >
              </app-input>
            </div>

            <div class="col-3 col-lg-3 col-md-3 col-sm-3 card-select">
              <div class="card-select-title">Status</div>
              <b-field>
                <b-select
                  v-model="searchStatus"
                  placeholder="Selecione..."
                  expanded
                >
                  <b-select-option :value="null">Todos</b-select-option>
                  <b-select-option :value="1">Ativas</b-select-option>
                  <b-select-option :value="2"
                    >Aguardando pagamento</b-select-option
                  >
                  <b-select-option :value="3">Canceladas</b-select-option>
                  <b-select-option :value="4">Pagamento negado</b-select-option>
                </b-select>
              </b-field>
            </div>

            <div class="col-3 col-lg-3 col-md-3 col-sm-3 card-select">
              <div class="card-select-title">Realizadas entre as datas</div>

              <b-field>
                <b-datepicker
                  placeholder="Selecionar..."
                  v-model="searchDates"
                  range
                >
                </b-datepicker>
              </b-field>
            </div>

            <div class="col-2 col-lg-2 col-md-2 col-sm-2 mt-4">
              <b-tooltip label="Limpar filtros">
                <b-button
                  @click="clearFilters"
                  type="is-primary"
                  icon-right="recycle"
                ></b-button>
              </b-tooltip>

              <b-tooltip label="Exportar" class="ml-4">
                <b-button
                  @click="exportSubscriptions()"
                  type="is-primary"
                  icon-right="file-excel"
                  :disabled="isLoading"
                  :loading="isLoading"
                >
                  Exportar
                </b-button>
              </b-tooltip>
            </div>
          </div>
        </div>
        <div class="card-content">
          <div class="row">
            <div class="col-12">
              <b-table
                id="table-subscriptions"
                :total="total"
                :paginated="false"
                :data="data"
                :per-page="perPage"
                :current-page.sync="page"
                :loading.sync="isLoading"
                :pagination-simple="false"
                :pagination-rounded="false"
                aria-previous-label="Página Anterior"
                aria-current-label="Página Atual"
                aria-next-label="Próxima Página"
                aria-page-label="Página"
              >
                <b-table-column
                  v-slot="props"
                  field="lawyer"
                  label="Advogado(a)"
                >
                  <p>{{ props.row.doctor_infos.name }}</p>
                </b-table-column>

                <b-table-column v-slot="props" field="email" label="E-mail">
                  <p>{{ props.row.doctor_infos.email }}</p>
                </b-table-column>

                <b-table-column v-slot="props" field="status" label="Status">
                  <p>{{ props.row.status_label }}</p>
                </b-table-column>

                <b-table-column
                  v-slot="props"
                  field="subscription_plan_name"
                  label="Plano"
                >
                  <p>{{ getPlanName(props.row) }}</p>
                </b-table-column>

                <b-table-column
                  v-slot="props"
                  field="started_at"
                  label="Iniciou em"
                >
                  <p v-if="props.row.started_at">
                    {{ props.row.started_at | date }}
                  </p>
                  <p v-else>-</p>
                </b-table-column>

                <b-table-column
                  v-slot="props"
                  field="renewed_at"
                  label="Renovada em"
                >
                  <p v-if="props.row.renewed_at">
                    {{ props.row.renewed_at | date }}
                  </p>
                  <p v-else>-</p>
                </b-table-column>

                <b-table-column
                  v-slot="props"
                  field="valid_until"
                  label="Válida até"
                >
                  <p>{{ props.row.valid_until | date }}</p>
                </b-table-column>

                <b-table-column v-slot="props" label="Opções">
                  <b-tooltip label="Detalhes" class="m-1">
                    <b-button
                      @click="onSubscriptionViewClick(props)"
                      type="is-primary"
                      size="is-small"
                      icon-right="eye"
                    />
                  </b-tooltip>
                  <b-tooltip label="Liberar créditos" class="m-1">
                    <b-button
                      @click="onExtraCreditsClick(props.row)"
                      type="is-primary"
                      size="is-small"
                      icon-right="coins"
                    />
                  </b-tooltip>
                  <b-tooltip
                    v-if="props.row.has_pending_cancellation_request"
                    label="Solicitação de cancelamento"
                    class="m-1"
                  >
                    <b-button
                      @click="onCancellationRequestClick(props.row)"
                      type="is-danger"
                      size="is-small"
                      icon-right="exclamation-triangle"
                    />
                  </b-tooltip>
                </b-table-column>

                <template #footer v-if="total > 0">
                  <div class="row align-middle text-center">
                    <div
                      class="col-5 col-lg-5 col-md-5 col-sm-3 ptext text-right"
                    ></div>

                    <div class="col-auto text-center">
                      <b-pagination
                        aria-controls="table-doctors"
                        v-model="page"
                        :per-page="perPage"
                        :total-rows="total"
                        first-number
                        last-number
                      >
                      </b-pagination>
                    </div>

                    <div class="col-auto text-center">
                      <b-select
                        class="pselect"
                        v-model="perPage"
                        style="margin-left: 10px"
                      >
                        <b-select-option
                          v-for="i in perPageOptions"
                          :key="i"
                          :value="i"
                        >
                          <b>{{ i }}</b>
                        </b-select-option>
                      </b-select>
                    </div>
                  </div>
                </template>

                <template slot="empty">
                  <section class="section">
                    <div class="content has-text-grey has-text-centered">
                      <p>Nenhum registro encontrado.</p>
                    </div>
                  </section>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="mt-4">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">Planos de assinatura</div>
          <div class="card-header-buttons">
            <b-tooltip label="Adicionar plano" class="ml-4">
              <router-link
                :to="{
                  name: 'subscription-plans.form',
                }"
              >
                <b-button type="is-primary" icon-right="plus"> Novo </b-button>
              </router-link>
            </b-tooltip>
          </div>
        </div>
        <div class="card-content">
          <subscription-plans-list />
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import SubscriptionsService from "@/services/subscriptions.service";
import debounce from "lodash/debounce";
import SubscriptionDetails from "./SubscriptionDetails.vue";
import moment from "moment";
import SubscriptionPlansList from "../SubscriptionPlans/SubscriptionPlansList.vue";
import ExtraSubscriptionCredit from "../../modals/ExtraSubscriptionCredit.vue";
import CancellationRequest from "../../modals/CancellationRequest.vue";

export default {
  components: { SubscriptionPlansList },
  data: () => ({
    perPageOptions: [10, 20, 40, 80],
    searchTerm: null,
    searchStatus: null,
    searchDates: [],
    isLoading: false,
    perPage: 10,
    page: 1,
    total: 0,
    data: [],
  }),
  watch: {
    searchTerm: debounce(function () {
      this.page = 1;
      this.loadSubscriptions();
    }, 600),

    searchStatus() {
      this.page = 1;
      this.loadSubscriptions();
    },
    searchDates() {
      this.page = 1;
      this.loadSubscriptions();
    },
    page() {
      if (this.data.length > 0 && this.data.length < this.total)
        this.loadSubscriptions();
    },

    perPage() {
      this.page = 1;
      this.loadSubscriptions();
    },
  },
  methods: {
    loadSubscriptions() {
      this.isLoading = true;

      const params = {
        page: this.page,
        perPage: this.perPage,
      };

      if (this.searchTerm) {
        params["filter[doctor.name]"] = this.searchTerm.trim();
      }

      if (this.searchStatus) {
        params["filter[status]"] = this.searchStatus;
      }

      if (this.searchDates.length === 2) {
        const [start, end] = this.searchDates;
        const startDate = moment(start).format("YYYY-MM-DD");
        const endDate = moment(end).format("YYYY-MM-DD");

        params["filter[between_dates]"] = `${startDate},${endDate}`;
      }

      SubscriptionsService.get({
        ...params,
      })
        .then(({ data }) => {
          const { total, data: items, current_page } = data;
          this.total = total;
          this.data = items;
          this.page = current_page;
        })
        .finally(() => (this.isLoading = false));
    },
    onSubscriptionViewClick(prop) {
      this.$buefy.modal.open({
        parent: this,
        component: SubscriptionDetails,
        hasModalCard: true,
        trapFocus: true,
        canCancel: true,
        props: {
          subscription: prop.row,
        },
      });
    },
    onExtraCreditsClick(subscription) {
      this.$buefy.modal.open({
        parent: this,
        component: ExtraSubscriptionCredit,
        hasModalCard: true,
        trapFocus: true,
        canCancel: true,
        props: {
          subscription_id: subscription.id,
          extra_credits: subscription.extra_credits,
        },
        events: {
          close: (reload) => {
            reload && this.loadSubscriptions();
          },
        },
      });
    },
    onCancellationRequestClick(subscription) {
      this.$buefy.modal.open({
        parent: this,
        component: CancellationRequest,
        hasModalCard: true,
        trapFocus: true,
        canCancel: true,
        props: {
          cancellation_request: subscription.cancellation_requests[0],
          lawyer_id: subscription.doctor_id,
        },
        events: {
          close: (reload) => {
            reload && this.loadSubscriptions();
          },
        },
      });
    },
    clearFilters() {
      this.searchDates = [];
      this.searchStatus = null;
      this.searchTerm = null;
    },
    exportSubscriptions() {
      this.isLoading = true;

      SubscriptionsService.export()
        .then(({ data }) => {
          const fileName = data.split("/")[data.split("/").length - 1];
          const fileLink = document.createElement("a");

          fileLink.target = "_blank";
          fileLink.href = data;
          fileLink.setAttribute("download", fileName);
          fileLink.setAttribute("style", "display:none;");

          document.body.appendChild(fileLink);
          fileLink.click();

          fileLink.remove();
        })
        .catch(({ response }) => {
          const message =
            response.data.message || "Erro ao realizar exportação.";
          this.$buefy.snackbar.open(message);
        })
        .finally(() => (this.isLoading = false));
    },
    getPlanName(subscription) {
      let type = "Mensal";

      switch (subscription.subscription_plan_type) {
        case 3:
          type = "Trimestral";
          break;
        case 6:
          type = "Semestral";
          break;
        case 12:
          type = "Anual";
          break;
      }

      return `${subscription.subscription_plan.name} - ${type}`;
    },
  },
  mounted() {
    this.loadSubscriptions();
  },
};
</script>

<style lang="scss"></style>
