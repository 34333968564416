<template>
    <div class="dashboard-view">
        <app-header icon="home" title="Dashboard"></app-header>

        <section>
            <div class="row mt-4">
                <div class="col-12">
                    <div class="card">
                        <div class="card-content">
                            <div class="card-title">FILTROS</div>

                            <div class="row p-0 m-0">
                                <div class="col-11 mx-auto mx-md-0 col-md-3 mb-3 mb-md-0 card-select">
                                    <div class="card-select-title">Selecione um período</div>

                                    <b-field>
                                        <b-select
                                            @input="onPeriodFilterChange()"
                                            expanded
                                            name="period"
                                            v-model="periodFilter"
                                            placeholder="Selecionar"
                                        >
                                            <b-select-option
                                                v-for="period of periods"
                                                :key="period.id"
                                                :value="period.id"
                                            >
                                                {{ period.name }}
                                            </b-select-option>
                                        </b-select>
                                    </b-field>
                                </div>

                                <div
                                    v-if="isCustomPeriod"
                                    class="col-11 col-md-3 mx-auto mx-md-0 mb-3 mb-md-0 card-select-custom"
                                >
                                    <div class="card-select-custom-title">Período</div>

                                    <b-field>
                                        <b-datepicker
                                            @input="onRangeChange"
                                            placeholder="Clique para selecionar..."
                                            icon="calendar"
                                            range
                                        >
                                        </b-datepicker>
                                    </b-field>
                                </div>

                                <div
                                    class="col-11 col-md-3 mx-auto mx-md-0 card-select"
                                    style="margin-right: 15px"
                                >
                                    <div class="card-select-title">Ativo/Inativo</div>

                                    <b-field>
                                        <b-select v-model="searchActive" placeholder="Selecione..." expanded>
                                            <b-select-option :value="-1">Todos</b-select-option>
                                            <b-select-option :value="1">Ativo</b-select-option>
                                            <b-select-option :value="0">Inativo</b-select-option>
                                        </b-select>
                                    </b-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <b-loading
                        :is-full-page="false"
                        :active.sync="isloadingScheduleInfos"
                        :can-cancel="false"
                    />

                    <div class="title">Atendimentos</div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-6 col-md-3">
                    <div class="control">
                        <div class="box-primary">
                            <h6>Total de atendimentos</h6>

                            <span>
                                {{ parseInt(schedulesInfo.total) || 0 }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-6 col-md-3">
                    <div class="control">
                        <div class="box-primary">
                            <h6>Total de atendimentos realizados</h6>

                            <span>
                                {{ parseInt(schedulesInfo.performeds) || 0 }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-3">
                    <div class="control">
                        <div class="box-primary">
                            <h6>Total de atendimentos à realizar</h6>

                            <span>
                                {{ parseInt(schedulesInfo.total - schedulesInfo.performeds) || 0 }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-6 col-md-3">
                    <div class="control">
                        <div class="box-primary">
                            <h6>Valor Total dos atendimentos</h6>

                            <span v-if="parseInt(schedulesInfo.amount) > 0">
                                {{ money(schedulesInfo.amount) }}
                            </span>

                            <span v-else>0</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-6">
                    <CountCreatedByMonth
                        v-if="!isloadingScheduleInfos"
                        :items="schedulesInfo.created_by_date"
                        label="Cadastros"
                    />
                </div>

                <div class="col-6">
                    <AmountByMonth
                        v-if="!isloadingScheduleInfos"
                        :items="schedulesInfo.growing_by_date"
                        label="Crescimento"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-lg-12 col-md-12 col-sm-6">
                    <div class="title">Atendimentos por especialidades</div>
                </div>
            </div>

            <div class="row col-12 d-flex flex-wrap justify-content-between mt-4">
                <div class="col-6 col-md-4">
                    <div class="box-primary">
                        <h5>Consultas em andamento</h5>

                        <span>{{ schedulesInfo.total_online }}</span>
                    </div>
                </div>
            </div>

            <section>
                <div class="row mt-2">
                    <div class="col-12 col_table">
                        <b-table
                            @page-change="loadSchedulesBySpecialty"
                            :current-page.sync="schedules_by_specialty.currentPage"
                            :loading="isLoading"
                            :per-page="schedules_by_specialty.perPage"
                            :total="schedules_by_specialty.rows"
                            :data="schedules_by_specialty.items"
                            default-sort="row.name"
                            paginated
                            backend-pagination
                            aria-next-label="Próxima Página"
                            aria-previous-label="Página Anterior"
                            aria-page-label="Página"
                            aria-current-label="Página Atual"
                        >
                            <b-table-column width="40%" v-slot="props" field="name" label="NOME" sortable>
                                {{ props.row.name }}
                            </b-table-column>

                            <b-table-column
                                width="20%"
                                v-slot="props"
                                field="count"
                                label="QUANTIDADE"
                                sortable
                                centered
                            >
                                {{ props.row.count }}
                            </b-table-column>

                            <b-table-column
                                width="10%"
                                v-slot="props"
                                field="count"
                                label="REALIZADOS"
                                sortable
                                centered
                            >
                                {{ props.row.performed | 0 }}
                            </b-table-column>

                            <b-table-column width="10%" v-slot="props" field="amount" label="VALORES" sortable>
                                <div v-if="props.row.value > 0" class="ctab-r">
                                    {{ money(props.row.value) }}
                                </div>
                                <div v-else class="text-center">0</div>
                            </b-table-column>

                            <!-- <b-table-column width="20%" v-slot="props" label="EXPORTAR XLS" align-center centered>
            <div class="text-center">
              <b-tooltip v-if="props.row" label="EXPORTAR XLS">
                <b-button
                  @click="export_schedules_by_specialty()"
                  type="is-primary"
                  icon-right="file-excel"
                  :disabled="isExporting"
                  :loading="isExporting"
                >
                </b-button>
              </b-tooltip>
            </div>
          </b-table-column> -->

                            <template slot="empty">
                                <section class="section">
                                    <div class="content has-text-grey has-text-centered">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p>
                                                        <b-icon icon="frown" size="h3"></b-icon>
                                                    </p>
                                                    <p>Nenhum registro encontrado.</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </div>
                                </section>
                            </template>

                            <template #footer v-if="schedules_by_specialty.rows > 0">
                                <div class="row align-middle text-center">
                                    <div
                                        v-if="schedules_by_specialty.perPage <= schedules_by_specialty.rows"
                                        class="col-5 col-lg-5 col-md-5 col-sm-3 ptext text-right"
                                    >
                                        Exibindo {{ schedules_by_specialty.perPage }} de
                                        {{ schedules_by_specialty.rows }}
                                    </div>
                                    <div v-else class="col-5 col-lg-5 col-md-5 col-sm-3 ptext text-right">
                                        Exibindo {{ schedules_by_specialty.rows }} de
                                        {{ schedules_by_specialty.rows }}
                                    </div>
                                    <div class="col-auto col-md-auto col-sm-auto text-center">
                                        <b-pagination
                                            v-model="schedules_by_specialty.currentPage"
                                            :total-rows="schedules_by_specialty.rows"
                                            align="center"
                                            :per-page="schedules_by_specialty.perPage"
                                            :limit="4"
                                        >
                                        </b-pagination>
                                    </div>
                                    <div
                                        v-if="schedules_by_specialty.perPage < schedules_by_specialty.rows"
                                        class="col-5 col-lg-5 col-md-5 col-sm-3 text-left"
                                    >
                                        <b-select
                                            class="pselect"
                                            v-model="schedules_by_specialty.perPage"
                                            style="margin-left: 5px"
                                        >
                                            <b-select-option v-for="i in pageOptions" :key="i">{{
                                                i
                                            }}</b-select-option>
                                        </b-select>
                                    </div>
                                </div>
                            </template>
                        </b-table>
                    </div>
                </div>
            </section>

            <div class="row mt-4">
                <div class="col-12 col-lg-12 col-md-12 col-sm-6">
                    <b-loading
                        :is-full-page="false"
                        :active.sync="isloadingDoctorsInfos"
                        :can-cancel="false"
                    />
                    <div class="title">Profissionais</div>
                </div>
            </div>

            <div class="row col-12 d-flex flex-wrap justify-content-between mt-4">
                <div class="col-6 col-md-4">
                    <div class="box-primary">
                        <h5>Aprovados</h5>

                        <span> {{ parseInt(approved) || 0 }} </span>
                    </div>
                </div>

                <div class="col-6 col-md-4">
                    <div class="box-primary">
                        <h5>Aguardando aprovação</h5>

                        <span>
                            {{ parseInt(doctors_by_amount_count.total - approved) || 0 }}
                        </span>
                    </div>
                </div>

                <div class="col-6 col-md-2">
                    <div class="box-primary">
                        <h5>Online</h5>

                        <span>{{ parseInt(total_online) || 0 }} </span>
                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-6">
                    <CountCreatedByMonth
                        v-if="!isloadingDoctorInfos"
                        :items="doctorsInfo.created_by_date"
                        label="Cadastros - period.name"
                    />
                </div>

                <div class="col-6">
                    <AmountByMonth
                        v-if="!isloadingDoctorInfos"
                        :items="doctorsInfo.growing_by_date"
                        label="Crescimento - period.name"
                    />
                </div>
                <!-- </div> -->
            </div>

            <div class="row">
                <div class="col-12">
                    <b-loading
                        :is-full-page="false"
                        :active.sync="isloadingPatientInfos"
                        :can-cancel="false"
                    />
                    <div class="title">Clientes</div>
                </div>
            </div>

            <div class="row col-12 d-flex flex-wrap justify-content-between mt-4">
                <div class="col-6 col-md-4">
                    <div class="box-primary">
                        <h5>Total de Clientes</h5>

                        <span>{{ patientsInfo.total }}</span>
                    </div>
                </div>

                <div class="col-6 col-md-4 mb-2 mb-md-0">
                    <div class="box-primary">
                        <h5>Clientes Ativos</h5>

                        <span>{{ patientsInfo.total_active }}</span>
                    </div>
                </div>

                <div class="col-4 col-md-4">
                    <div class="box-primary">
                        <h5>Clientes Inativos</h5>

                        <span>{{ patientsInfo.total_inactive }}</span>
                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-6">
                    <CountCreatedByMonth
                        v-if="!isloadingPatientInfos"
                        :items="patientsInfo.created_by_date"
                        label="Cadastros"
                    />
                </div>

                <div class="col-6">
                    <AmountByMonth
                        v-if="!isloadingPatientInfos"
                        :items="patientsInfo.growing_by_date"
                        label="Crescimento"
                    />
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
// import SchedulesService from '@/services/schedules.service';
import DoctorsService from "@/services/doctors.service";
// import PatientsService from '@/services/patients.service';
// import SpecialtiesService from '@/services/specialties.service.js';

import PERIODS from "@/constants/dashboard/periods-filter.constant";
import AmountByMonth from "@/components/charts/admin/AmountByMonth";
import CountCreatedByMonth from "@/components/charts/admin/CountCreatedByMonth";

import { mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "Dashboard",

    components: {
        CountCreatedByMonth,
        AmountByMonth,
    },

    data: () => ({
        path: "",
        isloadingDoctorsInfos: false,
        isloadingScheduleInfos: false,
        patientsChartsInfo: false,
        patientsCountTable: false,
        patientsAmountTable: false,
        doctorsChartsInfo: false,
        doctorsCountTable: false,
        doctorsAmountTable: false,
        datesFilter: "",
        isExporting: false,
        searchActive: -1,
        mheight: 420,
        offset: "",
        pages: 0,
        atdEspRows: 0,
        advRows: 0,
        cliRows: 0,
        isLoading: false,
        periodFilter: 10,
        periods: PERIODS,
        selectedPeriod: null,
        items: [],
        data: [],
        dates: [],
        atendimentos_items: [],
        advogados_items: [],
        advogados_performeds: [],
        advogados_total: [],
        advogados_amount: [],
        pacientes_items: [],
        pacientes_performeds: [],
        pacientes_amount: [],
        atendimentos_minheight: 0,
        advogados_minheight: 0,
        pacientes_minheight: 0,
        total: 0,
        page: 1,
        perPage: 5,
        a: 0,
        isPaginated: false,
        isPaginationSimple: false,
        isPaginationRounded: false,
        paginationPosition: "both",
        defaultSortDirection: "asc",
        sortIcon: "arrow-down",
        sortIconSize: "is-small",
        currentPage: 1,
        pageOptions: [10, 20, 40, 80],
        schedulesInfo: {
            total: 0,
            amount: 0,
            performeds: 0,
            rows: 0,
            page: 1,
            items: [],
            currentPage: 1,
            created_by_date: [],
            growing_by_date: [],
            total_online: 0,
        },

        schedules_by_specialty: {
            currentPage: 1,
            perPage: 0,
            items: [],
            rows: 0,
        },

        doctors_by_amount_count: {
            items: [],
            total: 0,
            currentPage: 1,
            perPage: 0,
        },

        patientsInfo: {
            patients_by_amount: {
                currentPage: 1,
                perPage: 0,
                items: [],
                rows: 0,
            },

            patients_by_count: {
                currentPage: 1,
                perPage: 0,
                items: [],
                rows: 0,
            },

            patients_by_amount_count: {
                items: [],
                total: 0,
                currentPage: 1,
                perPage: 0,
            },

            total: 0,
            total_active: 0,
            total_inactive: 0,
            amount: 0,
            performeds: 0,
            currentPage: 1,
            perPage: 0,
            items: [],
            rows: 0,
            created_by_date: [],
            growing_by_date: [],
        },

        doctorsInfo: {
            total: 0,
            amount: 0,
            performeds: 0,
            rows: 0,
            page: 1,
            items: [],
            currentPage: 1,
            created_by_date: [],
            growing_by_date: [],
            doctors_by_amount: {
                currentPage: 1,
            },
            doctors_by_count: {
                currentPage: 1,
            },
        },

        atendimentos_performeds: [],
        awaiting: 0,
        approved: 0,
        total_online: 0,
    }),

    mounted() {
        this.loadDashboardInfos();
    },

    beforeMount() {
        this.onPeriodFilterChange();
    },

    computed: {
        ...mapGetters(["doctorId"]),
        // isCustomPeriod() {
        //   const period = this.periods.filter(
        //     (period) => period.id == this.periodFilter
        //   )[0];
        //
        //   return period.custom ?? false;
        // },
        isloadingPatientInfos() {
            return !(this.patientsCountTable && this.patientsAmountTable && this.patientsChartsInfo);
        },

        isloadingDoctorInfos() {
            return !(this.doctorsCountTable && this.doctorsAmountTable && this.doctorsChartsInfo);
        },
    },


    watch: {
        searchActive() {
            this.page = 1;
            this.loadDashboardInfos();
        },

        perPage() {
            if (this.perPage > this.data.length) this.loadDashboardInfos();
        },
    },

    methods: {
        loadDashboardInfos() {
            this.isloadingScheduleInfos = true;
            this.patientsChartsInfo = false;
            this.patientsCountTable = false;
            this.patientsAmountTable = false;
            this.doctorsChartsInfo = false;
            this.doctorsCountTable = false;
            this.doctorsAmountTable = false;

            const promises = [this.loadSchedulesInfo(), this.loadPatientsInfos(), this.loadDoctorsInfos()];
            Promise.all(promises);
        },

        loadSchedulesInfo() {
            this.isloadingScheduleInfos = true;

            const promises = [this.loadSchedulesChartsInfo(), this.loadSchedulesBySpecialty()];

            Promise.all(promises).finally(() => (this.isloadingScheduleInfos = false));
        },

        loadSchedulesChartsInfo() {
            const params = {
                doctor_id: this.doctorId,
                period: this.selectedPeriod,
            };

            DashboardService.getSchedulesInfo(params).then(({ data }) => {
                this.schedulesInfo.growing_by_date = data.growing_by_date;
                this.schedulesInfo.created_by_date = data.created_by_date;
                this.schedulesInfo.total = data.total;
                this.schedulesInfo.amount = data.amount;
                this.schedulesInfo.performeds = data.performeds;
            });
        },

        loadSchedulesBySpecialty() {
            const params = {
                page: 1,
                period: this.selectedPeriod,
            };

            DashboardService.getSchedulesBySpecialty(params).then(({ data }) => {
                const { total, data: items, current_page, per_page } = data;

                this.schedules_by_specialty = {
                    currentPage: current_page,
                    total: total,
                    perPage: per_page,
                    items: items,
                    rows: items.length,
                };
            });
        },

        loadScheduleAmountPatientsTable() {
            const params = {
                doctor_id: this.doctorId,
                period: this.selectedPeriod,
                only_patients: true,
                type: "schedules_sum",
                page: this.patientsInfo.patients_by_amount.currentPage,
                active: this.searchActive,
            };

            DashboardService.getPatientsTableInfo({ ...params })
                .then(({ data }) => {
                    this.patientsInfo.patients_by_amount.currentPage = data.current_page;
                    this.patientsInfo.patients_by_amount.items = Object.values(data.data);
                    this.patientsInfo.patients_by_amount.total = data.total;
                    this.patientsInfo.patients_by_amount.perPage = data.per_page;
                    this.patientsInfo.patients_by_amount.rows = this.patientsInfo.patients_by_amount.items.length;
                    this.patientsInfo.total = data.total;
                    this.patientsInfo.total_active = data.data?.filter((x) => !!x.active)?.length;
                    this.patientsInfo.total_inactive = data.data
                        ?.map((x) => ({ ...x, active: !!x.active }))
                        ?.filter((x) => !x.active)?.length;
                })
                .finally(() => (this.patientsAmountTable = true));
        },

        loadScheduleCountPatientsTable() {
            const params = {
                doctor_id: this.doctorId,
                period: this.selectedPeriod,
                only_patients: true,
                type: "schedules_count",
                page: this.patientsInfo.patients_by_count.current_page,
                active: this.searchActive,
            };

            DashboardService.getPatientsTableInfo({ ...params })
                .then(({ data }) => {
                    const { total, data: items, current_page, per_page } = data;

                    this.patientsInfo.patients_by_count.current_page = current_page;
                    this.patientsInfo.patients_by_count.items = items;
                    this.patientsInfo.patients_by_count.total = total;
                    this.patientsInfo.patients_by_count.rows = items.length;
                    this.patientsInfo.patients_by_count.perPage = per_page;
                    this.patientsInfo.patients_by_amount_count.items = [];
                    this.patientsInfo.patients_by_amount_count.rows = items.length;
                    this.patientsInfo.patients_by_amount_count.perPage = per_page;
                    this.patientsInfo.patients_by_amount_count.total = total;
                    this.patientsInfo.patients_by_amount_count.current_page = current_page;
                    this.patientsInfo.patients_by_amount.items.forEach((item) =>
                        this.patientsInfo.patients_by_amount_count.items.push(item)
                    );
                    this.patientsInfo.patients_by_count.items.forEach((item, i) => {
                        this.patientsInfo.patients_by_amount_count.items[i].schedules_count =
                            item.schedules_count;
                    });
                })
                .finally(() => (this.patientsCountTable = true));
        },

        loadPatientsChartsInfos() {
            const params = {
                doctor_id: this.doctorId,
                period: this.selectedPeriod,
                active: this.searchActive,
            };
            DashboardService.getPatientsChartsInfo({ ...params })
                .then(({ data }) => {
                    this.patientsInfo.growing_by_date = data.growing_by_date;
                    this.patientsInfo.created_by_date = data.created_by_date;
                })
                .finally(() => (this.patientsChartsInfo = true));
        },

        loadPatientsInfos() {
            const promises = [
                this.loadScheduleAmountPatientsTable(),
                this.loadScheduleCountPatientsTable(),
                this.loadPatientsChartsInfos(),
            ];

            Promise.all(promises);
        },

        loadScheduleCountDoctorsTable() {
            const params = {
                period: this.selectedPeriod,
                type: "schedules_count",
                page: this.doctorsInfo.doctors_by_count.current_page,
                active: this.searchActive,
            };

            DashboardService.getDoctorsTableInfo({ ...params })
                .then(({ data }) => {
                    const { total, data: items, current_page, per_page } = data;

                    this.doctorsInfo.doctors_by_count = {
                        current_page: current_page,
                        items: items,
                        total: total,
                        rows: items.length,
                        perPage: per_page,
                    };
                    this.doctors_by_amount_count = [];
                    (this.doctors_by_amount_count.total = this.doctorsInfo.doctors_by_count.total),
                        (this.doctors_by_amount_count.rows = this.doctorsInfo.doctors_by_count.rows),
                        (this.doctors_by_amount_count.perPage = this.doctorsInfo.doctors_by_count.perPage),
                        (this.doctors_by_amount_count.items = this.doctorsInfo.doctors_by_amount.items.forEach(
                            (item) => {
                                this.doctors_by_amount_count.push(item);
                            }
                        ));
                    this.doctorsInfo.doctors_by_count.items.forEach((item, i) => {
                        this.doctors_by_amount_count[i].schedules_count = item.schedules_count;
                    });
                })
                .finally(() => (this.doctorsCountTable = true));
        },

        loadDoctorsChartsInfos() {
            const params = {
                period: this.selectedPeriod,
                active: this.searchActive,
            };

            DoctorsService.getcountByApproved(params).then(({ data }) => {
                this.approved = data.approved;
            });

            DoctorsService.get(params).then(({ data }) => {
                this.total_online = data.total_online;
            });

            DashboardService.getDoctorsChartsInfo({ ...params })
                .then(({ data }) => {
                    this.doctorsInfo.growing_by_date = data.growing_by_date;
                    this.doctorsInfo.created_by_date = data.created_by_date;
                })
                .finally(() => (this.doctorsChartsInfo = true));
        },

        loadDoctorsInfos() {
            const promises = [
                this.loadScheduleAmountDoctorsTable(),
                this.loadScheduleCountDoctorsTable(),
                this.loadDoctorsChartsInfos(),
            ];

            Promise.all(promises);
        },

        loadScheduleAmountDoctorsTable() {
            const params = {
                period: this.selectedPeriod,
                type: "schedules_sum",
                page: this.doctorsInfo.doctors_by_amount.current_page,
                active: this.searchActive,
            };

            DashboardService.getDoctorsTableInfo({ ...params })
                .then(({ data }) => {
                    this.doctorsInfo.doctors_by_amount = {
                        current_page: data.current_page,
                        items: data.data,
                        total: data.total,
                    };
                })
                .finally(() => (this.doctorsAmountTable = true));
        },

        onlineDoctor(value, doctor_id, row) {
            DoctorsService.patch(doctor_id, { on_line: value })
                .then(() => {
                    this.$buefy.snackbar.open("Advogado atualizado com sucesso!");
                })
                .catch(({ response }) => {
                    const { message, errors } = response;

                    if (response.status === 422) {
                        this.errors = errors;
                        value == 1 ? (row.on_line = 0) : (row.on_line = 1);
                    } else
                        this.$buefy.snackbar.open(
                            message || "Erro ao tentar a atualizar o Advogado.",
                            "error"
                        );
                    value == 1 ? (row.on_line = 0) : (row.on_line = 1);
                })
                .finally(() => (this.isSaving = false));
        },
        // getSchedulesOnline() {
        //   DashboardService.getOnline()
        //     .then(({ data }) => {
        //       this.total_online = data.data;
        //     })
        //     .finally();
        //
        // },
        linkGen(pageNum) {
            return this.links[pageNum - 1];
        },

        pageGen(pageNum) {
            return this.links[pageNum - 1].slice(1);
        },

        money(value) {
            let valueParsed = parseFloat(value);

            return isNaN(value)
                ? 0
                : valueParsed.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                  });
        },

        onPeriodFilterChange() {
            const period = this.periods.find((item) => item.id == this.periodFilter);

            if (!period.custom) {
                const { start, end } = period;

                this.dates = [moment(start).format("YYYY-MM-DD"), moment(end).format("YYYY-MM-DD")];
                this.selectedPeriod = `${this.dates[0]},${this.dates[1]}`;

                this.loadDashboardInfos();
                //this.loadDoctors();
                //this.loadPatints();
                this.page = 1;
                this.isCustomPeriod = false;
            } else {
                this.isCustomPeriod = true;
                this.dates = [];
            }
        },

        onRangeChange(values) {
            const dates = values.map((date) => moment(date).format("YYYY-MM-DD"));
            this.dates[1] = moment(this.dates[1]).add(1, "day").format("YYYY-MM-DD");
            this.selectedPeriod = `${dates[0]},${dates[1]}`;

            this.loadDashboardInfos();
            //this.loadDoctors();
            //this.loadPatints();
        },
    },
};
</script>

<style lang="scss" scoped>
.col_table {
    min-height: 430px;
}

.box-sbordas {
    font-weight: 600 !important;
    font-size: 1rem;
}

.box-primary {
    background: var(--color-secondary) !important;
    color: var(--color-white);

    border-left: 5px solid var(--color-primary);
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding-top: 1rem;
    padding-bottom: 1rem;

    & h5,
    h6 {
        font-size: 1rem;
        font-weight: 500;
    }

    & span {
        font-size: 1.1rem;
    }
}
</style>
