<template>
  <section class="referrer-list-view">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">Participantes</div>
      </div>
      <div class="card-content">
        <b-table
          :data="data"
          :total="total"
          :paginated="false"
          :per-page="perPage"
          :current-page.sync="page"
          :loading.sync="isLoading"
          :pagination-simple="false"
          :pagination-rounded="false"
          aria-previous-label="Página Anterior"
          aria-current-label="Página Atual"
          aria-next-label="Próxima Página"
          aria-page-label="Página"
        >
          <b-table-column v-slot="props" field="name" label="Nome">
            <p>{{ props.row.name }}</p>
          </b-table-column>

          <b-table-column v-slot="props" field="email" label="E-mail">
            <p>{{ props.row.email }}</p>
          </b-table-column>

          <b-table-column
            v-slot="props"
            field="referral_code"
            label="Código de indicação"
          >
            <p>{{ props.row.referral_code }}</p>
          </b-table-column>

          <b-table-column
            v-slot="props"
            field="created_at"
            label="Participando desde"
          >
            <p>{{ props.row.created_at | date("DD/MM/YYYY") }}</p>
          </b-table-column>

          <b-table-column
            v-slot="props"
            field="total_invitations"
            label="Total de indicações"
          >
            <p>{{ props.row.total_invitations }}</p>
          </b-table-column>

          <b-table-column
            v-slot="props"
            field="total_conversions"
            label="Total de conversões"
          >
            <p>{{ props.row.total_conversions }}</p>
          </b-table-column>

          <template #footer v-if="total > 0">
            <div class="row align-middle text-center pagination-container">
              <div
                class="col-5 col-lg-5 col-md-5 col-sm-3 ptext text-right"
              ></div>

              <div class="col-auto text-center">
                <b-pagination
                  aria-controls="table-doctors"
                  v-model="page"
                  :per-page="perPage"
                  :total-rows="total"
                  first-number
                  last-number
                >
                </b-pagination>
              </div>

              <div class="col-auto text-center">
                <b-select
                  class="pselect"
                  v-model="perPage"
                  style="margin-left: 10px"
                >
                  <b-select-option
                    v-for="i in perPageOptions"
                    :key="i"
                    :value="i"
                  >
                    <b>{{ i }}</b>
                  </b-select-option>
                </b-select>
              </div>
            </div>
          </template>

          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>Nenhum registro encontrado.</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </div>
  </section>
</template>

<script>
import ReferralProgramService from "../../../services/referral-program.service";
export default {
  data: () => ({
    perPageOptions: [10, 20, 40, 80],
    isLoading: false,
    perPage: 10,
    data: [],
    total: 0,
    page: 1,
  }),
  watch: {
    page() {
      this.loadReferrers();
    },
    perPage() {
      this.loadReferrers();
    },
  },
  methods: {
    loadReferrers() {
      this.isLoading = true;

      ReferralProgramService.getReferrers({
        page: this.page,
        per_page: this.perPage,
      })
        .then(({ data }) => {
          const { data: items, total } = data;
          this.total = total;
          this.data = items ?? [];
        })
        .catch(() => {
          this.$buefy.snackbar.open("Erro ao carregar usuários.");
        })
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.loadReferrers();
  },
};
</script>

<style lang="scss">
.pagination-container {
  justify-content: end;
  margin-right: 10px;
}
</style>
