<template>
  <div class="medical-record-modal">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Qual o motivo?</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <b-field label="Descrição">
          <b-input
            style="border: 1px solid #f5f7f7"
            v-model="justification"
            type="textarea"
            row="10"
            placeholder="Descreva o motivo da reprovação do cadastro do advogado(a)"
          />
        </b-field>
      </section>
      <footer class="modal-card-foot is-right">
        <b-button @click="$emit('close')">Fechar</b-button>
        <b-button
          :disabled="!justification"
          @click="$emit('close', justification)"
          type="is-secondary"
        >
          Reprovar advogado(a)
        </b-button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    justification: null,
  }),
};
</script>

<style lang="scss" scoped></style>
