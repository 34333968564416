<template>
  <section>
    <app-header :title="pageTitle" goes-back />
    <b-loading :active.sync="isLoading" :is-full-page="false" />

    <!-- <div class="container-cadastro">
      <div v-if="!isLoading" class="row mt-4">
        <form @submit.prevent="onSubmit">
          <b-field label="Nome" class="col-3 required">
            <b-input
              required
              type="text"
              name="name"
              v-model="plan.name"
              :errors="errors.name"
            />
          </b-field>
        </form>
      </div>
    </div> -->

    <section>
      <form v-if="!isLoading" @submit.prevent="">
        <div class="container-cadastro">
          <div class="row">
            <div class="col-4">
              <div class="card cadastro">
                <div class="card-body">
                  <div class="card-title-cadastro">Nome</div>
                  <input
                    class="input-cadastro"
                    type="text"
                    name="name"
                    required
                    v-model="plan.name"
                    :errors="errors.name"
                  />
                </div>
              </div>
            </div>

            <div class="col-4">
              <div class="card cadastro">
                <div class="card-body">
                  <div class="card-title-cadastro">Créditos disponíveis</div>
                  <input
                    class="input-cadastro"
                    type="text"
                    name="name"
                    required
                    v-model="plan.schedules_limit"
                    :errors="errors.schedules_limit"
                  />
                </div>
              </div>
            </div>

            <div class="col-4">
              <div class="card cadastro">
                <div class="card-body">
                  <div class="card-title-cadastro">Valor</div>
                  <app-input
                    v-model="plan.value"
                    :mask="MASKS.money"
                    :errors="errors.value"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-3">
              <div class="card cadastro">
                <div class="card-body">
                  <div class="card-title-cadastro">Desconto mensal</div>
                  <input
                    required
                    :min="0"
                    :max="100"
                    type="number"
                    class="input-cadastro"
                    v-model="plan.monthly_discount"
                    :errors="errors.monthly_discount"
                  />
                </div>
              </div>
            </div>

            <div class="col-3">
              <div class="card cadastro">
                <div class="card-body">
                  <div class="card-title-cadastro">Desconto trimestral</div>
                  <input
                    required
                    :min="0"
                    :max="100"
                    type="number"
                    class="input-cadastro"
                    v-model="plan.quarter_discount"
                    :errors="errors.quarter_discount"
                  />
                </div>
              </div>
            </div>

            <div class="col-3">
              <div class="card cadastro">
                <div class="card-body">
                  <div class="card-title-cadastro">Desconto semestral</div>
                  <input
                    required
                    :min="0"
                    :max="100"
                    type="number"
                    class="input-cadastro"
                    v-model="plan.semester_discount"
                    :errors="errors.semester_discount"
                  />
                </div>
              </div>
            </div>

            <div class="col-3">
              <div class="card cadastro">
                <div class="card-body">
                  <div class="card-title-cadastro">Desconto anual</div>
                  <input
                    required
                    :min="0"
                    :max="100"
                    type="number"
                    class="input-cadastro"
                    v-model="plan.yearly_discount"
                    :errors="errors.yearly_discount"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div
              @click="onSubmit"
              class="col-12 is-right"
              style="display: flex; justify-content: right"
            >
              <b-button :loading="isLoading" :disabled="isLoading">
                Salvar
              </b-button>
            </div>
          </div>
        </div>
      </form>
    </section>
  </section>
</template>

<script>
import SubscriptionService from "@/services/subscriptions.service";
import MASKS from "@/constants/masks.constant";

export default {
  name: "subscription-plan",
  data: () => ({
    plan: {
      name: null,
      value: "0",
      monthly_discount: 0,
      quarter_discount: 0,
      semester_discount: 0,
      yearly_discount: 0,
      schedules_limit: 0,
    },
    errors: {},
    MASKS: MASKS,
    planId: null,
    isLoading: false,
  }),
  computed: {
    isNew() {
      return !this.planId;
    },
    pageTitle() {
      return this.isNew ? "Criar plano" : "Editar plano";
    },
  },
  methods: {
    loadPlanDetails() {
      this.isLoading = true;

      SubscriptionService.getPlan(this.planId)
        .then(({ data }) => {
          this.plan = data;
        })
        .catch(() => {
          this.$buefy.snackbar.open("Erro ao carregar detalhes do plano");
          this.$router.go(-1);
        })
        .finally(() => (this.isLoading = false));
    },
    onSubmit() {
      this.isLoading = true;

      this.isNew && this.store();
      !this.isNew && this.update();
    },

    store() {
      SubscriptionService.storePlan({
        ...this.plan,
        value: this.plan.value.replace(/[^\d,-]/g, ""),
      })
        .then(() => {
          this.$buefy.snackbar.open("Plano criado com sucesso!");
          this.$router.go(-1);
        })
        .catch(({ response }) => {
          const { errors } = response;

          if (response.status === 422) {
            this.errors = errors;
          } else
            this.$buefy.snackbar.open(
              "Erro ao tentar a criar o plano.",
              "error"
            );
        })
        .finally(() => (this.isLoading = false));
    },
    update() {
      SubscriptionService.updatePlan(this.planId, {
        ...this.plan,
        value: this.plan.value.replace(/[^\d,-]/g, ""),
      })
        .then(() => {
          this.$buefy.snackbar.open("Plano atualizado com sucesso!");
          this.$router.go(-1);
        })
        .catch(({ response }) => {
          const { message, errors } = response;

          if (response.status === 422) {
            this.errors = errors;
          } else
            this.$buefy.snackbar.open(
              message || "Erro ao tentar a atualizar o plano.",
              "error"
            );
        })
        .finally(() => (this.isLoading = false));
    },
  },
  beforeMount() {
    this.planId = this.$route.params.id;
  },
  mounted() {
    !this.isNew && this.loadPlanDetails();
  },
};
</script>

<style></style>
