import Api from "./api.service";

const prefix = "subscriptions";

export default {
  get: (params) => Api.get(prefix, { params }),
  export: (params = {}) => Api.get(`${prefix}/export`, { params }),
  getPlans: (params = {}) => Api.get("subscription-plans", { params }),
  getPlan: (planId) => Api.get(`subscription-plans/${planId}`),
  storePlan: (data = {}) => Api.post(`subscription-plans`, data),
  updatePlan: (planId, data = {}) =>
    Api.put(`subscription-plans/${planId}`, data),
  deletePlan: (planId) => Api.delete(`subscription-plans/${planId}`),
  storeExtraSubscriptionCredits: (data = {}) =>
    Api.post(`${prefix}/extra-credits`, data),
};
