<template>
    <div class="discount-list-view">
        <app-header title="Descontos" icon="percent"></app-header>

        <section>
            <div class="row mt-2 justify-content-around align-items-middle">
                <div class="col-12 col-lg-12 col-md-12 col-sm-6">
                    <div class="card">
                        <div class="card-content">
                            <div class="card-title">FILTROS</div>

                            <div class="row d-flex">
                                <div class="col-11 col-lg-3 col-md-3 col-sm-3 card-select">
                                    <div class="card-select-title mb-2">Disconto</div>

                                    <app-input
                                        v-model="searchTerm"
                                        placeholder="Código, Valor data de expiração"
                                        icon="search"
                                        type="is-primary"
                                    >
                                    </app-input>
                                </div>

                                <div class="col-11 col-lg-3 col-md-4 col-sm-8 card-select">
                                    <div class="card-select-title mb-2">Tipo de desconto</div>

                                    <b-field>
                                        <b-select
                                            v-model="discount_type"
                                            :options="discount_types"
                                            text-field="label"
                                            placeholder="Selecione..."
                                            expanded
                                        >
                                            <b-select-option :value="-1">Todos</b-select-option>
                                        </b-select>
                                    </b-field>
                                </div>

                                <div class="col-5 col-lg-5 col-md-5 col-sm-4">
                                    <b-tooltip label="CRIAR DESCONTO">
                                        <router-link :to="{ name: 'discount.save' }">
                                            <b-button
                                                type="is-primary"
                                                icon-right="plus"
                                                style="margin-top: 10px; margin-right: 15px"
                                                >NOVO</b-button
                                            >
                                        </router-link>
                                    </b-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-12 col-lg-12 col-md-12 col-sm-6">
                    <b-table
                        id="table-discounts"
                        class="table-custom"
                        :data="discounts_list"
                        :per-page="perPage"
                        :current-page.sync="page"
                        :total="total"
                        :loading="isLoading"
                        :paginated="false"
                        :pagination-simple="false"
                        pagination-position="both"
                        default-sort-direction="asc"
                        :pagination-rounded="false"
                        sort-icon="arrow-down"
                        sort-icon-size="is-small"
                        default-sort="row.name"
                        aria-next-label="Próxima Página"
                        aria-previous-label="Página Anterior"
                        aria-page-label="Página"
                        aria-current-label="Página Atual"
                    >
                        <b-table-column v-slot="props" field="id" label="ID" sortable centered>
                            {{ props.row.id }}
                        </b-table-column>

                        <b-table-column v-slot="props" field="code" label="CÓDIGO" sortable centered>
                            <b-button
                                class="btn-copy remove-focus"
                                :class="clipboard(props.row.code).clipboardClass"
                                @click="onCopyText(props.row.code)"
                            >
                                {{ props.row.code }}

                                <b-icon :icon="clipboard(props.row.code).clipboardIcon"></b-icon>
                            </b-button>
                        </b-table-column>

                        <b-table-column v-slot="props" field="value" label="VALOR" sortable centered>
                            {{ valueFormat(props.row.value, props.row.discount_type) }}
                        </b-table-column>

                        <b-table-column
                            v-slot="props"
                            field="due_date"
                            label="DATA DE EXPIRAÇÃO"
                            sortable
                            centered
                        >
                            {{ props.row.due_date | date("DD/MM/YYYY HH:mm") }}
                        </b-table-column>

                        <b-table-column
                            v-slot="props"
                            field="quantity_of_use"
                            label="QUANTIDADES DE USOS"
                            sortable
                            centered
                        >
                            {{ props.row.quantity_of_use }}
                        </b-table-column>

                        <b-table-column
                            v-slot="props"
                            field="quantity_used"
                            label="QUANTIDADES USADAS"
                            sortable
                            centered
                        >
                            {{ props.row.quantity_used }}
                        </b-table-column>

                        <b-table-column v-slot="props" label="Tipo de desconto" sortable centered>
                            <b-badge variant="success">
                                {{ props.row.discount_type_label.label || "" }}
                            </b-badge>
                        </b-table-column>

                        <b-table-column v-slot="props" label="OPÇÕES" centered>
                            <router-link
                                :to="{
                                    name: 'discount.save',
                                    params: { id: props.row.id },
                                }"
                            >
                                <b-tooltip label="Editar" class="m-1">
                                    <b-button type="is-primary" size="is-small" icon-right="edit" />
                                </b-tooltip>
                            </router-link>

                            <b-tooltip label="Deletar" class="m-1">
                                <b-button
                                    @click="onClickDelete(props.row.id)"
                                    type="is-primary"
                                    size="is-small"
                                    icon-right="trash"
                                />
                            </b-tooltip>
                        </b-table-column>

                        <template slot="empty">
                            <section class="section">
                                <div class="content has-text-grey has-text-centered">
                                    <p>
                                        <b-icon icon="frown" size="is-small"></b-icon>
                                    </p>
                                    <p>Nenhum registro encontrado.</p>
                                </div>
                            </section>
                        </template>

                        <template #footer v-if="total > 0">
                            <div class="row align-middle text-center">
                                <div class="col-5 col-lg-5 col-md-5 col-sm-3 ptext text-right">
                                    Exibindo {{ rows }} de {{ total }}
                                </div>

                                <div class="col-auto text-center">
                                    <b-pagination
                                        v-model="page"
                                        :total-total="total"
                                        :per-page="perPage"
                                        first-number
                                        last-number
                                        aria-controls="table-discounts"
                                    >
                                    </b-pagination>
                                </div>

                                <div class="col-auto text-center">
                                    <b-select class="pselect" v-model="perPage" style="margin-left: 10px">
                                        <b-select-option v-for="i in perPageOptions" :key="i" :value="i">
                                            <b>{{ i }}</b>
                                        </b-select-option>
                                    </b-select>
                                </div>
                            </div>
                        </template>
                    </b-table>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import debounce from "lodash/debounce";

import DiscountTypeEnum from "@/constants/discount_type.constant";

import DiscountService from "@/services/discount.service";

export default {
    mounted() {
        this.loadDiscounts();
        this.loadDiscountsLabels();
    },

    data: () => ({
        isLoading: false,
        data: [],
        page: 1,
        perPageOptions: [10, 20, 40, 80],
        perPage: 10,
        total: 0,
        clipboards: [],
        searchTerm: "",
        discount_types: [],
        discount_type: -1,
    }),

    computed: {
        discounts_list() {
            return this.data;
        },

        rows() {
            return this.data.length;
        }
    },

    watch: {
        searchTerm: debounce(function () {
            this.page = 1;

            this.loadDiscounts();
        }, 1000),

        discount_type() {
            this.loadDiscounts();
        },

        page() {
            if (this.data.length > 0 && this.data.length < this.total) this.loadDiscounts();
        },

        perPage() {
            this.loadDiscounts();
        },
    },

    methods: {
        loadDiscounts() {
            this.isLoading = true;

            const params = this.filters();

            DiscountService.get(params)
                .then(({ data }) => {
                    const { total, data: items, current_page } = data;

                    this.page = current_page;
                    this.total = total;
                    this.data = items ?? [];
                    this.clipboards = this.data.map(({ code }) => ({
                        code,
                        clipboardIcon: "clipboard",
                        clipboardClass: "clipboard-default",
                    }));

                    // this.pages = this.perPage < this.total ? parseInt(this.total / this.perPage) : 1;
                })
                .catch(() => this.toast("Erro inesperado  ao tentar carregar os descontos", "is-danger"))
                .finally(() => (this.isLoading = false));
        },

        loadDiscountsLabels() {
            DiscountService.getLabels().then(({ data }) => (this.discount_types = data));
        },

        filters() {
            const params = {
                page: this.page,
                perPage: this.perPage,
                period: this.selectedPeriod,
                "filter[not_voucher]": true,
            };

            this.searchTerm && (params["filter[search]"] = this.searchTerm);

            [1, 2].includes(this.discount_type) && (params["filter[discount_type]"] = this.discount_type);

            return params;
        },

        async onCopyText(code) {
            const clip = this.clipboard(code);

            try {
                await window.navigator.clipboard.writeText(code);

                clip.clipboardClass = "clipboard-success";
                clip.clipboardIcon = "clipboard-check";

                this.toast("Código copiado para área de transferência", "is-success");
            } catch (_) {
                console.log(_);
                clip.clipboardClass = "clipboard-error";
                clip.clipboardIcon = "clipboard-x";
            }

            setTimeout(() => {
                clip.clipboardClass = "clipboard-default";
                clip.clipboardIcon = "clipboard";
            }, 3000);
        },

        onClickDelete(id) {
            this.$buefy.dialog.confirm({
                title: "Excluir Desconto",
                message:
                    "Tem certeza que deseja <b>excluir</b> este desconto? Essa ação não pode ser desfeita.",
                cancelText: "Cancelar",
                confirmText: "Excluir",
                type: "is-danger",
                hasIcon: true,
                onConfirm: () => this.delete(id),
            });
        },

        clipboard(code) {
            return this.clipboards.find((x) => x.code == code) ?? {};
        },

        delete(id) {
            DiscountService.delete(id)
                .then(() => {
                    this.toast("Desconto excluído com sucesso", "is-success");

                    this.loadDiscounts();
                })
                .catch(() => this.toast("Ocorreu um erro ao deletar o desconto", "is-danger"));
        },

        valueFormat(value, type) {
            if (!value || !type) return "";

            if (DiscountTypeEnum.MONEY == type)
                return parseFloat(value).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                });

            if (DiscountTypeEnum.PERCENTAGE == type) return `${value}%`;
        },
    },
};
</script>

<style lang="scss" scoped>
.table-custom {
    height: auto !important;

    & table tbody tr {
        &:hover {
            cursor: pointer;
            background-color: #e6e6e6;
        }
    }

    .btn-copy {
        background: transparent !important;

        &,
        &:focus:not(:active) {
            border: 0 !important;
            box-shadow: 0 !important;
        }
    }

    .clipboard-success {
        color: var(--success);
    }

    .clipboard-error {
        color: var(--danger);
    }
}
</style>
