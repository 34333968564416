<template>
  <div class="card-title-cadastro values">
    <div v-for="value of values" :key="value.id" class="value">
      <b-button
        :type="isValueSelected(value.id) ? 'is-primary' : 'is-secondary'"
        :outlined="isValueSelected(value.id) ? false : true"
        @click="onValueClick(value.id)"
        expanded
        rounded
        >{{ value.name }}</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    getValues: Function,
    ruleValues: {
      type: Array,
      default: () => [],
    },
    shouldBeUnique: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    values: [],
    selectedValues: [],
  }),
  methods: {
    onValueClick(value) {
      if (this.shouldBeUnique) {
        this.selectedValues = [value];
      } else {
        const idx = this.selectedValues.indexOf(value);
        if (idx >= 0) {
          this.selectedValues.splice(idx, 1);
        } else {
          this.selectedValues.push(value);
        }
      }
      this.$emit("onSelectValue", this.selectedValues);
    },
    isValueSelected(value) {
      return this.selectedValues.indexOf(value) >= 0;
    },
  },
  mounted() {
    this.selectedValues = this.ruleValues;
    this.getValues().then(({ data }) => (this.values = data));
  },
};
</script>

<style lang="scss" scoped>
.values {
  margin-top: 2rem;
  flex-wrap: wrap;
  display: flex;
  gap: 10px;

  width: 100%;
}
</style>
