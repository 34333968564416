import api from './api.service';
import Api from './api.service';

export default {
  get: (term = '') =>
    Api.get(`appointment_types?page=${term.page}&filter[name]=${term.name}`),
  getAll: () => Api.get('appointment-types'),
  getId: (id) => Api.get(`appointment_types/${id}`),
  save: (data) => Api.post(`appointment_types/`, data),
  update: (id, data) => Api.put(`appointment-types/${id}`, data),
  updateMany: (data) => api.put('appointment-types-many', data),
  delete: (id) => Api.delete(`appointment_types/${id}`),
  export: (filters) => Api.post('appointment_types/export', { filters }),
};
