<template>
  <div class="app-file-picker">
    <input
      @change="onFileSelect"
      ref="fileInput"
      type="file"
      style="display: none"
      :accept="accept"
    />

    <label class="label">
      {{ label }}

      <span v-if="isRequired">*</span>
    </label>

    <div class="input-content">
      <div class="value">
        <span>{{ fileName }}</span>

        <button
          @click="deleteFile"
          v-if="fileName"
          type="button"
          class="remove-btn"
        >
          <b-icon size="is-small" icon="times"></b-icon>
        </button>
      </div>

      <button @click="onClick" type="button">
        <b-icon size="is-small" icon="upload"></b-icon>
      </button>
    </div>
  </div>
</template>

<script>
import FileMixin from "@/mixins/FileMixin";

export default {
  name: "app-file-picker",
  mixins: [FileMixin],
  model: {
    prop: "model",
    event: "input",
  },
  props: ["label", "model", "accept", "required", "disabled", "onlyFile"],
  data: () => {
    return {
      fileName: null,
      fileUrl: null,
    };
  },
  methods: {
    onClick() {
      !this.disabled && this.$refs.fileInput.click();
    },
    async onFileSelect(event) {
      const [file] = event.target.files;
      let url = null;

      console.log(this.onlyFile);

      if (this.onlyFile) url = file;
      else url = await this.fileToBase64(file);

      this.$refs.fileInput.value = null;
      this.fileName = file.name;
      this.updateModel(url);
      this.$emit("fileSelected", file);
    },
    deleteFile() {
      this.fileName = null;
      this.updateModel(null);
      this.$emit("fileDeleted");
    },
    updateModel(value) {
      this.$emit("input", value);
    },
  },
  computed: {
    isRequired() {
      return this.required === "" || this.required;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-file-picker {
  margin: 2px 0;

  label {
    display: block;
    font-size: 16px;
    margin-bottom: 0.5em;
    color: var(--color-dark);

    > span {
      color: var(--color-danger);
      font-size: 0.9em;
      position: relative;
      top: -3px;
      left: -2px;
    }
  }

  .input-content {
    display: flex;
    align-content: center;

    .value {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 0 75%;
      border: 1px solid #ddd;
      outline: 0;
      padding: 8px;
      border-radius: 4px 0 0 4px;
      height: 38px;
      font-size: 1em;
      color: #424242;

      .remove-btn {
        margin-top: 5px;
        color: var(--color-danger);
        border: none;
        background: transparent;
        outline: 0;
        font-size: 1.1em;
        cursor: pointer;
        transition: background 0.2s ease-in-out;
      }
    }

    button:not(.remove-btn) {
      flex: 0 25%;
      border: none;
      background: var(--color-primary);
      color: #fff;
      border-radius: 0 8px 8px 0;
      outline: 0;
      transition: background 0.2s ease-in-out;
      cursor: pointer;

      &:disabled {
        filter: contrast(0.5);
      }

      &:hover {
        filter: contrast(1.1);
      }

      .icon {
        font-size: 1.3em;
        margin-right: 4px;
      }
    }
  }
}
</style>
