import Api from "./api.service";

const prefix = "admins";

export default {
  get: (params) => Api.get(prefix, { params }),
  getId: (id) => Api.get(`${prefix}/${id}`),
  save: (data) => Api.post(`${prefix}/`, data),
  update: (id, data) => Api.put(`${prefix}/${id}`, data),
  delete: (id) => Api.delete(`${prefix}/${id}`),
  export: (params) => Api.get(`${prefix}/export`, { params }),
  resetPassword: (email) =>
    Api.get(`${prefix}/users/reset-password?email=${email}`),
  storePaymentRange: (data = {}) => Api.post("payment-ranges", data),
  getPaymentRanges: () => Api.get("payment-ranges"),
  deletePaymentRange: (id) => Api.delete(`payment-ranges/${id}`),
  rejectLawyer: (lawyerId, data = {}) =>
    Api.patch(`lawyers/${lawyerId}/reject-registration`, data),
  getGettingStartedText: () => Api.get("getting-started"),
  saveGettingStartedText: function (data = {}) {
    let method = Api.post;

    if (Object.keys(data).includes("id")) {
      method = Api.patch;
    }

    return method("getting-started", data);
  },
};
