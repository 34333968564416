import Clear from '@/layouts/Clear.vue';

export default {
  path: '/subscriptions',
  component: Clear,
  children: [
    {
      path: '',
      name: 'subscriptions.index',
      component: () => import('@/views/Subscriptions/SubscriptionsList.vue'),
    }
  ],
};
