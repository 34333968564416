<template>
  <div class="schedule-view">
    <app-header icon="calendar" title="Detalhe do atendimento" goes-back></app-header>
    <section>
      <div class="row mt-2">
        <div class="col-12 col-lg-12 col-md-12 col-sm-6">
          <form @submit.prevent>
          <div class="card">
            <div class="card-content">
              <div class="card-title">CONTRATANTES</div>
                <div class="row d-flex">
                  <div class="col-5 col-lg-5 col-md-5 col-sm-3 card-select">
                    <div class="card-select-title">Advogado</div>
                      <b-tooltip label="ver advogado">
                        <app-input
                          v-if="schedule.doctor.user"
                          name="name"
                          required
                          disabled
                          title="ver advogado"
                          v-model="schedule.doctor.name"
                          :icon-right-clickable="true"
                          icon-right="external-link-square-alt"
                          size="h1"
                          @icon-right-click="openUrl('/lawyers/'+schedule.doctor.id)">
                        </app-input>
                      </b-tooltip>
                      <b-tooltip label="ver advogado">
                        <b-button class="text-right" size="h2" type="is-primary" icon-right="external-link-square-alt" @click="openUrl('/lawyers/'+schedule.doctor.id)">
                          ver advogado
                        </b-button>
                      </b-tooltip>
                      <b-field v-if="schedule.doctor.specialties && schedule.doctor.specialties.length > 0" label=" " class="field-specialty">
                        <b-tag
                           v-for="specialty in schedule.doctor.specialties" v-bind:key="specialty.id"
                           type='is-primary'
                           >
                          {{specialty.name}}
                        </b-tag>
                      </b-field>
                    </div>
                    <div class="col-5 col-lg-5 col-md-5 col-sm-3 card-select">
                      <div class="card-select-title">Cliente</div>
                      <b-tooltip label="ver cliente">
                        <app-input
                          v-if="schedule.patient.user"
                          name="name"
                          required
                          v-model="schedule.patient.name"
                          disabled
                          :icon-right-clickable="true"
                          icon-right="external-link-square-alt"
                          size="h1"
                          @icon-right-click="openUrl('/clients/'+schedule.patient.id)">
                        </app-input>
                      </b-tooltip>
                      <b-tooltip label="ver cliente">
                        <b-field>
                          <b-button
                              size="h1"
                              type="is-primary"
                              icon-right="external-link-square-alt"
                              @click="openUrl('/clients/'+schedule.patient.id)"
                          >
                            ver cliente
                          </b-button>
                        </b-field>
                      </b-tooltip>
                    </div>
                  </div>
                  <div class="card-title">DADOS DO ATENDIMENTO</div>
                  <div class="row d-flex">
                    <div class="col-2 col-lg-2 col-md-2 col-sm-1 card-select">
                      <div class="card-select-title">Data de Criação</div>
                      <span class="span-data" style="margin-top: 5px; margin-left: 10px;">{{schedule.created_at}}</span>
                    </div>
                    <div class="col-2 col-lg-2 col-md-2 col-sm-1 card-select">
                      <div class="card-select-title">Data de Início</div>
                      <span class="span-data" style="margin-top: 5px; margin-left: 10px;">{{schedule.start}}</span>
                    </div>
                    <div class="col-2 col-lg-2 col-md-2 col-sm-1 card-select">
                      <div class="card-select-title">Encaixe</div>
                      <span class="span-data" style="margin-top: 5px; margin-left: 10px;">
                        <b-checkbox
                          v-model="schedule.fit"
                          class="primary"
                          disabled
                          >Encaixe
                        </b-checkbox>
                      </span>
                    </div>
                    <div class="col-2 col-lg-2 col-md-2 col-sm-1 card-select">
                      <div class="card-select-title">Interno</div>
                      <span class="span-data" style="margin-top: 5px; margin-left: 10px;">
                        <b-checkbox
                          v-model="schedule.internal"
                          class="primary"
                          disabled
                          >Interno
                        </b-checkbox>
                      </span>
                    </div>
                    <div class="col-2 col-lg-2 col-md-2 col-sm-1 card-select">
                      <div class="card-select-title">Tipo de Atendimento</div>
                      <span class="span-data" style="margin-top: 5px; margin-left: 10px;">{{schedule.appointment_types.name}}</span>
                    </div>
                  </div>
                  <div class="card-title">FINANCEIRO</div>
                  <div class="row d-flex">
                    <div class="col-2 col-lg-2 col-md-2 col-sm-1 card-select">
                      <div class="card-select-title">Valor</div>
                      <span class="span-data" style="margin-top: 5px; margin-left: 10px;">{{schedule.value}}</span>
                    </div>
                    <div class="col-2 col-lg-2 col-md-2 col-sm-1 card-select">
                      <div class="card-select-title">Taxa</div>
                      <span class="span-data" style="margin-top: 5px; margin-left: 10px;">{{schedule.value_fee}}</span>
                    </div>
                    <div class="col-2 col-lg-2 col-md-2 col-sm-1 card-select">
                      <div class="card-select-title">Tipo de Pagamento</div>
                      <span class="span-data" style="margin-top: 5px; margin-left: 10px;">{{schedule.forma_pagamento.name}}</span>
                    </div>
                    <div class="col-2 col-lg-2 col-md-2 col-sm-1 card-select">
                      <div class="card-select-title">Status de Pagamento</div>
                      <span class="span-data" style="margin-top: 5px; margin-left: 10px;">{{schedule.payment_status.label}}</span>
                    </div>
                  </div>
              </div>
            </div>
            </form>
        </div>
      </div>
      </section>
    </div>
</template>

<script>
import SchedulesService from '@/services/schedules.service.js';
import StatesService from '@/services/states.service';
import CitiesService from '@/services/cities.service';
import MASKS from '@/constants/masks.constant';
import * as moment from 'moment';

export default {
  data: () => ({
    errors: {},
    MASKS: MASKS,
    isLoading: true,
    isLoadingCity: false,
    title: '',
    schedule: {
      doctor: { user: {} },
      patient: { user: {} },
      appointment: { appointment_type: {} },
    },
    schedules: [],
    states: [],
    cities: [],
    state_id: null,
    city_id: null,
    enableSeconds: false,
    hourFormat: '24',
  }),
  validations: {},
  methods: {
    statesGetter: StatesService.search,
    citiesGetter: CitiesService.search,
    openUrl(url){
      this.$router.push(url);
    },
    loadSchedule(id) {
      this.isLoading = true;
      if (typeof id != 'undefined') {
        SchedulesService.getId(id)
          .then(({ data: data }) => {
            this.schedule = data;
            this.schedule.created_at =
              this.schedule.created_at != null
                ? moment(this.schedule.created_at).format('DD/MM/YYYY')
                : null;
            this.schedule.start =
              this.schedule.start != null
                ? moment(this.schedule.start).format('DD/MM/YYYY HH:MM')
                : null;
            this.schedule.end =
              this.schedule.end != null
                ? moment(this.schedule.end).format('DD/MM/YYYY')
                : null;
            this.schedule.value =
              this.schedule.value != null
                ? 'R$ ' +
                  parseFloat(this.schedule.value)
                    .toFixed(2)
                    .replace(',', '.')
                    .replace('.', ',')
                : '0,00';
            this.schedule.value_fee =
              this.schedule.value_fee != null
                ? 'R$ ' +
                  parseFloat(this.schedule.value_fee)
                    .toFixed(2)
                    .replace(',', '.')
                    .replace('.', ',')
                : '0,00';
          })
          .finally(() => (this.isLoading = false));
      }

      this.isLoading = false;
    },
  },

  mounted() {
    const id = this.$route.params.id;
    this.id = this.$route.params.id;
    this.loadSchedule(id);
  },
};
</script>

<style lang="scss" scoped>
.schedule {
  //display: flex;
  .card-select {
    margin-left: 15px !important;
  }

  &__image {
    margin-right: 24px;
    border: 1px solid #f5f5f5;
    overflow: hidden;
    height: 120px;
    width: 100%;
    height: 100%;
    text-align: center;

    img {
      object-fit: cover;
    }
  }

  &__info {
    h2 {
      font-size: 1.4rem;
    }

    .links {
      margin-top: 8px;

      .button:not(:first-child) {
        margin-left: 8px;
      }
    }
  }
}
.field-specialty{
  margin-bottom: 0.25rem;
}
.checkbox {
  color: primary;
}
</style>
