import Api from "./api.service";

const ENDPOINT = "lawyers";

export default {
    getRankRules: () => {
        return Api.get(`${ENDPOINT}/rank/rules`);
    },
    updateRankRules: (data = {}) => {
        return Api.put(`${ENDPOINT}/rank/updateRules`, data);
    },
    getAverageDaysOptions: () => {
        return new Promise((resolve) => {
            resolve({
                data: [
                    {
                        id: 5,
                        name: "5 dias",
                    },
                    {
                        id: 10,
                        name: "10 dias",
                    },
                    {
                        id: 15,
                        name: "15 dias",
                    },
                    {
                        id: 20,
                        name: "20 dias",
                    },
                    {
                        id: 30,
                        name: "30 dias",
                    },
                    {
                        id: 60,
                        name: "60 dias",
                    },
                    {
                        id: 90,
                        name: "90 dias",
                    },
                    {
                        id: 120,
                        name: "120 dias",
                    },
                    {
                        id: 365,
                        name: "1 ano",
                    },
                    {
                        id: 730,
                        name: "2 anos",
                    },
                ],
            });
        });
    },
};
