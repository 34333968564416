import Vue from "vue";
import VueMask from "v-mask";
import Vuelidate from "vuelidate";
import PictureInput from "vue-picture-input";
import ToggleButton from "vue-js-toggle-button";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueTooltip from "vue-directive-tooltip";

import GlobalMixins from "./config/AppMixinsConfig";

import "./config/AppComponentsConfig";
import "./config/AppDirectivesConfig";
import "./config/AppFiltersConfig";
import "./config/FontAwesomeConfig";
import "./config/MomentConfig";
import "./config/BuefyConfig";
import "./config/BootstrapConfig";
import "vue-directive-tooltip/dist/vueDirectiveTooltip.css";

Vue.component("picture-input", PictureInput);

Vue.use(ToggleButton);
Vue.use(VueTooltip);
Vue.use(require("vue-moment"));
Vue.use(VueMask);
Vue.use(Vuelidate);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    mixins: GlobalMixins,
    render: (h) => h(App),
}).$mount("#app");
