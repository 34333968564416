<template>
    <div class="specialties-list-view">
        <app-header icon="balance-scale" title="Especialidades"></app-header>

        <section>
            <div class="row mt-2 justify-content-around align-items-middle">
                <div class="col-12 col-lg-12 col-md-12 col-sm-6x">
                    <div class="card">
                        <div class="card-content">
                            <div class="card-title">FILTROS</div>
                            <div class="row d-flex">
                                <div class="col-3 col-lg-3 col-md-3 col-sm-2 card-select">
                                    <div class="card-select-title">Especialidade</div>
                                    <app-input
                                        v-model="searchName"
                                        placeholder="Nome"
                                        icon="search"
                                        type="is-primary"
                                    >
                                    </app-input>
                                </div>
                                <div class="col-5 col-lg-5 col-md-5 col-sm-2">
                                    <b-tooltip label="NOVA ESPECIALIDADE">
                                        <router-link
                                            :to="{
                                                name: 'specialties.save',
                                            }"
                                        >
                                            <b-button
                                                type="is-primary"
                                                icon-right="plus"
                                                style="margin-top: 10px; margin-right: 15px"
                                                >NOVO</b-button
                                            >
                                        </router-link>
                                    </b-tooltip>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <b-tooltip label="EXPORTAR XLS">
                                        <b-button
                                            @click="exportSpecialties()"
                                            type="is-primary"
                                            icon-right="file-excel"
                                            :disabled="isExporting"
                                            :loading="isExporting"
                                            style="margin-top: 10px; margin-right: 15px"
                                        >
                                            EXPORTAR XLS
                                        </b-button>
                                    </b-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-lg-12 col-md-12 col-sm-6">
                    <b-table
                        id="table-specialties"
                        class="table-custom"
                        :data="specialties_list"
                        :per-page="perPage"
                        :current-page.sync="page"
                        :total="total"
                        :loading="isLoading"
                        :paginated="false"
                        :pagination-simple="false"
                        pagination-position="both"
                        default-sort-direction="asc"
                        :pagination-rounded="false"
                        sort-icon="arrow-down"
                        sort-icon-size="is-small"
                        default-sort="row.name"
                        aria-next-label="Próxima Página"
                        aria-previous-label="Página Anterior"
                        aria-page-label="Página"
                        aria-current-label="Página Atual"
                    >
                        <b-table-column width="80%" v-slot="props" field="name" sortable label="NOME">
                            {{ props.row.name }}
                        </b-table-column>

                        <b-table-column width="20%" v-slot="props" label="OPÇÕES" centered>
                            <router-link
                                :to="{
                                    name: 'specialties.save',
                                    params: { id: props.row.id },
                                }"
                            >
                                <b-tooltip label="Editar" class="m-1">
                                    <b-button type="is-primary" size="is-small" icon-right="edit" />
                                </b-tooltip>
                            </router-link>
                            <b-tooltip label="Deletar" class="m-1">
                                <b-button
                                    @click="onClickDelete(props.row.id)"
                                    type="is-primary"
                                    size="is-small"
                                    icon-right="trash"
                                />
                            </b-tooltip>
                        </b-table-column>

                        <template slot="empty">
                            <section class="section">
                                <div class="content has-text-grey has-text-centered">
                                    <p>
                                        <b-icon icon="frown" size="is-large"></b-icon>
                                    </p>
                                    <p>Nenhum registro encontrado.</p>
                                </div>
                            </section>
                        </template>

                        <template #footer v-if="total > 0">
                            <div class="row align-middle text-center">
                                <div class="col-5 col-lg-5 col-md-5 col-sm-3 ptext text-right">
                                    Exibindo {{ rows }} de {{ total }}
                                </div>

                                <div class="col-auto text-center">
                                    <b-pagination
                                        v-model="page"
                                        :total-total="total"
                                        :per-page="perPage"
                                        first-number
                                        last-number
                                        aria-controls="table-specialties"
                                    >
                                    </b-pagination>
                                </div>

                                <div class="col-auto text-center">
                                    <b-select class="pselect" v-model="perPage" style="margin-left: 10px">
                                        <b-select-option v-for="i in perPageOptions" :key="i" :value="i">
                                            <b>{{ i }}</b>
                                        </b-select-option>
                                    </b-select>
                                </div>
                            </div>
                        </template>
                    </b-table>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import SpecialtiesService from "@/services/specialties.service";
import debounce from "lodash/debounce";

export default {
    mounted() {
        this.load();
    },

    data: () => ({
        isExporting: false,
        isLoading: true,
        data: [],
        page: 1,
        perPageOptions: [10, 20, 40, 80],
        perPage: 10,
        total: 0,
        searchName: "",
        title: "",
    }),

    computed: {
        specialties_list() {
            return this.data;
        },

        rows() {
            return this.data.length;
        },
    },

    watch: {
        searchName: debounce(function () {
            this.page = 1;
            this.load();
        }, 400),

        page() {
            if (this.data.length > 0 && this.data.length < this.total) this.load();
        },

        perPage() {
            this.load();
        },
    },

    methods: {
        load() {
            this.isLoading = true;

            SpecialtiesService.get({
                page: this.page,
                perPage: this.perPage,
                "filter[name]": this.searchName.trim(),
            })
                .then(({ data }) => {
                    const { total, data: items, current_page } = data;
                    this.page = current_page;
                    this.data = items;
                    this.total = total;
                })
                .finally(() => setTimeout(() => (this.isLoading = false), 300));
        },

        onClickDelete(id) {
            this.$buefy.dialog.confirm({
                title: "Excluindo Especialidade",
                message:
                    "Tem certeza que deseja <b>excluir</b> esta especialidade? Essa ação não pode ser desfeita.",
                cancelText: "Cancelar",
                confirmText: "Excluir Especialidade",
                type: "is-primary",
                hasIcon: true,
                onConfirm: () => this.delete(id),
            });
        },

        delete(id) {
            SpecialtiesService.delete(id)
                .then(() => {
                    this.$buefy.snackbar.open("Especialidade excluída com sucesso.");
                    this.load();
                })
                .catch(({ response }) => {
                    if (typeof response != "undefined" && response.status != 200) {
                        this.$buefy.snackbar.open(
                            response.message || "Erro ao tentar excluir a Especialidade.",
                            "error",
                            4000
                        );
                    }
                });
        },

        exportSpecialties() {
            this.isExporting = true;

            let filters = {
                name: this.searchName.trim() || "",
            };

            SpecialtiesService.export({ ...filters })
                .then(({ data }) => {
                    const fileName = data.split("/")[data.split("/").length - 1];
                    const fileLink = document.createElement("a");

                    fileLink.target = "_blank";
                    fileLink.href = data;
                    fileLink.setAttribute("download", fileName);
                    fileLink.setAttribute("style", "display:none;");

                    document.body.appendChild(fileLink);
                    fileLink.click();

                    fileLink.remove();
                })
                .catch(({ response }) => {
                    const message = response.data.message || "Erro ao realizar exportação.";
                    this.$buefy.snackbar.open(message);
                })
                .finally(() => (this.isExporting = false));
        },
    },
};
</script>

<style lang="scss"></style>
