import Clear from '@/layouts/Clear.vue';

export default {
  path: '/values',
  component: Clear,
  children: [
    {
      path: '',
      name: 'values.index',
      component: () => import('@/views/Values/Values.vue'),
    },
  ],
};
