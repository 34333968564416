<template>
    <div class="specialty-view">
        <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>
        <app-header icon="book" title="INCLUIR ESPECIALIDADE"  goes-back></app-header>

        <section>
            <form v-if="!isLoading" @submit.prevent="">
                <div class="container-cadastro">
                    <div class="row">
                        <div class="col-8 col-md-8 col-sm-4">
                            <div class="card cadastro">
                                <div class="card-body">
                                    <div class="card-title-cadastro">Nome</div>
                                    <input
                                        class="input-cadastro"
                                        type="text"
                                        name="name"
                                        required
                                        v-model="specialty.name"
                                        :errors="errors.name"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div
                            class="col-8 col-md-8 col-sm-4 button-container d-flex justify-content-end"
                            style="padding-right: 15px"
                        >
                            <b-button
                                type="submit"
                                :loading="isSaving"
                                :disabled="isSaving"
                                class="btn-success button-rounded text-white remove-focus border-0 px-3"
                                @click="onSubmit"
                            >
                                Salvar
                            </b-button>
                        </div>
                    </div>
                </div>
            </form>
        </section>
    </div>
</template>

<script>
import SpecialtiesService from "@/services/specialties.service.js";
import { required } from "vuelidate/lib/validators";

export default {
    data: () => ({
        errors: {},
        isLoading: false,
        isSaving: false,
        title: "",
        specialty: {},
        specialties: [],
    }),

    validations: {
        specialty: {
            name: {
                required,
            },
        },
    },

    mounted() {
        this.id = this.$route.params.id;

        this.id && this.loadSpecialty();
    },

    methods: {
        loadSpecialty() {
            this.isLoading = true;

            SpecialtiesService.getId(this.id)
                .then(({ data }) => (this.specialty = data))
                .finally(() => (this.isLoading = false));
        },

        goBack() {
            this.$router.go(-1);
        },

        onSubmit() {
            this.errors = {};
            !this.id && this.save(this.specialty);
            this.id && this.update(this.id, this.specialty);
        },

        save(data) {
            this.isSaving = true;
            SpecialtiesService.save(data)
                .then(() => {
                    this.$buefy.snackbar.open("Especialidade cadastrada com sucesso!");
                    this.$router.push({ name: "specialties.index" });
                })
                .catch(({ response }) => {
                    const message = response.data.message;
                    this.$buefy.snackbar.open(
                        typeof message === "string" ? message : "Erro ao tentar atualizar a Especialidade.",
                        "error"
                    );
                    this.errors = response.data.message;
                })
                .finally(() => (this.isSaving = false));
        },
        update(id, data) {
            this.isSaving = true;
            SpecialtiesService.update(id, data)
                .then(() => {
                    this.$buefy.snackbar.open("Especialidade atualizada com sucesso!");
                    this.$router.push({ name: "specialties.index" });
                })
                .catch(({ response }) => {
                    const message = response.data.message;
                    this.$buefy.snackbar.open(
                        typeof message === "string" ? message : "Erro ao tentar atualizar a Especialidade.",
                        "error"
                    );
                    this.errors = response.data.message;
                })
                .finally(() => (this.isSaving = false));
        },
    },
};
</script>

<style lang="scss" scoped>
.btn-success {
    background-color: #28a745 !important;
}

.specialty {
    display: flex;

    &__image {
        margin-right: 24px;
        border: 1px solid #f5f5f5;
        border-radius: 50%;
        overflow: hidden;
        height: 120px;
        width: 120px;

        img {
            object-fit: cover;
        }
    }

    &__info {
        h2 {
            font-size: 1.4rem;
        }

        .links {
            margin-top: 8px;

            .button:not(:first-child) {
                margin-left: 8px;
            }
        }
    }
}
</style>
