<template>
  <section class="getting-started-view">
    <div class="row mt-4">
      <div class="col-12">
        <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>

        <div class="card" style="background: #fff">
          <div class="card-header">
            <div
              class="card-header-title"
              style="color: #1a1a1a; text-transform: uppercase"
            >
              Primeiros Passos
            </div>
            <div class="card-header-buttons">
              <b-tooltip label="Salvar alterações">
                <b-button
                  @click="onSubmit"
                  class="ml-4"
                  variant="light"
                  type="is-primary"
                  size="is-small"
                  icon-left="save"
                  :loading="isLoading"
                  :disabled="!gettingStarted.content"
                  >Salvar</b-button
                >
              </b-tooltip>
            </div>
          </div>
          <div class="card-content">
            <div class="row mb-4">
              <div class="col-12">
                <b-button
                  v-for="(button, idx) of availablesButtons"
                  @click="setEditorValue(button.value)"
                  :class="button.customClass"
                  type="is-primary"
                  :key="idx"
                  outlined
                  rounded
                >
                  {{ button.label }}
                </b-button>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-12">
                <editor
                  ref="editor"
                  :api-key="editorApiKey"
                  v-model="gettingStarted.content"
                  :init="{
                    height: 300,
                    paste_as_text: true,
                  }"
                ></editor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AdminsService from "@/services/admins.service.js";

import Editor from "@tinymce/tinymce-vue";

import { getTinymce } from "@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE";

export default {
  components: {
    editor: Editor,
  },
  data: () => ({
    editorApiKey: process.env.VUE_APP_TINY_MCE_KEY,
    isLoading: false,
    errors: {},
    gettingStarted: {
      id: null,
      content: null,
    },
    availablesButtons: [
      {
        label: "Destaque",
        value: "[detail][/detail]",
        customClass: null,
      },
      {
        label: "Destaque Centralizado",
        value: "[centered_detail][/centered_detail]",
        customClass: "ml-2",
      },
    ],
  }),

  methods: {
    onFieldClick(value) {
      this.setEditorValue(value);
      this.setEditorCursorPosition();
    },
    setEditorValue(value) {
      getTinymce().activeEditor.selection.setContent(value);
    },
    setEditorCursorPosition() {
      const bookmark = getTinymce().activeEditor.selection.getBookmark();
      getTinymce().activeEditor.selection.moveToBookmark(bookmark);
      getTinymce().activeEditor.focus();
    },
    loadGettingStarted() {
      this.isLoading = true;

      AdminsService.getGettingStartedText()
        .then(({ data }) => (this.gettingStarted = data))
        .finally(() => (this.isLoading = false));
    },
    onSubmit() {
      this.isLoading = true;
      this.errors = {};

      AdminsService.saveGettingStartedText({ ...this.gettingStarted })
        .then(({ data }) =>
          this.$buefy.snackbar.open({
            message: data.message,
            type: "is-success",
          })
        )
        .catch(() =>
          this.$buefy.snackbar.open({
            message: "Um erro inesperado aconteceu!",
          })
        )
        .finally(() => (this.isLoading = false));
    },
  },

  mounted() {
    this.loadGettingStarted();
  },
};
</script>

<style>
#sel_term {
  height: auto;
  font-size: 14px;
}
</style>
