import Clear from "@/layouts/Clear.vue";

export default {
  path: "/subscription-plans",
  component: Clear,
  children: [
    {
      path: "",
      name: "subscription-plans.index",
      component: () =>
        import("@/views/SubscriptionPlans/SubscriptionPlansList.vue"),
    },
    {
      path: ":id?",
      name: "subscription-plans.form",
      component: () => import("@/views/SubscriptionPlans/SubscriptionPlan.vue"),
    },
  ],
};
