<template>
  <section>
    <b-table
      :data="data"
      :paginated="false"
      :loading.sync="isLoading"
      :pagination-simple="false"
      :pagination-rounded="false"
      id="table-subscription-plans"
      aria-page-label="Página"
      aria-next-label="Próxima Página"
      aria-current-label="Página Atual"
      aria-previous-label="Página Anterior"
    >
      <b-table-column v-slot="props" field="name" label="Nome">
        <p>{{ props.row.name }}</p>
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="schedules_limit"
        label="Créditos por mês"
      >
        <p>{{ props.row.schedules_limit }}</p>
      </b-table-column>

      <b-table-column v-slot="props" field="discount" label="Desconto mensal">
        <p>{{ props.row.monthly_discount }}%</p>
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="discount"
        label="Desconto trimestral"
      >
        <p>{{ props.row.quarter_discount }}%</p>
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="discount"
        label="Desconto semestral"
      >
        <p>{{ props.row.semester_discount }}%</p>
      </b-table-column>

      <b-table-column v-slot="props" field="discount" label="Desconto anual">
        <p>{{ props.row.yearly_discount }}%</p>
      </b-table-column>

      <b-table-column v-slot="props" label="Opções" width="110">
        <b-tooltip label="Editar" class="m-1">
          <router-link
            :to="{
              name: 'subscription-plans.form',
              params: { id: props.row.id },
            }"
          >
            <b-button type="is-primary" size="is-small" icon-right="eye" />
          </router-link>
        </b-tooltip>

        <b-tooltip label="Excluir" class="m-1">
          <b-button
            @click="onDeletePlanClick(props.row.id)"
            type="is-danger"
            size="is-small"
            icon-right="trash"
          />
        </b-tooltip>
      </b-table-column>

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>Nenhum registro encontrado.</p>
          </div>
        </section>
      </template>
    </b-table>
  </section>
</template>

<script>
import SubscriptionsService from "@/services/subscriptions.service";

export default {
  name: "subscription-plans-list",
  data: () => ({
    data: [],
    isLoading: false,
  }),
  methods: {
    getSubscriptionPlans() {
      this.isLoading = true;

      const params = {
        page: this.page,
        perPage: this.perPage,
      };

      SubscriptionsService.getPlans(params)
        .then(({ data }) => {
          this.data = data;
        })
        .catch(() => {
          this.$buefy.snackbar.open("Erro ao carregar planos de assinaturas!");
        })
        .finally(() => (this.isLoading = false));
    },
    onDeletePlanClick(planId) {
      this.$buefy.dialog.confirm({
        title: "Excluindo plano",
        message:
          "Tem certeza que deseja <b>excluir</b> este plano? Essa ação não poderá ser desfeita.",
        cancelText: "Cancelar",
        confirmText: "Excluir Plano",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deletePlan(planId),
      });
    },
    deletePlan(planId) {
      this.isLoading = true;

      SubscriptionsService.deletePlan(planId)
        .then(() => {
          this.$buefy.snackbar.open("Plano excluído com sucesso!");
          this.getSubscriptionPlans();
        })
        .catch(() => {
          this.$buefy.snackbar.open("Erro ao excluír plano!");
        })
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.isLoading = false;
    this.getSubscriptionPlans();
  },
};
</script>

<style></style>
