<template>
  <div class="cancellation-request-modal">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Solicitação de cancelamento</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div class="row mt-2">
          <div class="col-12">
            <b-notification
              has-icon
              role="alert"
              type="is-warning"
              :closable="false"
            >
              <p>Motivo:</p>
              <p>{{ cancellation_request.reason_description }}</p>
            </b-notification>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-12">
            <b-notification
              has-icon
              icon="comment"
              role="alert"
              type="is-dark"
              :closable="false"
            >
              <p>Observação:</p>
              <p>{{ cancellation_request.observation }}</p>
            </b-notification>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-12">
            <div class="card-select-title mb-2">Ação a ser tomada:</div>

            <b-field>
              <b-select v-model="selectedAction" placeholder="Selecione...">
                <b-select-option :value="null">Selecionar</b-select-option>

                <b-select-option
                  v-for="(action, idx) in actions"
                  :value="action.value"
                  :key="idx"
                  >{{ action.label }}</b-select-option
                >
              </b-select>
            </b-field>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button
          @click="save()"
          :loading="isLoading"
          :disabled="isDisabled || isLoading"
          type="is-primary"
          expanded
        >
          Salvar
        </b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import CancellationRequestService from "@/services/cancellation-request.service";
export default {
  components: {},
  props: {
    cancellation_request: {
      type: Object,
      default: () => ({
        observation: null,
        subscription_id: null,
        reason_description: null,
      }),
    },
    lawyer_id: {
      type: Number,
      default: () => null,
    },
  },
  data: () => ({
    isLoading: false,
    selectedAction: null,
    actions: [
      { value: "reversed", label: "Reverter" },
      { value: "cancel", label: "Confirmar cancelamento" },
    ],
  }),
  computed: {
    isDisabled() {
      return !this.selectedAction;
    },
  },
  methods: {
    save() {
      this.isLoading = true;

      CancellationRequestService.updateCancellationRequest(this.lawyer_id, {
        action: this.selectedAction,
        subscription_id: this.cancellation_request.subscription_id,
      })
        .then(({ data }) => {
          const { message } = data;
          this.$buefy.snackbar.open(message);
          this.$emit("close", true);
        })
        .catch(() =>
          this.$buefy.snackbar.open(
            "Erro ao atualizar solicitação, tente novamente"
          )
        )
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
