<template>
    <div class="appointment-view">
        <app-header icon="user-cog" title="Incluir Administrador" goes-back></app-header>

        <section>
            <form v-if="!isLoading" @submit.prevent="onSubmit()">
                <div class="container-cadastro">
                    <div class="title_cadastro">DADOS DO ADMINISTRADOR</div>
                    <div class="row">
                        <div class="col-7 col-md-7 col-sm-3">
                            <div class="card cadastro">
                                <div class="card-body">
                                    <b-field for="name" class="card-title-cadastro mb-0">Nome</b-field>

                                    <input
                                        class="input-cadastro"
                                        type="text"
                                        name="name"
                                        required
                                        v-model="admin.name"
                                        :errors="errors.name"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-7 col-md-7 col-sm-3">
                            <div class="card cadastro">
                                <div class="card-body">
                                    <b-field for="email" class="card-title-cadastro mb-0">E-mail</b-field>

                                    <input
                                        class="input-cadastro"
                                        type="mail"
                                        name="email"
                                        required
                                        v-model="admin.user.email"
                                        :errors="errors.email"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-7 col-md-7 col-sm-3">
                            <div class="card cadastro">
                                <div class="card-body">
                                    <b-field for="password" class="card-title-cadastro mb-0">Senha</b-field>

                                    <input
                                        class="input-cadastro"
                                        type="password"
                                        name="password"
                                        minlength="6"
                                        required
                                        v-model="admin.user.password"
                                        :errors="errors.password"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-7 col-md-7 col-sm-3 button-container d-flex justify-content-end">
                            <b-button variant="light" @click="goBack" type="is-light">Voltar</b-button>
                            <b-button
                                variant="light"
                                native-type="submit"
                                type="is-primary"
                                :loading="isSubmit"
                                :disabled="isSubmit"
                                >Salvar</b-button
                            >
                        </div>
                    </div>
                </div>
            </form>
        </section>
    </div>
</template>

<script>
import AdminsService from "@/services/admins.service.js";

export default {
    data: () => ({
        isLoading: false,
        isSubmit: false,
        errors: {},
        title: "",
        admin: { user: {} },
        admins: [],
        email: null,
        password: null,
    }),

    methods: {
        goBack() {
            this.$router.go(-1);
        },

        loadAdmin(id) {
            if (id) {
                this.isLoading = true;

                AdminsService.getId(id)
                    .then(({ data }) => (this.admin = data))
                    .finally(() => (this.isLoading = false));
            }
        },

        onSubmit() {
            this.errors = {};

            if (!this.id) this.save({ ...this.admin.user, name: this.admin.name });
            else
                this.update(this.id, {
                    name: this.admin.name,
                    email: this.admin.user.email,
                    password: this.admin.password,
                });
        },
        save(data) {
            this.isSubmit = true;

            AdminsService.save(data)
                .then(() => {
                    this.$buefy.snackbar.open("Administrador cadastrado com sucesso!");
                    this.$router.push({ name: "admins.index" });
                })
                .catch(({ response }) => {
                    const { data, status } = response;

                    this.$buefy.snackbar.open("Erro ao tentar salvar o Administrador.", "error");

                    if (status == 400) this.errors = data.errors;
                })
                .finally(() => (this.isSubmit = false));
        },
        update(id, data) {
            this.isSubmit = true;

            AdminsService.update(id, data)
                .then(() => {
                    this.$buefy.snackbar.open("Administrador atualizado com sucesso!");
                    this.$router.push({ name: "admins.index" });
                })
                .catch(({ response }) => {
                    const { data } = response;

                    this.$buefy.snackbar.open("Erro ao tentar atualizar o Administrador.", "error");

                    this.errors = data.errors;
                })
                .finally(() => (this.isSubmit = false));
        },
    },

    mounted() {
        const id = this.$route.params.id;
        this.id = this.$route.params.id;
        this.loadAdmin(id);
    },
};
</script>

<style lang="scss" scoped>
.admin {
    display: flex;

    &__image {
        margin-right: 24px;
        border: 1px solid #f5f5f5;
        border-radius: 50%;
        overflow: hidden;
        height: 120px;
        width: 120px;

        img {
            object-fit: cover;
        }
    }

    &__info {
        h2 {
            font-size: 1.4rem;
        }

        .links {
            margin-top: 8px;

            .button:not(:first-child) {
                margin-left: 8px;
            }
        }
    }
}
</style>
