import Clear from "@/layouts/Clear.vue";

export default {
  path: "/getting-started",
  component: Clear,
  children: [
    {
      path: "",
      name: "getting-started.index",
      component: () => import("@/views/GettingStarted/GettingStarted.vue"),
    },
  ],
};
