<template>
  <div class="settings-page">
    <b-loading :active.sync="isLoading" :is-full-page="true"></b-loading>
    <app-header icon="cog" title="Configurações"></app-header>

    <section>
      <rank-rules></rank-rules>

      <div class="card mt-4" style="background: #fff">
        <div class="card-header">
          <div
            class="card-header-title"
            style="color: #1a1a1a; text-transform: uppercase"
          >
            Padrões do Sistema
          </div>

          <div class="card-header-buttons">
            <b-button
              class="ml-4"
              variant="light"
              @click="onSubmit()"
              type="is-primary"
              size="is-small"
              icon-left="save"
              :loading="isSaving"
              >Salvar</b-button
            >
          </div>
        </div>
        <div class="card-content">
          <form v-if="!isLoading" @submit.prevent>
            <div class="row">
              <div class="col-12 col-md-12 col-sm-6">
                <div
                  class="card cadastro"
                  v-for="field in parameters"
                  :key="field.id"
                >
                  <div class="card-body">
                    <div class="card-title-cadastro">{{ field.label }}</div>
                    <app-input
                      v-model="field.value"
                      style="margin-left: 5px"
                    ></app-input>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <!-- <div class="card mt-4" style="background: #fff">
        <div class="card-header">
          <div
            class="card-header-title"
            style="color: #1a1a1a; text-transform: uppercase"
          >
            Faixa de valores
          </div>
          <div class="card-header-buttons">
            <b-tooltip label="Nova faixa de valor">
              <b-button
                @click="newPaymentRange"
                type="is-secondary"
                icon-left="plus"
                size="is-small"
              >
                Adicionar
              </b-button>
            </b-tooltip>
            <b-tooltip label="Salvar alterações">
              <b-button
                @click="savePaymentRanges"
                class="ml-4"
                variant="light"
                type="is-primary"
                size="is-small"
                icon-left="save"
                :loading="isSaving"
                >Salvar</b-button
              >
            </b-tooltip>
          </div>
        </div>
        <div class="card-content">
          <form v-if="!isLoading" @submit.prevent>
            <div class="row" v-for="(range, idx) in paymentRanges" :key="idx">
              <div class="col-4 col-md-4 col-sm-3">
                <div class="card cadastro">
                  <div class="card-body">
                    <div class="card-title-cadastro">Faixa inicial</div>
                    <app-input v-model="range.initial_range"></app-input>
                  </div>
                </div>
              </div>
              <div class="col-4 col-md-4 col-sm-3">
                <div class="card cadastro">
                  <div class="card-body">
                    <div class="card-title-cadastro">Faixa final</div>
                    <app-input v-model="range.final_range"></app-input>
                  </div>
                </div>
              </div>
              <div
                class="col-4 col-md-4 col-sm-3"
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <div style="width: 94%" class="card cadastro">
                  <div class="card-body">
                    <div class="card-title-cadastro">Valor</div>
                    <app-input
                      v-model="range.amount"
                      :mask="MASKS.money"
                    ></app-input>
                  </div>
                </div>

                <div style="margin-top: 10px">
                  <b-button
                    @click="deletePaymentRange(idx)"
                    type="is-danger"
                    size="is-small"
                    icon-right="trash"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div> -->

      <getting-started />
    </section>
  </div>
</template>

<script>
import ParametersService from "@/services/parameters.service";

import AdminsService from "@/services/admins.service";
import MASKS from "@/constants/masks.constant";
import GettingStarted from "../GettingStarted/GettingStarted.vue";
import RankRules from "../../components/rank_rules/RankRules.vue";

export default {
  components: { GettingStarted, RankRules },
  data: () => ({
    isLoading: false,
    isSaving: false,
    parameters: [],
    paymentRanges: [],
    MASKS: MASKS,
    rankRules: [],
    specialties: [],
  }),

  methods: {
    deletePaymentRange(idx) {
      const range = this.paymentRanges[idx];

      if (range.id) {
        AdminsService.deletePaymentRange(range.id).then(() => {
          this.paymentRanges.splice(idx, 1);
        });
      } else {
        this.paymentRanges.splice(idx, 1);
      }
    },
    newPaymentRange() {
      const lastRange = this.paymentRanges[this.paymentRanges.length - 1];

      if (!lastRange["final_range"]) return;

      this.paymentRanges.push({
        id: null,
        initial_range: Number(lastRange["final_range"]) + 1,
        final_range: null,
        amount: null,
      });
    },
    savePaymentRanges() {
      this.isSaving = true;

      const promises = [];

      this.paymentRanges.forEach((paymentRange) => {
        promises.push(
          AdminsService.storePaymentRange({
            ...paymentRange,
            amount: parseFloat(paymentRange.amount.replace(/[^\d,-]/g, "")),
          })
        );
      });

      Promise.all(promises)
        .then(() => {
          this.$buefy.snackbar.open("Valores salvos com sucesso!");
          this.paymentRanges = this.paymentRanges.map((range) => ({
            ...range,
            amount: new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(parseFloat(range.amount.replace(/[^\d,-]/g, ""))),
          }));
        })
        .catch((response) => {
          const { status } = response;

          let message = "Erro ao salvar valores";

          if (status == 400) {
            const { data } = response;
            message = data.errors[Object.keys(response.data.errors)[0]][0];
          }
          this.$buefy.snackbar.open(message);
        })
        .finally(() => (this.isSaving = false));
    },

    onSubmit() {
      this.isSaving = true;

      ParametersService.store(this.parameters)
        .then(() =>
          this.$buefy.snackbar.open("Configurações atualizadas com sucesso.")
        )
        .catch(() =>
          this.$buefy.snackbar.open("Erro ao atualizar configurações.")
        )
        .finally(() => (this.isSaving = false));
    },
    loadParameters() {
      ParametersService.get()
        .then(({ data }) => (this.parameters = data))
        .catch(() => {
          this.$buefy.snackbar.open("Erro ao carregar configurações.");
          this.isLoading = false;
        });
    },
    getPaymentRanges() {
      AdminsService.getPaymentRanges()
        .then(({ data }) => {
          this.paymentRanges = data.map((range) => ({
            ...range,
            amount: new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(range.amount),
          }));
        })
        .catch(() => {
          this.$buefy.snackbar.open("Erro ao carregar faixa de valores.");
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.isLoading = true;

    Promise.all([this.loadParameters(), this.getPaymentRanges()]);

    setTimeout(() => (this.isLoading = false), 3000);
  },
};
</script>

<style lang="scss" scoped>
.values {
  margin-top: 2rem;
  flex-wrap: wrap;
  display: flex;
  gap: 10px;

  width: 100%;
}
</style>
