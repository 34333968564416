import Api from "./api.service";

export default {
    get: (params) => Api.get("terms", { params }),
    getId: (id) => Api.get(`terms/${id}`),
    save: (data) => Api.post(`terms/`, data),
    update: (id, data) => Api.put(`terms/${id}`, data),
    delete: (id) => Api.delete(`terms/${id}`),
    export: (filters) => Api.post("terms/export", { filters }),
};
