<template>
    <div class="terms-list-view">
        <app-header icon="file-alt" title="Termos de Uso"></app-header>

        <section>
            <div class="row mt-4">
                <div class="col-12 col-lg-12 col-md-12 col-sm-6 f-flex text-right">
                    <b-tooltip label="NOVO TERMO DE USO">
                        <router-link
                            :to="{
                                name: 'terms.save',
                            }"
                        >
                            <b-button class="mr-3" type="is-primary" icon-right="plus">NOVO</b-button>
                        </router-link>
                    </b-tooltip>

                    <b-tooltip label="EXPORTAR XLS">
                        <b-button
                            @click="exportTerms()"
                            type="is-primary"
                            icon-right="file-excel"
                            :disabled="isExporting"
                            :loading="isExporting"
                        >
                            EXPORTAR XLS
                        </b-button>
                    </b-tooltip>
                </div>
            </div>

            <div class="row mt-2 justify-content-around">
                <div class="col-12 col-lg-12 col-md-12 col-sm-6">
                    <b-table
                        id="table-terms"
                        class="table-custom"
                        :data="terms_list"
                        :per-page="perPage"
                        :current-page.sync="page"
                        :total="total"
                        :loading="isLoading"
                        :paginated="false"
                        :pagination-simple="false"
                        pagination-position="both"
                        default-sort-direction="asc"
                        :pagination-rounded="false"
                        sort-icon="arrow-down"
                        sort-icon-size="is-small"
                        default-sort="row.name"
                        aria-next-label="Próxima Página"
                        aria-previous-label="Página Anterior"
                        aria-page-label="Página"
                        aria-current-label="Página Atual"
                    >
                        <b-table-column width="40%" v-slot="props" field="name" label="NOME" sortable>
                            {{ props.row.name }}
                        </b-table-column>

                        <b-table-column
                            width="20%"
                            v-slot="props"
                            field="user_type"
                            label="TIPO DE TERMO"
                            sortable
                        >
                            {{ UserTypes.find((element) => element.id == props.row.user_type).name }}
                        </b-table-column>

                        <b-table-column
                            width="20%"
                            v-slot="props"
                            field="created_at"
                            label="CADASTRADO EM"
                            sortable
                        >
                            {{ props.row.created_at | date("DD/MM/YYYY [às] HH:mm") }}
                        </b-table-column>

                        <b-table-column width="20%" v-slot="props" label="OPÇÕES" cell-class="width-100">
                            <router-link
                                :to="{
                                    name: 'terms.save',
                                    params: { id: props.row.id },
                                }"
                            >
                                <b-tooltip label="Editar" class="m-1">
                                    <b-button type="is-primary" size="is-small" icon-right="edit" />
                                </b-tooltip>
                            </router-link>
                            <b-tooltip label="Deletar" class="m-1">
                                <b-button
                                    @click="onClickDelete(props.row.id)"
                                    type="is-primary"
                                    size="is-small"
                                    icon-right="trash"
                                />
                            </b-tooltip>
                        </b-table-column>

                        <template slot="empty">
                            <section class="section">
                                <div class="content has-text-grey has-text-centered">
                                    <p>
                                        <b-icon icon="frown" size="h3"></b-icon>
                                    </p>
                                    <p>Nenhum registro encontrado.</p>
                                </div>
                            </section>
                        </template>

                        <template #footer v-if="rows > 0">
                            <div class="row align-middle text-center">
                                <div class="col-5 col-lg-5 col-md-5 col-sm-3 ptext text-right">
                                    Exibindo {{ rows }} de {{ total }}
                                </div>

                                <div class="col-auto text-center">
                                    <b-pagination
                                        v-model="page"
                                        :total-total="total"
                                        :per-page="perPage"
                                        first-number
                                        last-number
                                        aria-controls="table-admins"
                                    >
                                    </b-pagination>
                                </div>

                                <div class="col-auto text-center">
                                    <b-select class="pselect" v-model="perPage" style="margin-left: 10px">
                                        <b-select-option v-for="i in perPageOptions" :key="i" :value="i">
                                            <b>{{ i }}</b>
                                        </b-select-option>
                                    </b-select>
                                </div>
                            </div>
                        </template>
                    </b-table>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import TermsService from "@/services/terms.service";
import debounce from "lodash/debounce";

export default {
    mounted() {
        this.load();
    },

    data: () => ({
        isExporting: false,
        isLoading: true,
        data: [],
        page: 1,
        perPageOptions: [10, 20, 40, 80],
        perPage: 10,
        total: 0,
        terms: [],
        UserTypes: [],
        searchName: "",
        searchUserType: null,
    }),

    computed: {
        terms_list() {
            return this.data;
        },

        rows() {
            return this.data.length;
        },
    },

    watch: {
        searchUserType() {
            this.page = 1;
            this.load();
        },

        searchName: debounce(function () {
            this.page = 1;
            this.load();
        }, 400),

        page() {
            if (this.data.length > 0 && this.data.length < this.total) this.load();
        },

        perPage() {
            this.load();
        },
    },

    methods: {
        exportTerms() {
            this.isExporting = true;
            TermsService.export()
                .then(({ data }) => {
                    const link = document.createElement("a");
                    link.target = "_blank";
                    link.href = data;
                    link.click();
                })
                .catch(({ response }) => {
                    const message = response.error || "Erro ao realizar exportação.";
                    this.$buefy.snackbar.open(message);
                })
                .finally(() => (this.isExporting = false));
        },

        load() {
            this.UserTypes = [
                { id: 1, name: "Advogado" },
                { id: 2, name: "Cliente" },
                { id: 3, name: "Secretária" },
            ];

            this.isLoading = true;

            TermsService.get({
                page: this.page,
                perPage: this.perPage,
                name: this.searchName,
                user_type: this.searchUserType != null ? this.searchUserType : "",
            })
                .then(({ data }) => {
                    const { total, data: items, current_page } = data;
                    this.page = current_page;
                    this.data = items;
                    this.total = total;
                })
                .finally(() => setTimeout(() => (this.isLoading = false), 300));
        },

        onClickDelete(id) {
            this.$buefy.dialog.confirm({
                title: "Excluindo Termo",
                message: "Tem certeza que deseja <b>excluir</b> este termo? Essa ação não pode ser desfeita.",
                cancelText: "Cancelar",
                confirmText: "Excluir Termo",
                type: "is-danger",
                hasIcon: true,
                onConfirm: () => this.delete(id),
            });
        },

        delete(id) {
            TermsService.delete(id)
                .then(() => {
                    this.$buefy.snackbar.open("Termo excluído com sucesso.");
                    this.load();
                })
                .catch(({ response }) => {
                    if (response.status != 200) {
                        this.$buefy.snackbar.open(
                            response.message || "Erro ao tentar excluir o Termo.",
                            "error",
                            4000
                        );
                    }
                });
        },
    },
};
</script>

<style scoped>
.filters {
    align-items: center;
}
.separated-table {
    margin: 5px 0;
}
</style>
