import Api from './api.service';

const ENDPOINT = 'dashboard';

export default {
  getScheduleValues(params = {}) {
    return Api.get(`${ENDPOINT}/schedule-list-value`, { params });
  },
  getSchedulesInfo(params = {}) {
    return Api.get(`${ENDPOINT}/schedules`, { params });
  },
  getPatientsTableInfo(params = {}) {
    return Api.get(`${ENDPOINT}/clients-table-info`, { params });
  },
  getPatientsChartsInfo(params = {}) {
    return Api.get(`${ENDPOINT}/clients-charts-info`, { params });
  },
  getDoctorsTableInfo(params = {}) {
    return Api.get(`${ENDPOINT}/lawyers-table-info`, { params });
  },
  getDoctorsChartsInfo(params = {}) {
    return Api.get(`${ENDPOINT}/lawyers-charts-info`, { params });
  },
  getSchedulesBySpecialty(params = {}) {
    return Api.get(`${ENDPOINT}/schedules-by-specialty`, { params });
  },
  getDoctorsStatus(params = {}) {
    return Api.get(`${ENDPOINT}/lawyers-status`, { params });
  },
  // getOnline(params = {}) {
  //   return Api.get(`/schedules/online`, { params });
  // },
};
